import { UserRole } from 'shared/lib/bazar-api';

export const USER_ROLES = [
  {
    label: 'Администратор',
    value: UserRole.Admin,
  },
  { label: 'Менеджер', value: UserRole.Manager },
  { label: 'Супервайзер', value: UserRole.Supervisor },
  { label: 'Маркетинг', value: UserRole.Marketing },
  { label: 'HR-специалист', value: UserRole.HR },
  { label: 'Работа с клиентами', value: UserRole.Support },
  { label: 'Специалист по закупкам', value: UserRole.ProcurementSpecialist },
];

export const USER_ROLES_MAP = {
  [UserRole.Admin]: 'Администратор',
  [UserRole.Manager]: 'Менеджер',
  [UserRole.Supervisor]: 'Супервайзер',
  [UserRole.Marketing]: 'Маркетинг',
  [UserRole.HR]: 'HR-специалист',
  [UserRole.Support]: 'Работа с клиентами',
  [UserRole.ProcurementSpecialist]: 'Специалист по закупкам',
};
