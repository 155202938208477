import { useTranslation } from 'react-i18next';
import { AddressAutocomplete } from 'features/addresses';
import { useFormController } from 'shared/lib/form';

export const CustomerFormAddress = () => {
  const { t } = useTranslation('features/customers', { keyPrefix: 'customer-form' });

  const streetController = useFormController({ name: 'addresses.0.street' });
  const houseController = useFormController({ name: 'addresses.0.house' });

  let value = '';
  if (streetController.field.value && houseController.field.value) {
    value = `${streetController.field.value}, ${houseController.field.value}`;
  }
  const error =
    streetController.fieldState.error?.message || houseController.fieldState.error?.message;

  return (
    <AddressAutocomplete
      required
      label={t('street-house')}
      value={value}
      error={error}
      onChange={address => {
        const [street, house] = address.name.split(',');
        streetController.field.onChange(street);
        houseController.field.onChange(house);
      }}
    />
  );
};
