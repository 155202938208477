import { parseDate } from 'shared/utils/date';
import { capitalize } from 'shared/utils/string';
import { bazarApiClient } from '../client';
import { Darkstore, Supplier, SupplierRow } from '../types';

const getList = async ({
  darkstoreId,
  query,
  sortBy = 'name',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  darkstoreId: Darkstore['id'];
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{ rows: SupplierRow[]; totalPages: number }> => {
  const body = JSON.stringify({
    darkstoreId,
    query,
    sortField: sortBy,
    sortOrder,
    page: pageNumber,
    pageSize,
  });
  const res = await bazarApiClient.post('admin/providers/list', { body });
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => {
      return {
        id: row.id,
        darkstoreId,
        name: row.name,
        upn: row.unp,
        contact: row.contacts[0]?.name ?? '',
        phone: row.contacts[0]?.tel ?? '',
        days: row.days.map((day: string) => capitalize(day)).join(', '),
      };
    }),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const getOne = async ({
  id,
  darkstoreId,
}: {
  id: Supplier['id'];
  darkstoreId: Darkstore['id'];
}): Promise<Supplier> => {
  const res = await bazarApiClient.get(`admin/providers/${id}/${darkstoreId}`);
  const data = await res.json<any>();
  return {
    id: data.id,
    darkstoreId,
    type: data.type,
    paymentTermType: data.termsPayment,
    products: (data.products ?? []).map((p: any) => ({
      id: p.productId,
      name: p.name,
      barcode: p.cod,
      amount: p.qty,
      sold: p.qtySale,
      writeOff: p.qtyWriteoff,
      weighted: Boolean(p.isWeightGoods),
      enabled: Boolean(p.status),
      minRequest: p.minPurchases,
      weight: Number(p.weight),
      liquid: Boolean(p.liter),
      priceWithoutVat: p.pricePurchases,
      request: 0,
    })),
    supplies: (data.supplies ?? []).map((s: any) => ({
      id: s.id,
      number: s.num,
      sumWithVat: s.sum,
      sumWithoutVat: s.sumWithoutVat,
      createdAt: parseDate(s.dateCreate),
      completedAt: parseDate(s.dateComplete),
      payedAt: s.datePayment === '0000-00-00' ? null : parseDate(s.datePayment),
    })),
    name: data.name,
    email: data.email,
    upn: data.unp,
    requisites: data.requisites,
    contractNumber: data.contract,
    contractUrl: data.contactUrl || null,
    contacts: data.contacts.map((c: any) => ({
      name: c.name,
      phone: c.tel,
      birthday: c.birthday ? parseDate(c.birthday) : null,
      email: c.email || '',
      comment: c.comment || '',
    })),
    requestDays: data.days,
    paymentDelay: data.delayPayment,
    paymentTermComment: data.termsPaymentComment,
    comment: data.comment,
  };
};

const add = async (
  variables: Omit<Supplier, 'id' | 'products' | 'supplies'>,
): Promise<{ id: Supplier['id'] }> => {
  const body = JSON.stringify({
    type: variables.type,
    termsPayment: variables.paymentTermType,
    name: variables.name,
    email: variables.email,
    unp: variables.upn,
    requisites: variables.requisites,
    contract: variables.contractNumber,
    contractUrl: variables.contractUrl,
    contacts: variables.contacts.map((c: any) => ({
      name: c.name,
      tel: c.phone,
      birthday: c.birthday,
      email: c.email,
      comment: c.comment,
    })),
    days: variables.requestDays,
    delayPayment: variables.paymentDelay,
    termsPaymentComment: variables.paymentTermComment,
    comment: variables.comment,
  });
  const res = await bazarApiClient.post(`admin/darkstores`, { body });
  const data = await res.json<any>();
  return { id: data.providerId };
};

const edit = async (variables: Omit<Supplier, 'products' | 'supplies'>) => {
  const body = JSON.stringify({
    id: variables.id,
    type: variables.type,
    termsPayment: variables.paymentTermType,
    name: variables.name,
    email: variables.email,
    unp: variables.upn,
    requisites: variables.requisites,
    contract: variables.contractNumber,
    contractUrl: variables.contractUrl,
    contacts: variables.contacts.map((c: any) => ({
      name: c.name,
      tel: c.phone,
      birthday: c.birthday,
      email: c.email,
      comment: c.comment,
    })),
    days: variables.requestDays,
    delayPayment: variables.paymentDelay,
    termsPaymentComment: variables.paymentTermComment,
    comment: variables.comment,
  });
  const res = await bazarApiClient.put(`admin/providers`, { body });
  const data = await res.json();
  return data;
};

export const suppliers = { getList, getOne, add, edit };
