import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { SupplierRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { formatPhone } from 'shared/utils/phone';
import { useSuppliers } from '../hooks';

export const SuppliersDataGrid = ({ query }: { query: string }) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'suppliers-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplierRow>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue().toString();
          return (
            <Anchor component={Link} to={value}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', {
        size: 350,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('upn', {
        size: 150,
        header: () => t('columns.2'),
      }),
      columnHelper.accessor('contact', {
        size: 150,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor('phone', {
        size: 150,
        header: () => t('columns.4'),
        cell: params => formatPhone(params.getValue()),
      }),
      columnHelper.accessor('days', {
        size: 150,
        header: () => t('columns.5'),
        cell: params => {
          const value = params.getValue();
          if (value.length > 20) {
            return (
              <Tooltip label={value}>
                <span>{value}</span>
              </Tooltip>
            );
          }
          return value;
        },
      }),
    ];
  }, [t]);

  const [debouncedQuery] = useDebouncedValue(query, 300);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'name', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, suppliers, totalPages } = useSuppliers({
    query: debouncedQuery,
    ...state.server,
  });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={suppliers}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
