import { DarkstoreZoneStatus } from 'shared/lib/bazar-api';

export const DARKSTORE_ZONE_STATUSES = [
  { label: 'Штатный режим', value: DarkstoreZoneStatus.Working },
  { label: 'Технический перерыв', value: DarkstoreZoneStatus.TechnicalBreak },
  { label: 'Повышенный спрос', value: DarkstoreZoneStatus.IncreasedDemand },
  { label: 'Плохая погода', value: DarkstoreZoneStatus.BadWeather },
  { label: 'Временно не работаем', value: DarkstoreZoneStatus.TemporalityDown },
];

export const DARKSTORE_ZONE_STATUSES_MAP = {
  [DarkstoreZoneStatus.Working]: 'Штатный режим',
  [DarkstoreZoneStatus.TechnicalBreak]: 'Технический перерыв',
  [DarkstoreZoneStatus.IncreasedDemand]: 'Повышенный спрос',
  [DarkstoreZoneStatus.BadWeather]: 'Плохая погода',
  [DarkstoreZoneStatus.TemporalityDown]: 'Временно не работаем',
};

export const DEFAULT_DELIVERY_TIMES = [
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
];
