import { FILESTORAGE_URL } from 'shared/config/env';
import { bazarApiClient } from '../client';
import { Banner, BannerSize, Darkstore } from '../types';

const getList = async ({ darkstoreId }: { darkstoreId: Darkstore['id'] }): Promise<Banner[]> => {
  const res = await bazarApiClient.get('admin/banners', { searchParams: { darkstoreId } });
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.id,
    darkstoreId,
    size: item.isBig ? BannerSize.Big : BannerSize.Small,
    actionType: item.type,
    appImage: `${FILESTORAGE_URL}/${item.imgApp}`,
    webImage: `${FILESTORAGE_URL}/${item.imgWeb}`,
    targetCategory: null,
    targetSubcategory: null,
    targetBrand: null,
    targetScreen: null,
    modal: null,
    stories: [],
  }));
};

export const add = async (variables: Omit<Banner, 'id'>) => {
  const body = JSON.stringify(variables);
  const res = await bazarApiClient.post('admin/banners', { body });
  const data = await res.json();
  return data;
};

export const edit = async (variables: Banner) => {
  const body = JSON.stringify(variables);
  const res = await bazarApiClient.put('admin/banners', { body });
  const data = await res.json();
  return data;
};

const remove = async ({ id }: { id: Banner['id'] }) => {
  const res = await bazarApiClient.delete(`admin/banners/${id}`);
  const data = await res.json();
  return data;
};

export const banners = { getList, add, edit, remove };
