import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Product } from 'shared/lib/bazar-api';
import type { ProductFormValues } from '../types';

type EditProductDTO = {
  productId: Product['id'];
  data: Pick<ProductFormValues, 'darkstoreId'> & Partial<ProductFormValues>;
};

export const useEditProduct = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditProductDTO>({
    mutationFn: variables => {
      return bazarApi.assortment.editProduct({
        id: variables.productId,
        ...variables.data,
      });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['products'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
