import { useSettings } from 'features/settings';
import { UserRole } from 'shared/lib/bazar-api';
import { useAddUser } from '../hooks';
import { UserForm } from './users-form';

export const UserAddModal = ({ onClose }: { onClose: () => void }) => {
  const { isAdding, add } = useAddUser({ onSuccess: onClose });

  const partnerId = useSettings(settings => settings.partnerId);

  return (
    <UserForm
      mode="add"
      isSubmitting={isAdding}
      defaultValues={{
        partnerId,
        email: '',
        name: '',
        surname: '',
        password: '',
        role: UserRole.Admin,
        darkstores: [],
        acl: {},
      }}
      onSubmit={data => add({ data })}
    />
  );
};
