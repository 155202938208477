import { useSettings } from 'features/settings';
import { BlacklistAddressType } from 'shared/lib/bazar-api';
import { useAddBlacklistAddress } from '../hooks';
import { BlacklistAddressForm } from './blacklist-address-form';

export const BlacklistAddressAddModal = ({ onClose }: { onClose: () => void }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isAdding, add } = useAddBlacklistAddress({ onSuccess: onClose });

  return (
    <BlacklistAddressForm
      isSubmitting={isAdding}
      defaultValues={{
        darkstoreId,
        type: BlacklistAddressType.Using,
        street: '',
        house: '',
        building: '',
        flat: '',
      }}
      onSubmit={data => add({ data })}
    />
  );
};
