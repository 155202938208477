import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';

export const useAddStopListItem = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: any) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, any>({
    mutationFn: variables => bazarApi.stopList.add(variables.data),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Запись в стоп-лист добавлена',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['stop-list'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
