import dayjs from 'dayjs';
import { formatDate, parseDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { Darkstore, Employee, Order, OrderRow, OrderStatus } from '../types';

const getList = async ({
  darkstoreId,
  from,
  to,
  query,
  courierId,
  sortBy = 'name',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  darkstoreId: Darkstore['id'];
  from: Date;
  to: Date;
  query: string;
  courierId?: Employee['id'];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{ rows: OrderRow[]; totalPages: number }> => {
  const body = JSON.stringify({
    darkstoreId,
    dateStart: formatDate(from, 'YYYY-MM-DD'),
    dateFinish: formatDate(to, 'YYYY-MM-DD'),
    query,
    courierId,
    sortField: sortBy,
    sortOrder,
    page: pageNumber,
    pageSize,
  });
  const res = await bazarApiClient.post('admin/orders/list', { body });
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => {
      const deliveryDate = parseDate(row.dateDelivery);
      const deliveryTime = row.timeDelivery === '00:00:00' ? 'now' : row.timeDelivery;
      const createdAt = parseDate(row.dateCreate);
      let deliveredAt = null;
      let deliveredIn = null;
      if (row.finishDelivery) {
        const [h, m, s] = row.finishDelivery.split(':');
        deliveredAt = dayjs(deliveryDate).set('h', h).set('m', m).set('s', s).toDate();
        if (deliveryTime === 'now') {
          deliveredIn = dayjs(deliveredAt).diff(createdAt, 's');
        } else {
          const [h, m, s] = deliveryTime.split(':');
          deliveredIn = dayjs(deliveredAt).diff(
            dayjs(deliveryDate).set('h', h).set('m', m).set('s', s),
            's',
          );
        }
        if (deliveredIn < 0) {
          deliveredIn = 0;
        }
      }
      return {
        id: row.id,
        idSosedi: row.idSosedi,
        idYandex: row.idYandex,
        idDelivio: row.idDelivio,
        courierId: row.courierId,
        status: row.statusEnum,
        paymentMethod: row.paymentMethod,
        customerName: row.name,
        customerPhone: row.phone,
        paid: Boolean(row.paymentStatus),
        courierName: row.courierName,
        courierSurname: row.courierSurname,
        sum: row.sum,
        plannedTimeDelivery: row.plannedTimeDelivery,
        deliveryDate,
        deliveryTime,
        createdAt,
        deliveredAt,
        deliveredIn,
      };
    }),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const getOne = async ({ id }: { id: Order['id'] }): Promise<Order> => {
  const res = await bazarApiClient.get(`admin/orders/${id}`);
  const data = await res.json<any>();
  let pickedAt = null;
  if (data.picker?.pickerFinish) {
    const [h, m, s] = data.picker.pickerFinish.split(':');
    pickedAt = dayjs(data.dateDelivery).set('h', h).set('m', m).set('s', s).toDate();
  }
  let deliveredAt = null;
  if (data.courier?.courierFinish) {
    const [h, m, s] = data.courier.courierFinish.split(':');
    deliveredAt = dayjs(data.dateDelivery).set('h', h).set('m', m).set('s', s).toDate();
  }
  return {
    ...data,
    status: data.statusEnum,
    id,
    idSosedi: data.idSosedi,
    idYandex: data.idYandex,
    idDelivio: data.idDelivio,
    courierId: data.courier ? data.courier.courierId : null,
    pickerId: data.picker ? data.picker.pickerId : null,
    customerName: data.name,
    customerPhone: data.phone,
    paid: Boolean(data.paymentStatus),
    sum: data.sum,
    deliveryDate: parseDate(data.dateDelivery),
    deliveryTime: data.timeDelivery === '00:00:00' ? 'now' : data.timeDelivery,
    products: data.products.map((p: any) => ({
      id: p.productId,
      name: p.name,
      barcode: p.cod,
      amount: p.qty,
      price: p.price,
      priceCatalog: p.priceCatalog,
    })),
    createdAt: parseDate(data.dateCreate),
    pickedAt,
    deliveredAt,
  };
};

const edit = async (variables: Pick<Order, 'id'> & Partial<Order>) => {
  let oldStatus = 1;
  if (variables.status === OrderStatus.New || variables.status === OrderStatus.Collecting) {
    oldStatus = 1;
  }
  if (variables.status === OrderStatus.Collected || variables.status === OrderStatus.Delivering) {
    oldStatus = 2;
  }
  if (variables.status === OrderStatus.Delivered || variables.status === OrderStatus.Transferred) {
    oldStatus = 3;
  }
  if (variables.status === OrderStatus.Canceled) {
    oldStatus = 4;
  }
  const body = JSON.stringify({
    orderId: variables.id,
    courierId: variables.courierId,
    pickerId: variables.pickerId,
    statusEnum: variables.status,
    status: oldStatus,
    paymentMethod: variables.paymentMethod,
    dateDelivery: variables.deliveryDate
      ? formatDate(variables.deliveryDate, 'YYYY-MM-DD')
      : undefined,
    timeDelivery: variables.deliveryTime === 'now' ? '00:00:00' : variables.deliveryTime,
  });
  const res = await bazarApiClient.put(`admin/orders`, { body });
  const data = await res.json();
  return data;
};

export const orders = { getList, getOne, edit };
