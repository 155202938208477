import { Box } from '@mantine/core';
import { flexRender } from '@tanstack/react-table';
import { CaretDown, CaretUp } from 'tabler-icons-react';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridHeader = () => {
  const { props, table } = useDataGridContext();
  return (
    <Box
      component="thead"
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: 1,
      }}
    >
      {table.getHeaderGroups().map(headerGroup => (
        <Box
          key={headerGroup.id}
          component="tr"
          sx={{
            display: 'flex',
            flex: '1 0 auto',
            width: '100%',
            minWidth: '0px',
          }}
        >
          {headerGroup.headers.map(header => (
            <Box
              key={header.id}
              component="th"
              colSpan={header.colSpan}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: props.sorting ? 'pointer' : 'default',
                ...(header.column.columnDef.meta?.sticky
                  ? {
                      position: 'sticky',
                      left: 0,
                      backgroundColor: '#ffffff',
                    }
                  : {}),
              }}
              style={{
                flex: `${header.getSize()} 0 auto`,
                width: header.getSize(),
                minWidth: '0px',
              }}
              onClick={props.sorting ? header.column.getToggleSortingHandler() : undefined}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getIsSorted() && (
                <Box component="span">
                  {header.column.getIsSorted() === 'desc' ? (
                    <CaretDown size={14} />
                  ) : (
                    <CaretUp size={14} />
                  )}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
