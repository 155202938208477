import { useTranslation } from 'react-i18next';
import { Group, Stack, Title } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { OrdersDataGrid } from 'features/orders';
import { SearchInput } from 'shared/components';
import { Employee } from 'shared/lib/bazar-api';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';

export const OrdersPage = () => {
  const [search, setSearch] = useSearch<{
    from: string;
    to: string;
    query: string;
    courierId?: Employee['id'];
  }>({
    from: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
    to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    query: '',
    courierId: undefined,
  });

  const { t } = useTranslation('pages/orders');

  const filters = { ...search, from: parseDate(search.from), to: parseDate(search.to) };

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('orders')}</Title>
      <Group>
        <DateRangePicker
          clearable={false}
          placeholder={t('select-period')}
          value={[filters.from, filters.to]}
          onChange={([from, to]) => {
            if (from && to) {
              setSearch({ from: formatDate(from, 'YYYY-MM-DD'), to: formatDate(to, 'YYYY-MM-DD') });
            }
          }}
        />
        <SearchInput
          sx={{ flex: 1 }}
          value={filters.query}
          onChange={query => setSearch({ query })}
        />
      </Group>
      <OrdersDataGrid {...filters} />
    </Stack>
  );
};
