import { formatDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { Darkstore } from '../types';

// TODO: add types

const fiscal = async ({
  darkstoresIds,
  period,
}: {
  darkstoresIds: Darkstore['id'][];
  period: [Date, Date];
}) => {
  const res = await bazarApiClient.get('admin/reports/fiscal', {
    searchParams: {
      darkstores: darkstoresIds.join(','),
      from: formatDate(period[0], 'YYYY-MM-DD'),
      to: formatDate(period[1], 'YYYY-MM-DD'),
    },
  });
  const data = await res.json<any[]>();
  return data;
};

const ordersByDay = async ({
  darkstoresIds,
  date,
}: {
  darkstoresIds: Darkstore['id'][];
  date: Date;
}) => {
  const res = await bazarApiClient.get('admin/reports/orders-by-day', {
    searchParams: {
      darkstores: darkstoresIds.join(','),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
  });
  const data = await res.json<any[]>();
  return data;
};

const stopList = async ({
  darkstoresIds,
  period,
}: {
  darkstoresIds: Darkstore['id'][];
  period: [Date, Date];
}) => {
  const res = await bazarApiClient.get('admin/reports/stop-list', {
    searchParams: {
      darkstores: darkstoresIds.join(','),
      from: formatDate(period[0], 'YYYY-MM-DD'),
      to: formatDate(period[1], 'YYYY-MM-DD'),
    },
  });
  const data = await res.json<any>();
  return data.shortResults;
};

const deliveryServices = async ({
  darkstoresIds,
  date,
  service,
}: {
  darkstoresIds: Darkstore['id'][];
  date: Date;
  service: string;
}) => {
  const res = await bazarApiClient.get('admin/reports/delivery-services', {
    searchParams: {
      darkstores: darkstoresIds.join(','),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      service,
    },
  });
  const data = await res.json<any[]>();
  return data;
};

export const reports = { fiscal, ordersByDay, stopList, deliveryServices };
