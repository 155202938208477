import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Supply } from 'shared/lib/bazar-api';
import type { SupplyProductFormValues } from '../types';

type AddSupplyProductDTO = {
  supplyId: Supply['id'];
  data: SupplyProductFormValues;
};

export const useAddSupplyProduct = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddSupplyProductDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddSupplyProductDTO>({
    mutationFn: variables => {
      return bazarApi.supplies.addProduct({
        supplyId: variables.supplyId,
        ...variables.data,
      });
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Товар добавлен',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['supplies', { supplyId: variables.supplyId }] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
