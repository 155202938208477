import { formatDate, parseDate } from 'shared/utils/date';
import { round } from 'shared/utils/number';
import { bazarApiClient } from '../client';
import { Darkstore, Supplier, SupplierProduct, Supply, SupplyProduct } from '../types';

const getList = async ({
  createdAt,
  expectedAt,
  suppliersIds,
  darkstoresIds,
  sortBy = 'id',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  createdAt?: Date;
  expectedAt?: Date;
  suppliersIds?: Supplier['id'][];
  darkstoresIds?: Darkstore['id'][];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{ rows: any[]; totalPages: number }> => {
  const body = JSON.stringify({
    // FIXME: remove it after fix on api side
    darkstoreId: 1,
    dateCreate: createdAt,
    date: expectedAt,
    providers: suppliersIds,
    darkstores: darkstoresIds,
    sortField: sortBy,
    sortOrder,
    page: pageNumber,
    pageSize,
  });
  const res = await bazarApiClient.post('admin/supplies/list', { body });
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => ({
      id: row.id,
      darkstoreId: row.darkstoreId,
      supplierId: row.providerId,
      status: Number(row.status),
      number: row.num,
      darkstoreName: row.darkstoreName,
      supplierName: row.providerName,
      sumWithVat: row.sum,
      amount: row.qty,
      createdAt: parseDate(row.dateCreate),
      expectedAt: parseDate(row.date),
    })),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const getOne = async (variables: { id: Supply['id'] }): Promise<Supply> => {
  const res = await bazarApiClient.get(`admin/supplies/${variables.id}`);
  const data = await res.json<any>();
  return {
    id: data.id,
    darkstoreId: data.darkstoreId,
    supplierId: data.providerId,
    status: Number(data.status),
    products: data.products.map((p: any) => ({
      id: p.productId,
      name: p.name,
      barcode: p.cod,
      weight: Number(p.weight),
      liquid: Boolean(p.liter),
      amount: p.qty,
      priceWithoutVat: p.priceWithoutVat,
      sumWithoutVat: round(p.priceWithoutVat * p.qty),
      vatPercent: p.vat,
      vat: round(p.priceWithoutVat * (p.vat / 100)),
      vatSum: round(p.priceWithoutVat * (p.vat / 100) * p.qty),
      extraCharge: p.extraCharge,
      price: p.price,
      sku: p.sku,
    })),
    number: data.num,
    darkstoreName: data.darkstoreName,
    supplierName: data.providerName,
    consignmentNoteNumber: data.consignmentNote,
    sumWithVat: data.sum,
    vat: Number((data.sum - data.sumWithoutVat).toFixed(2)),
    sumWithoutVat: data.sumWithoutVat,
    billUrl: data.bill || null,
    paymentOrderUrl: data.paymentOrder || null,
    waybillUrl: data.waybill || null,
    createdAt: parseDate(data.dateCreate),
    expectedAt: parseDate(data.date),
  };
};

const add = async (variables: {
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  expectedAt: Date;
  products: SupplierProduct[];
}) => {
  // TODO: replace zeros with actual data
  const body = JSON.stringify({
    darkstoreId: variables.darkstoreId,
    providerId: variables.supplierId,
    products: variables.products
      .filter(p => p.request > 0)
      .map(p => ({
        productId: p.id,
        qty: p.request,
        priceWithoutVat: 0,
        vat: 0,
        extraCharge: 0,
      })),
    sum: 0,
    sumWithoutVat: 0,
    date: formatDate(variables.expectedAt, 'YYYY-MM-DD'),
  });
  const res = await bazarApiClient.post('admin/supplies', { body });
  const data = await res.json();
  return data;
};

const edit = async (variables: Pick<Supply, 'id'> & Partial<Supply>) => {
  const body = JSON.stringify({
    id: variables.id,
    status: variables.status,
    date: variables.expectedAt ? formatDate(variables.expectedAt, 'YYYY-MM-DD') : undefined,
  });
  const res = await bazarApiClient.put('admin/supplies', { body });
  const data = await res.json();
  return data;
};

const addProduct = async (variables: {
  supplyId: Supply['id'];
  productId: SupplyProduct['id'];
  amount: SupplyProduct['amount'];
}) => {
  const body = JSON.stringify({
    suppliesId: variables.supplyId,
    productId: variables.productId,
    qty: variables.amount,
  });
  const res = await bazarApiClient.post('admin/supplies/product', { body });
  const data = await res.json();
  return data;
};

const editProduct = async (variables: {
  supplyId: Supply['id'];
  productId: SupplyProduct['id'];
  amount: SupplyProduct['amount'];
}) => {
  const body = JSON.stringify({
    suppliesId: variables.supplyId,
    productId: variables.productId,
    qty: variables.amount,
  });
  const res = await bazarApiClient.put('admin/supplies/product', { body });
  const data = await res.json();
  return data;
};

const removeProduct = async (variables: {
  supplyId: Supply['id'];
  productId: SupplyProduct['id'];
}) => {
  const body = JSON.stringify({
    suppliesId: variables.supplyId,
    productId: variables.productId,
  });
  const res = await bazarApiClient.delete('admin/supplies/product', { body });
  const data = await res.json();
  return data;
};

export const supplies = { getList, getOne, add, edit, addProduct, editProduct, removeProduct };
