import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { usePermission } from 'features/auth';
import {
  DarkstoreZoneForm,
  useDarkstoreZone,
  useEditDarkstoreZone,
  DarkstoreZoneDeliveryCost,
  useDarkstoreZoneDeliveryCost,
} from 'features/darkstores';
import { useEditDarkstoreZoneDeliveryCost } from 'features/darkstores/hooks/use-edit-darkstore-zone-delivery-cost';

export const DarkstoreZoneEditPage = () => {
  const { zoneId } = useParams();

  const { t } = useTranslation('pages/darkstores');

  const hasWritePermission = usePermission('darkstores.write');

  const { isFetching, zone } = useDarkstoreZone({ zoneId: Number(zoneId) });
  const { isEditing, edit } = useEditDarkstoreZone();

  const { isFetchingDeliveryCost, deliveryCost } = useDarkstoreZoneDeliveryCost({
    zoneId: Number(zoneId),
  });
  const { isEditingDeliveryCost, editDeliveryCost } = useEditDarkstoreZoneDeliveryCost();

  if (isFetching && isFetchingDeliveryCost) {
    return <LoadingOverlay visible />;
  }

  if (!zone) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to={`/darkstore`}>
            {t('information')}
          </Anchor>
          <Text>{t('darkstore-zone-not-found')}</Text>
        </Breadcrumbs>
        <Title order={2}>{t('darkstore-zone-not-found')}</Title>
      </Stack>
    );
  }

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to={`/darkstore`}>
          {t('information')}
        </Anchor>
        <Text>{zone.name}</Text>
      </Breadcrumbs>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{zone.name}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <DarkstoreZoneForm
            id="darkstore-zone-form"
            disabled={!hasWritePermission}
            defaultValues={zone}
            onSubmit={data => edit({ zoneId: zone.id, data })}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="darkstore-zone-form"
            disabled={!hasWritePermission}
            loading={isEditing}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>

      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{t('darkstore-zone-delivery-cost')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <DarkstoreZoneDeliveryCost
            id="darkstore-zone-delivery-cost"
            disabled={!hasWritePermission}
            defaultValues={deliveryCost}
            onSubmit={data => {
              editDeliveryCost({ zoneId: zone.id, data });
            }}
            values={deliveryCost}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="darkstore-zone-delivery-cost"
            disabled={!hasWritePermission}
            loading={isEditingDeliveryCost}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>
    </Stack>
  );
};
