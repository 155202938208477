import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Anchor, Breadcrumbs, Button, Card, Stack, Text, Title } from '@mantine/core';
import { usePermission } from 'features/auth';
import { DarkstoreZoneForm, useAddDarkstoreZone } from 'features/darkstores';
import { useSettings } from 'features/settings';

export const DarkstoreZoneAddPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('pages/darkstores');

  const hasWritePermission = usePermission('darkstores.write');
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isAdding, add } = useAddDarkstoreZone({
    onSuccess: data => navigate(`../${data.id}`, { replace: true }),
  });

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to={`/darkstore`}>
          {t('information')}
        </Anchor>
        <Text> {t('add-darkstore-zone')}</Text>
      </Breadcrumbs>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}> {t('add-darkstore-zone')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <DarkstoreZoneForm
            id="darkstore-zone-form"
            disabled={!hasWritePermission}
            defaultValues={{
              darkstoreId,
              name: '',
              defaultDeliveryTime: 15,
              deliveryTime: 15,
              deliveryCost: 0,
              minOrderSum: 0,
              status: 0,
              paymentMethods: [],
              points: [],
            }}
            onSubmit={data => add({ data })}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="darkstore-zone-form"
            disabled={!hasWritePermission}
            loading={isAdding}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>
    </Stack>
  );
};
