import ky from 'ky';
import { BAZAR_API_URL } from 'shared/config/env';

// import { wait } from 'shared/utils/test';

export const bazarApiClient = ky.create({
  prefixUrl: BAZAR_API_URL,
  timeout: 100000,
  hooks: {
    beforeRequest: [
      async request => {
        //await wait(1000);
        const language = localStorage.getItem('language') ?? 'en';
        request.headers.set('language', language);
        const token = localStorage.getItem('token') ?? '';
        request.headers.set('authorization', token);
      },
    ],
  },
});
