import { z } from 'zod';

export const categoryFormSchema = z.object({
  partnerId: z.number().int().gte(0),
  name: z.string().min(1),
  image: z.string().url(),
  icon: z.string().url(),
  order: z.number().int().gte(0),
  promoted: z.boolean().default(false),
});

export const subcategoryFormSchema = z.object({
  categoryId: z.number().int().gte(0),
  name: z.string().min(1),
  order: z.number().int().gte(0),
});
