import { FILESTORAGE_URL } from 'shared/config/env';
import { bazarApiClient } from '../client';

const upload = async ({ type, file }: { type: string; file: File }): Promise<{ url: string }> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  const res = await bazarApiClient.post('admin/upload', { body: formData });
  const data = await res.json<any>();
  return { url: `${FILESTORAGE_URL}/${data.path}` };
};

export const files = { upload };
