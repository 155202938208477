import { useTranslation } from 'react-i18next';
import { Select, Sx } from '@mantine/core';
import { UserRole } from 'shared/lib/bazar-api';
import { USER_ROLES } from '../constants';

export const UserRoleSelect = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: UserRole | null;
  error?: string;
  onChange?: (value: UserRole) => void;
}) => {
  const { t } = useTranslation('features/users', { keyPrefix: 'user-role-select' });

  return (
    <Select
      className={className}
      sx={sx}
      required={required}
      label={label}
      placeholder={t('select-user-role')}
      data={USER_ROLES}
      value={value}
      error={error}
      onChange={onChange}
    />
  );
};
