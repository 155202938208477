import { useTranslation } from 'react-i18next';
import { Button, Group, Stack, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { EmployeeSelect } from 'features/employees';
import { Suspense } from 'shared/components';
import { EmployeeRole, Order } from 'shared/lib/bazar-api';
import { Form, FormController } from 'shared/lib/form';
import { formatDateTime } from 'shared/utils/date';
import { formatPhone } from 'shared/utils/phone';
import { orderFormSchema } from '../schemas';
import { OrderFormValues } from '../types';
import { DeliveryToModal } from './delivery-to-modal';
import { PaymentMethodSelect } from './payment-method-select';

export const OrderForm = ({
  disabled,
  defaultValues,
  onSubmit,
}: {
  disabled?: boolean;
  defaultValues: Order;
  onSubmit: (values: OrderFormValues) => void;
}) => {
  const { t } = useTranslation('features/orders', { keyPrefix: 'order-form' });

  const modals = useModals();

  return (
    <Form
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={orderFormSchema}
      onSubmit={onSubmit}
    >
      {form => {
        const submit = form.handleSubmit(onSubmit, e => console.warn(e));
        return (
          <Group noWrap>
            <Stack>
              <Text size="sm" weight={500}>
                {t('customer-name')}
              </Text>
              <Text size="sm" weight={500}>
                {t('customer-phone')}
              </Text>
              <Text size="sm" weight={500}>
                {t('address')}
              </Text>
              <Text size="sm" weight={500}>
                {t('created-at')}
              </Text>
              <Text size="sm" weight={500}>
                {t('promo-code')}
              </Text>
              <Text size="sm" weight={500}>
                {t('bonuses')}
              </Text>
              <Text size="sm" weight={500}>
                {t('picked-at')}
              </Text>
              <Text size="sm" weight={500}>
                {t('delivered-at')}
              </Text>
              <Text size="sm" weight={500} sx={{ lineHeight: '36px' }}>
                {t('delivery-time')}
              </Text>
              <Text size="sm" weight={500} sx={{ lineHeight: '36px' }}>
                {t('payment-method')}
              </Text>
              <Text size="sm" weight={500} sx={{ lineHeight: '36px' }}>
                {t('picker')}
              </Text>
              <Text size="sm" weight={500} sx={{ lineHeight: '36px' }}>
                {t('courier')}
              </Text>
            </Stack>
            <Stack>
              <Text size="sm">{defaultValues.customerName || '-'}</Text>
              <Text size="sm">{formatPhone(defaultValues.customerPhone)}</Text>
              <Text size="sm">{defaultValues.address}</Text>
              <Text size="sm">{formatDateTime(defaultValues.createdAt)}</Text>
              <Text size="sm">{defaultValues.promocode || '-'}</Text>
              <Text size="sm">{defaultValues.bonuses || '-'}</Text>
              <Text size="sm">
                {defaultValues.pickedAt ? formatDateTime(defaultValues.pickedAt) : '-'}
              </Text>
              <Text size="sm">
                {defaultValues.deliveredAt ? formatDateTime(defaultValues.deliveredAt) : '-'}
              </Text>
              <FormController
                name="deliveryTime"
                render={({ field }) => (
                  <Button
                    disabled={field.disabled}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('delivery-time'),
                        children: (
                          <Suspense>
                            <DeliveryToModal
                              baseDate={defaultValues.createdAt}
                              value={{
                                deliveryDate: defaultValues.deliveryDate,
                                deliveryTime: defaultValues.deliveryTime,
                              }}
                              onChange={value => {
                                form.setValue('deliveryDate', value.deliveryDate);
                                form.setValue('deliveryTime', value.deliveryTime);
                                submit();
                                modals.closeModal(modalId);
                              }}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {field.value === 'now' ? t('now') : field.value}
                  </Button>
                )}
              />
              <FormController
                name="paymentMethod"
                render={({ field, fieldState }) => (
                  <PaymentMethodSelect
                    {...field}
                    error={fieldState.error?.message}
                    onChange={paymentMethod => {
                      field.onChange(paymentMethod);
                      submit();
                    }}
                  />
                )}
              />
              <FormController
                name="pickerId"
                render={({ field, fieldState }) => (
                  <EmployeeSelect
                    {...field}
                    role={EmployeeRole.Picker}
                    error={fieldState.error?.message}
                    onChange={pickerId => {
                      field.onChange(pickerId);
                      submit();
                    }}
                  />
                )}
              />
              <FormController
                name="courierId"
                render={({ field, fieldState }) => (
                  <EmployeeSelect
                    {...field}
                    role={EmployeeRole.Courier}
                    error={fieldState.error?.message}
                    onChange={courierId => {
                      field.onChange(courierId);
                      submit();
                    }}
                  />
                )}
              />
            </Stack>
          </Group>
        );
      }}
    </Form>
  );
};
