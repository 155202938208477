import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { Employee } from 'shared/lib/bazar-api';
import { useRemoveEmployee } from '../hooks';

export const EmployeeRemoveModal = ({
  employee,
  onClose,
}: {
  employee: Employee;
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/employees', { keyPrefix: 'employee-remove-modal' });

  const { isRemoving, remove } = useRemoveEmployee({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{ confirm: t('remove-employee'), cancel: t('dont-remove-employee') }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ employeeId: employee.id })}
    >
      <Text size="sm">{t('employee-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
