import { useRoutes } from 'react-router-dom';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/cyrillic-400.css';
import '@fontsource/roboto/cyrillic-500.css';
import '@fontsource/roboto/cyrillic-700.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import { AuthProvider } from 'features/auth';
import { Suspense } from 'shared/components';
import { IS_DEVELOPMENT_ENV } from 'shared/config/env';
import './i18n';
import { MantineProviders } from './mantine-providers';
import { queryClient } from './query-client';
import { routes } from './routes';

dayjs.extend(localeData);
dayjs.extend(customParseFormat);

if (IS_DEVELOPMENT_ENV) {
  document.title = 'DEV: Bazar Admin';
  // const { bazarApiWorker } = require('shared/utils/bazar-api/worker');
  // bazarApiWorker.start({
  //   serviceWorker: { url: '/mock-service-worker.js' },
  //   onUnhandledRequest: 'bypass',
  // });
}

export const App = () => {
  const routesElement = useRoutes(routes);

  return (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MantineProviders>{routesElement}</MantineProviders>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
};
