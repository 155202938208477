import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ActionIcon, Box, NavLink, Tooltip } from '@mantine/core';
import { Menu2 as MenuIcon } from 'tabler-icons-react';
import {
  AnalyticsIcon,
  AssortmentIcon,
  CustomersIcon,
  EmployeesIcon,
  InformationIcon,
  MarketingIcon,
  OrdersIcon,
  ReportsIcon,
} from 'shared/icons';

const LINKS = [
  [
    { icon: <OrdersIcon />, url: '/orders' },
    { icon: <EmployeesIcon />, url: '/employees' },
    { icon: <InformationIcon />, url: '/darkstore' },
  ],
  [
    { icon: <AssortmentIcon />, url: '/products' },
    { icon: <AssortmentIcon />, url: '/stop-list' },
  ],
  [
    { icon: <CustomersIcon />, url: '/customers' },
    { icon: <MarketingIcon />, url: '/marketing' },
  ],
  [
    { icon: <ReportsIcon />, url: '/reports' },
    { icon: <CustomersIcon />, url: '/users' },
  ],
  [{ icon: <AnalyticsIcon />, url: '/monitoring' }],
];

export const Navbar = ({
  isNavbarOpened,
  onNavbarToggle,
}: {
  isNavbarOpened: boolean;
  onNavbarToggle: () => void;
}) => {
  const { t } = useTranslation('features/layouts', { keyPrefix: 'app-layout' });

  const { pathname } = useLocation();

  return (
    <Box
      component="nav"
      sx={{
        position: 'fixed',
        height: '100%',
        zIndex: 3,
        background: '#fff',
        width: isNavbarOpened ? '200px' : '48px',
        transition: 'width linear 0.2s',
        boxShadow: '1px 0px 0px #E9E9F1',
      }}
    >
      <Box
        sx={{
          padding: '16px 10px',
        }}
      >
        <ActionIcon onClick={onNavbarToggle}>
          <MenuIcon />
        </ActionIcon>
      </Box>
      <Box>
        {LINKS.map((section, sectionIndex) => {
          return (
            <Box
              key={sectionIndex}
              sx={{ '&:not(:last-of-type)': { borderBottom: '1px solid #E9E9F1' } }}
            >
              {section.map((link, linkIndex) => {
                const isActive = `/${pathname.split('/')[1]}` === link.url;
                return (
                  <Tooltip key={linkIndex} label={t(`links.${sectionIndex}.${linkIndex}`)}>
                    <NavLink
                      sx={theme => ({ color: theme.colors.gray[6] })}
                      noWrap
                      active={isActive}
                      component={Link}
                      to={link.url}
                      label={t(`links.${sectionIndex}.${linkIndex}`)}
                      icon={link.icon}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
