import { useQuery, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Darkstore, Supplier, SupplierProduct } from 'shared/lib/bazar-api';

export const useSupplierProducts = ({
  supplierId,
  darkstoreId,
}: {
  supplierId?: Supplier['id'];
  darkstoreId?: Darkstore['id'];
}) => {
  const queryClient = useQueryClient();

  const { isFetching, data, refetch } = useQuery({
    queryKey: ['supplier-products', { supplierId, darkstoreId }],
    queryFn: async () => {
      if (supplierId && darkstoreId) {
        const supplier = await bazarApi.suppliers.getOne({ id: supplierId, darkstoreId });
        return supplier.products;
      }
    },
    enabled: !!(supplierId && darkstoreId),
  });

  const updateProductRequest = ({
    id,
    value,
  }: {
    id: SupplierProduct['id'];
    value: SupplierProduct['amount'];
  }) => {
    queryClient.setQueryData<SupplierProduct[]>(
      ['supplier-products', { supplierId, darkstoreId }],
      data => {
        if (data) {
          const newData: SupplierProduct[] = JSON.parse(JSON.stringify(data));
          const index = newData.findIndex(row => row.id === id);
          newData[index].request = value;
          return newData;
        }
        return [];
      },
    );
  };

  return { isFetching, products: data ?? [], refetch, updateProductRequest };
};
