import type { RouteObject } from 'react-router-dom';
import {
  AnalyticsPage,
  BlacklistPage,
  CashAccountingPage,
  CustomerEditPage,
  CustomersPage,
  DarkstoreEditPage,
  DarkstoreZoneAddPage,
  DarkstoreZoneEditPage,
  EmployeesPage,
  HomePage,
  MarketingPage,
  MonitoringPage,
  NotFoundPage,
  OrderEditPage,
  OrdersPage,
  ProductAddPage,
  ProductEditPage,
  ProductsPage,
  RegistryPage,
  ReportsPage,
  SignInPage,
  StopListPage,
  SupplierAddPage,
  SupplierEditPage,
  SuppliersPage,
  SuppliesGeneratePage,
  SuppliesPage,
  SupplyEditPage,
  UsersPage,
  WriteOffsPage,
} from 'pages';
import { Protected } from 'features/auth';
import { AppLayout } from 'features/layouts';
import { SettingsInitializer } from 'features/settings';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <Protected>
        <SettingsInitializer>
          <AppLayout />
        </SettingsInitializer>
      </Protected>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: 'cash-accounting', element: <CashAccountingPage /> },
      {
        path: 'customers',
        children: [
          { index: true, element: <CustomersPage /> },
          { path: ':customerId', element: <CustomerEditPage /> },
          { path: 'blacklist', element: <BlacklistPage /> },
        ],
      },
      {
        path: 'darkstore',
        children: [
          { index: true, element: <DarkstoreEditPage /> },
          {
            path: 'zones',
            children: [
              { path: ':zoneId', element: <DarkstoreZoneEditPage /> },
              { path: 'add', element: <DarkstoreZoneAddPage /> },
            ],
          },
        ],
      },
      { path: 'employees', element: <EmployeesPage /> },
      { path: 'stop-list', element: <StopListPage /> },
      { path: 'marketing', element: <MarketingPage /> },
      {
        path: 'orders',
        children: [
          { index: true, element: <OrdersPage /> },
          { path: ':orderId', element: <OrderEditPage /> },
        ],
      },
      {
        path: 'products',
        children: [
          { index: true, element: <ProductsPage /> },
          { path: ':productId', element: <ProductEditPage /> },
          { path: 'add', element: <ProductAddPage /> },
        ],
      },
      { path: 'reports', element: <ReportsPage /> },
      { path: 'registry', element: <RegistryPage /> },
      {
        path: 'suppliers',
        children: [
          { index: true, element: <SuppliersPage /> },
          { path: ':supplierId', element: <SupplierEditPage /> },
          { path: 'add', element: <SupplierAddPage /> },
        ],
      },
      {
        path: 'supplies',
        children: [
          { index: true, element: <SuppliesPage /> },
          { path: ':supplyId', element: <SupplyEditPage /> },
        ],
      },
      { path: 'supplies-generate', element: <SuppliesGeneratePage /> },
      { path: 'users', element: <UsersPage /> },
      { path: 'write-offs', element: <WriteOffsPage /> },
      { path: 'monitoring', element: <MonitoringPage /> },
    ],
  },
  { path: 'sign-in', element: <SignInPage /> },
  { path: '*', element: <NotFoundPage /> },
];
