import { z } from 'zod';
import { UserRole } from 'shared/lib/bazar-api';

export const userFormSchema = z.object({
  partnerId: z.number().int().min(0),
  email: z.string().email(),
  name: z.string(),
  surname: z.string(),
  password: z.string().nullable(),
  role: z.nativeEnum(UserRole),
  darkstores: z.array(z.number().int().min(0)),
  acl: z.record(z.string(), z.object({ read: z.boolean(), write: z.boolean() })),
});
