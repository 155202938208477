export const CustomersIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16c2.762 0 5-2.238 5-5s-2.238-5-5-5-5 2.238-5 5 2.238 5 5 5Zm0 2.5c-3.338 0-10 1.675-10 5V26h20v-2.5c0-3.325-6.663-5-10-5Z"
      />
    </svg>
  );
};
