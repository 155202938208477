import { useTranslation } from 'react-i18next';
import { Button, Group } from '@mantine/core';
import { DatePicker, DateRangePicker } from '@mantine/dates';
import { z } from 'zod';
import { DarkstoreMultiSelect } from 'features/darkstores';
import { Form, FormController, FormWatch } from 'shared/lib/form';
import { ReportType } from '../types';
import { ReportDeliveryServicesSelect } from './report-delivery-services-select';
import { ReportTypeSelect } from './report-type-select';

const validationSchema = z.object({
  type: z.nativeEnum(ReportType),
  darkstoresIds: z.array(z.number().int().min(0)),
  period: z.array(z.date()).length(2),
  date: z.date(),
  service: z.string(),
});

type FiltersFormValues = z.infer<typeof validationSchema>;

export const ReportsFiltersForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FiltersFormValues>;
  onSubmit: (values: FiltersFormValues) => void;
}) => {
  const { t } = useTranslation('features/reports', { keyPrefix: 'reports-filters-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Group position="apart" align="flex-end">
        <Group>
          <FormController
            name="type"
            render={({ field, fieldState }) => (
              <ReportTypeSelect
                {...field}
                required
                label={t('report-type')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="darkstoresIds"
            render={({ field, fieldState }) => (
              <DarkstoreMultiSelect
                {...field}
                required
                label={t('darkstore')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormWatch
            name="type"
            render={type => {
              switch (type) {
                case 'fiscal':
                  return (
                    <FormController
                      name="period"
                      render={({ field, fieldState }) => (
                        <DateRangePicker
                          {...field}
                          required
                          label={t('period')}
                          placeholder={t('select-period')}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  );
                case 'orders-by-day':
                  return (
                    <FormController
                      name="date"
                      render={({ field, fieldState }) => (
                        <DatePicker
                          {...field}
                          required
                          label={t('date')}
                          placeholder={t('select-date')}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  );
                case 'stop-list':
                  return (
                    <FormController
                      name="period"
                      render={({ field, fieldState }) => (
                        <DateRangePicker
                          {...field}
                          required
                          label={t('period')}
                          placeholder={t('select-period')}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  );
                case 'delivery-services':
                  return (
                    <>
                      <FormController
                        name="date"
                        render={({ field, fieldState }) => (
                          <DatePicker
                            {...field}
                            required
                            label={t('date')}
                            placeholder={t('select-date')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                      <FormController
                        name="service"
                        render={({ field, fieldState }) => (
                          <ReportDeliveryServicesSelect
                            {...field}
                            required
                            label={t('select-service')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    </>
                  );
                default:
                  return null;
              }
            }}
          />
        </Group>
        <Button type="submit">{t('apply-filters')}</Button>
      </Group>
    </Form>
  );
};
