export const OrdersIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.589 13.175a.645.645 0 0 1-.636-.655V9.051C11.953 6.817 13.717 5 15.886 5c2.168 0 3.932 1.817 3.932 4.051v3.47a.646.646 0 0 1-.636.654.645.645 0 0 1-.635-.655V9.051c0-1.512-1.194-2.741-2.662-2.741-1.467 0-2.66 1.23-2.66 2.741v3.469a.645.645 0 0 1-.636.655Zm10.827-2.823 1.58 15.924a.679.679 0 0 1-.155.506.604.604 0 0 1-.46.217H7.619a.605.605 0 0 1-.46-.216.68.68 0 0 1-.156-.507l1.581-15.924c.033-.333.299-.586.616-.586h1.733v2.752c0 1.083.833 1.964 1.857 1.964s1.857-.88 1.857-1.964V9.766h2.707v2.752c0 1.083.832 1.964 1.856 1.964s1.857-.88 1.857-1.964V9.766H22.8c.317 0 .583.253.616.586Z"
      />
    </svg>
  );
};
