import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Breadcrumbs, Button, Group, Stack, Tabs, Text, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import {
  BlacklistAddressAddModal,
  BlacklistAddressesDataGrid,
  BlacklistCustomerAddModal,
  BlacklistCustomersDataGrid,
} from 'features/blacklist';
import { SearchInput, Suspense } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const BlacklistPage = () => {
  const [search, setSearch] = useSearch({ tab: 'customers', query: '' });

  const { t } = useTranslation('pages/customers');
  const modals = useModals();

  const hasWritePermission = usePermission('customers.write');

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to="/customers">
          {t('customers')}
        </Anchor>
        <Text> {t('blacklist')}</Text>
      </Breadcrumbs>
      <Tabs
        variant="pills"
        defaultValue={search.tab}
        onTabChange={tab => setSearch({ tab: tab ?? 'customers' })}
      >
        <Stack>
          <Group position="apart">
            <Group spacing={24}>
              <Title order={2}>{t('blacklist')}</Title>
              <Tabs.List>
                <Tabs.Tab value="customers">{t('customers')}</Tabs.Tab>
                <Tabs.Tab value="addresses">{t('addresses')}</Tabs.Tab>
              </Tabs.List>
            </Group>
            <Group>
              <Button
                disabled={!hasWritePermission}
                leftIcon={<PlusIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('add-customer'),
                    children: (
                      <Suspense>
                        <BlacklistCustomerAddModal onClose={() => modals.closeModal(modalId)} />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('add-customer')}
              </Button>
              <Button
                disabled={!hasWritePermission}
                leftIcon={<PlusIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('add-address'),
                    children: (
                      <Suspense>
                        <BlacklistAddressAddModal onClose={() => modals.closeModal(modalId)} />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('add-address')}
              </Button>
            </Group>
          </Group>
          {search.tab === 'customers' && (
            <SearchInput
              sx={{ flex: 1 }}
              value={search.query}
              onChange={query => setSearch({ query })}
            />
          )}
          <Tabs.Panel value="customers">
            <BlacklistCustomersDataGrid query={search.query} actionsColumn={hasWritePermission} />
          </Tabs.Panel>
          <Tabs.Panel value="addresses">
            <BlacklistAddressesDataGrid actionsColumn={hasWritePermission} />
          </Tabs.Panel>
        </Stack>
      </Tabs>
    </Stack>
  );
};
