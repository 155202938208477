import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Breadcrumbs, LoadingOverlay, Stack, Text, Title } from '@mantine/core';
import { usePermission } from 'features/auth';
import { useCategory } from 'features/categories';
import { ProductForm, useEditProduct, useProduct } from 'features/products';
import { useSearch } from 'shared/lib/search';
import { diff } from 'shared/utils/object';

export const ProductEditPage = () => {
  const { productId } = useParams();
  const [search] = useSearch<{ categoryId?: number }>({ categoryId: undefined });

  const { t } = useTranslation('pages/products');

  const hasWritePermission = usePermission('products.write');

  const { category } = useCategory({ categoryId: search.categoryId });
  const { isFetching, product } = useProduct({ productId: Number(productId) });
  const { isEditing, edit } = useEditProduct();

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  if (!product) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="..">
            {t('assortment')}
          </Anchor>
          {category && (
            <Anchor component={Link} to={`..?categoryId=${category.id}`}>
              {category.name}
            </Anchor>
          )}
          <Text>{t('product-not-found')}</Text>
        </Breadcrumbs>
        <Title order={1}>{t('product-not-found')}</Title>
      </Stack>
    );
  }

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to="..">
          {t('assortment')}
        </Anchor>
        {category && (
          <Anchor component={Link} to={`..?categoryId=${category.id}`}>
            {category.name}
          </Anchor>
        )}
        <Text>{product.name}</Text>
      </Breadcrumbs>
      <ProductForm
        title={product.name}
        disabled={!hasWritePermission}
        isSubmitting={isEditing}
        defaultValues={product}
        onSubmit={data => {
          edit({
            productId: product.id,
            data: { darkstoreId: data.darkstoreId, ...diff(product, data) },
          });
        }}
      />
    </Stack>
  );
};
