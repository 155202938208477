import { useState } from 'react';
import { ActionIcon, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { GripVertical as GripVerticalIcon } from 'tabler-icons-react';
import { SubcategorySelect } from 'features/categories';
import { useSettings } from 'features/settings';
import { SortableList } from 'shared/components';
import { Category, Darkstore, Product, Subcategory } from 'shared/lib/bazar-api';
import { useProductsWithOrder, useReorderProducts } from '../hooks';

export const ProductsList = ({ categoryId }: { categoryId: Category['id'] }) => {
  const [subcategoryId, setSubcategoryId] = useState<number>();

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, products } = useProductsWithOrder({ categoryId });
  const { isReordering, reorder } = useReorderProducts();

  const ordered = products
    .filter(item => item.order.find(o => o.subcategoryId === subcategoryId))
    .map(item => {
      const order = item.order.find(o => o.subcategoryId === subcategoryId);
      //@ts-ignore
      return { id: item.id, name: item.name, order: order.value };
    })
    .sort((a, b) => (a.order > b.order ? 1 : -1));

  return (
    <Stack sx={{ position: 'relative' }}>
      <LoadingOverlay visible={isFetching || isReordering} />
      <SubcategorySelect
        categoryId={categoryId}
        value={subcategoryId}
        onChange={setSubcategoryId}
      />
      <Stack spacing={4}>
        <SortableList
          items={ordered.map(p => p.id.toString())}
          onItemsChange={items => {
            const data: {
              id: Product['id'];
              darkstoreId: Darkstore['id'];
              order: { subcategoryId: Subcategory['id']; value: number }[];
            }[] = [];
            items.forEach((id, index) => {
              const product = products.find(o => o.id.toString() === id);
              if (product) {
                const orderIndex = product.order.findIndex(o => o.subcategoryId === subcategoryId);
                product.order[orderIndex].value = index;
                data.push({ ...product, darkstoreId });
              }
            });
            reorder({ data });
          }}
          renderItem={({ id, style, attributes, listeners, setNodeRef }) => {
            const product = ordered.find(p => p.id.toString() === id);
            if (!product) {
              return null;
            }
            return (
              <Group ref={setNodeRef} style={style} spacing={16} position="apart" align="center">
                <Text
                  sx={{
                    flex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {product.name}
                </Text>
                <ActionIcon {...listeners} {...attributes}>
                  <GripVerticalIcon size={14} />
                </ActionIcon>
              </Group>
            );
          }}
        />
      </Stack>
    </Stack>
  );
};
