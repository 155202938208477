import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Box, Button, Group, Popover, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DrawingManager, GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import {
  Pencil as PencilIcon,
  Trash as TrashIcon,
  Check as CheckIcon,
  X as XIcon,
} from 'tabler-icons-react';
import { useSettings } from 'features/settings';
import { GOOGLE_API_KEY } from 'shared/config/env';
import { DarkstoreZonePoint } from 'shared/lib/bazar-api';
import { usePolygons } from '../../hooks';

const LIBRARIES: ['drawing'] = ['drawing'];

const CENTER = [
  { lat: 53.893009, lng: 27.567444 },
  { lat: 59.939095, lng: 30.315868 },
  { lat: 50.45447, lng: 30.52418 },
  { lat: 51.169392, lng: 71.449074 },
  { lat: 53.13333, lng: 23.16433 },
];

type Mode = 'default' | 'drawing' | 'editing' | 'confirm-drawing' | 'cancel-drawing';

export const DarkstoreZoneMap = ({
  editingToolbar,
  points,
  onPointsChange,
}: {
  editingToolbar?: boolean;
  points: DarkstoreZonePoint[];
  onPointsChange: (polygon: DarkstoreZonePoint[]) => void;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-zone-form' });

  const partnerId = useSettings(settings => settings.partnerId);

  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const [mode, setMode] = useState<Mode>('default');
  const [opened, { close, open }] = useDisclosure(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: LIBRARIES,
    language,
  });

  const { polygons } = usePolygons();

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        minHeight: '640px',
        borderRadius: '4px',
      }}
      options={{
        streetViewControl: false,
        // mapTypeControl: false,
        fullscreenControl: false,
      }}
      center={CENTER[partnerId]}
      zoom={11}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'inline-block',
          padding: '0 6px',
          backgroundColor: 'rgba(245, 245, 245, 0.7)',
          color: '#000',
          fontSize: '10px',
        }}
      >
        {t('map-help')}
      </Box>
      {editingToolbar && (
        <Stack spacing="xs" sx={{ position: 'absolute', top: '24px', right: '10px' }}>
          {mode === 'default' ? (
            <>
              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  setMode(points.length > 0 ? 'editing' : 'drawing');
                }}
              >
                <PencilIcon size={18} />
              </ActionIcon>
              <Popover
                position="bottom-end"
                offset={10}
                width="200px"
                opened={opened}
                onClose={close}
              >
                <Popover.Target>
                  <ActionIcon variant="light" size="lg" onClick={open}>
                    <TrashIcon size={18} />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack spacing="xs">
                    <Text size="xs">{t('map-zone-remove-confirmation')}</Text>
                    <Group spacing="xs" position="right">
                      <Button size="xs" variant="light" onClick={close}>
                        {t('no')}
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => {
                          close();
                          polygonRef.current?.setPath([]);
                          onPointsChange([]);
                        }}
                      >
                        {t('yes')}
                      </Button>
                    </Group>
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            </>
          ) : (
            <>
              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  if (mode === 'drawing') {
                    setMode('confirm-drawing');
                  } else {
                    setMode('default');
                    const path = polygonRef.current
                      ?.getPath()
                      .getArray()
                      .map(xy => ({ lat: xy.lat(), lng: xy.lng() }));
                    onPointsChange(path ?? []);
                  }
                }}
              >
                <CheckIcon size={18} />
              </ActionIcon>
              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  if (mode === 'drawing') {
                    setMode('cancel-drawing');
                  } else {
                    setMode('default');
                    polygonRef.current?.setPath(points);
                  }
                }}
              >
                <XIcon size={18} />
              </ActionIcon>
            </>
          )}
        </Stack>
      )}
      {polygons.map((polygon, index) => (
        <Polygon
          key={index}
          path={polygon.points}
          options={{
            fillColor: 'rgba(74, 74, 82, 0.4)',
            fillOpacity: 1,
            strokeColor: 'rgba(74, 74, 82, 0.8)',
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      ))}
      <Polygon
        path={points}
        editable={mode === 'editing'}
        options={{
          fillColor: 'rgba(255, 122, 0, 0.4)',
          fillOpacity: 1,
          strokeColor: 'rgba(255, 122, 0, 0.8)',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
        onLoad={instance => {
          polygonRef.current = instance;
        }}
        onRightClick={(e: google.maps.MapMouseEvent & { vertex?: number }) => {
          if (typeof e.vertex === 'number') {
            polygonRef.current?.getPath().removeAt(e.vertex);
          }
        }}
      />
      <DrawingManager
        drawingMode={mode === 'drawing' ? google.maps.drawing.OverlayType.POLYGON : null}
        options={{
          drawingControl: false,
          polygonOptions: {
            fillColor: 'rgba(255, 122, 0, 0.4)',
            fillOpacity: 1,
            strokeColor: 'rgba(255, 122, 0, 0.8)',
            strokeOpacity: 1,
            strokeWeight: 2,
          },
        }}
        onPolygonComplete={polygon => {
          switch (mode) {
            case 'drawing': {
              setMode('editing');
              const path = polygon
                .getPath()
                .getArray()
                .map(xy => ({ lat: xy.lat(), lng: xy.lng() }));
              polygon.setPath([]);
              polygonRef.current?.setPath(path);
              break;
            }
            case 'confirm-drawing': {
              setMode('default');
              const path = polygon
                .getPath()
                .getArray()
                .map(xy => ({ lat: xy.lat(), lng: xy.lng() }));
              polygon.setPath([]);
              onPointsChange(path);
              break;
            }
            case 'cancel-drawing': {
              setMode('default');
              polygon.setPath([]);
              break;
            }
            default:
              break;
          }
        }}
      />
    </GoogleMap>
  );
};
