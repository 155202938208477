import { forwardRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Group, Select, SelectItemProps, Sx, Text } from '@mantine/core';

const OPTIONS = [
  {
    label: 'English',
    value: 'en',
    countryCode: 'us',
  },
  {
    label: 'Русский',
    value: 'ru',
    countryCode: 'ru',
  },
  {
    label: 'Українська',
    value: 'uk',
    countryCode: 'ua',
  },
  {
    label: 'Polski',
    value: 'pl',
    countryCode: 'pl',
  },
];

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps & { countryCode: string }>(
  (props, ref) => {
    const { label, countryCode, ...rest } = props;
    return (
      <div ref={ref} {...rest}>
        <Group noWrap>
          <ReactCountryFlag svg countryCode={countryCode} />
          <Text size="sm">{label}</Text>
        </Group>
      </div>
    );
  },
);

export const LanguageSelect = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: string | null;
  error?: string;
  onChange?: (value: string) => void;
}) => {
  const { t } = useTranslation('shared', { keyPrefix: 'language-select' });

  const selected = OPTIONS.find(o => o.value === value);

  return (
    <Select
      className={className}
      sx={sx}
      required={required}
      label={label}
      placeholder={t('select-language')}
      data={OPTIONS}
      value={value}
      error={error}
      itemComponent={SelectItem}
      icon={selected && <ReactCountryFlag svg countryCode={selected.countryCode} />}
      onChange={onChange}
    />
  );
};
