import { EmployeeRole, EmployeeStatus } from 'shared/lib/bazar-api';

export const EMPLOYEE_ROLES = [
  { label: 'Комплектовщик', value: EmployeeRole.Picker },
  { label: 'Курьер', value: EmployeeRole.Courier },
];

export const EMPLOYEE_ROLES_MAP = {
  [EmployeeRole.Picker]: 'Комплектовщик',
  [EmployeeRole.Courier]: 'Курьер',
};

export const EMPLOYEE_STATUSES = [
  { label: 'Вне работы', value: EmployeeStatus.Outside },
  { label: 'На смене', value: EmployeeStatus.Working },
  { label: 'На больничном', value: EmployeeStatus.Sick },
  { label: 'В отпуске', value: EmployeeStatus.Vacation },
  { label: 'Уволен', value: EmployeeStatus.Dismissed },
];

export const EMPLOYEE_STATUSES_MAP = {
  [EmployeeStatus.Outside]: 'Вне работы',
  [EmployeeStatus.Working]: 'На смене',
  [EmployeeStatus.Sick]: 'На больничном',
  [EmployeeStatus.Vacation]: 'В отпуске',
  [EmployeeStatus.Dismissed]: 'Уволен',
};
