import { useSettings } from 'features/settings';
import { StopList } from 'shared/lib/bazar-api';
import { useAddStopListItem } from '../hooks';
import { StopListItemForm } from './stop-list-item-form';

export const StopListAddModal = ({
  stopList,
  onClose,
}: {
  stopList?: StopList;
  onClose: () => void;
}) => {
  const { isAdding, add } = useAddStopListItem({ onSuccess: onClose });

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const handleSubmit = (data: any) => {
    add({ data });
  };

  return (
    <StopListItemForm
      isSubmitting={isAdding}
      defaultValues={{
        darkstoreId,
        productId: '',
        reason: '',
        finish: '',
        interval: '',
        time: '',
        ...stopList,
      }}
      //onSubmit={data => add({ data })}
      onSubmit={handleSubmit}
    />
  );
};
