import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Subcategory } from 'shared/lib/bazar-api';

type ReorderSubcategoriesDTO = {
  data: {
    id: Subcategory['id'];
    name: string;
    order: number;
  }[];
};

export const useReorderSubcategories = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, ReorderSubcategoriesDTO>({
    mutationFn: variables => {
      return bazarApi.assortment.reorderSubcategories(variables.data);
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
    },
  });

  return { isReordering: isLoading, reorder: mutate };
};
