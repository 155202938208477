import { ReactNode } from 'react';
import { Stack } from '@mantine/core';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridContainer = ({ children }: { children: ReactNode }) => {
  const {
    props: { className, sx },
  } = useDataGridContext();

  return (
    <Stack
      className={className}
      spacing={8}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
