import { useMutation } from '@tanstack/react-query';
import { bazarApi, Darkstore, Supplier, SupplierProduct } from 'shared/lib/bazar-api';

type GenerateSuppliesDTO = {
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  expectedAt: Date;
  products: SupplierProduct[];
};

export const useGenerateSupplies = () => {
  const { isLoading, data, mutate } = useMutation<unknown, Error, GenerateSuppliesDTO>({
    mutationFn: variables => bazarApi.supplies.add(variables),
  });

  return { isGenerating: isLoading, supplies: data, generate: mutate };
};
