import { forwardRef } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { PAYMENT_TERM_TYPES } from 'features/suppliers/constants';
import { PaymentTermType } from 'shared/lib/bazar-api';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: PaymentTermType | null;
  onChange: (value: PaymentTermType) => void;
};

export const PaymentTermTypeSelect = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Select ref={ref} data={PAYMENT_TERM_TYPES} {...props} />;
});
