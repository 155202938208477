import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@mantine/core';
import { REPORT_DELVIERY_SERVICES } from '../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: string | null;
  onChange: (value: string) => void;
};

export const ReportDeliveryServicesSelect = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { t } = useTranslation('features/reports', { keyPrefix: 'report-type-select' });

  return <Select {...ref} placeholder={t('service')} data={REPORT_DELVIERY_SERVICES} {...props} />;
});
