import { useTranslation } from 'react-i18next';
import { Button, Group, Stack, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { EmployeeAddModal, EmployeesDataGrid, EmployeeRoleSelect } from 'features/employees';
import { SearchInput, Suspense } from 'shared/components';
import { EmployeeRole } from 'shared/lib/bazar-api';
import { useSearch } from 'shared/lib/search';

export const EmployeesPage = () => {
  const [search, setSearch] = useSearch({ role: EmployeeRole.Courier, query: '' });

  const { t } = useTranslation('pages/employees');
  const modals = useModals();

  const hasWritePermission = usePermission('employees.write');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('employees')}</Title>
      <Group>
        <EmployeeRoleSelect
          sx={{ minWidth: 220 }}
          value={search.role}
          onChange={role => setSearch({ role })}
        />
        <SearchInput
          sx={{ flex: 1 }}
          value={search.query}
          onChange={query => setSearch({ query })}
        />
        <Button
          disabled={!hasWritePermission}
          leftIcon={<PlusIcon size={14} />}
          onClick={() => {
            const modalId = modals.openModal({
              title: t('add-employee'),
              children: (
                <Suspense>
                  <EmployeeAddModal onClose={() => modals.closeModal(modalId)} />
                </Suspense>
              ),
            });
          }}
        >
          {t('add-employee')}
        </Button>
      </Group>
      <EmployeesDataGrid
        employeeRole={search.role}
        query={search.query}
        actionsColumn={hasWritePermission}
      />
    </Stack>
  );
};
