import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, Category, Subcategory, Supplier } from 'shared/lib/bazar-api';

export const useRegistry = ({
  query,
  categoryId,
  subcategoryId,
  supplierId,
}: {
  query: string;
  categoryId?: Category['id'];
  subcategoryId?: Subcategory['id'];
  supplierId?: Supplier['id'];
}) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data, refetch } = useQuery({
    queryKey: ['registry', { partnerId, query, categoryId, subcategoryId, supplierId }],
    queryFn: () => {
      return bazarApi.assortment.getRegistry({
        partnerId,
        query,
        categoryId,
        subcategoryId,
        supplierId,
      });
    },
  });

  return { isFetching, registry: data ?? [], refetch };
};
