import { bazarApiClient } from '../client';
import { User } from '../types';

const signIn = async (variables: { email: string; password: string }) => {
  const body = JSON.stringify(variables);
  const res = await bazarApiClient.post(`admin/login`, { body });
  const data = await res.json<any>();
  return data;
};

const getUser = async (): Promise<User> => {
  const res = await bazarApiClient.get('admin/user');
  const data = await res.json<any>();
  return data;
};

export const auth = { signIn, getUser };
