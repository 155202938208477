import { useTranslation } from 'react-i18next';
import { NumberInput } from '@mantine/core';
import { ProductFormValues } from 'features/products/types';
import { useSettings } from 'features/settings';
import { useFormController } from 'shared/lib/form';

export const ProductDiscountedPrice = () => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  const { darkstoreId } = useSettings();

  const { field } = useFormController<ProductFormValues, 'discounts'>({
    name: 'discounts',
  });

  const discount = field.value.find(a => a.darkstoreId === darkstoreId);

  return (
    <NumberInput
      readOnly
      hideControls
      precision={2}
      label={t('discounted-price')}
      placeholder={t('input-discounted-price')}
      value={discount ? discount.price : 0}
    />
  );
};
