import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { useMonitoring } from '../hooks';

dayjs.extend(duration);

export const MonitoringDataGrid = ({ actionsColumn }: { actionsColumn?: boolean }) => {
  const { t } = useTranslation('features/monitoring', { keyPrefix: 'monitoring-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    const dataColumns = [
      columnHelper.accessor('darkstoreName', {
        size: 200,
        meta: { sticky: true },
        header: () => t('darkstore'),
      }),
      columnHelper.accessor('amount', {
        size: 100,
        header: () => t('orders'),
      }),
      columnHelper.accessor('delivered', {
        size: 100,
        header: () => t('delivered'),
      }),
      columnHelper.accessor('opened', {
        size: 100,
        header: () => t('opened'),
      }),
      columnHelper.accessor('aggregators', {
        size: 100,
        header: () => t('aggregators'),
      }),
      columnHelper.accessor('picking', {
        size: 100,
        header: () => t('picking'),
      }),
      columnHelper.accessor('delivering', {
        size: 100,
        header: () => t('delivering'),
      }),
      columnHelper.accessor('canceled', {
        size: 100,
        header: () => t('canceled'),
      }),
      columnHelper.accessor('sum', {
        size: 150,
        header: () => t('sum'),
      }),
      columnHelper.accessor('deliveredSum', {
        size: 150,
        header: () => t('deliveredSum'),
      }),
      columnHelper.accessor('couriersAmount', {
        size: 100,
        header: () => t('couriersAmount'),
      }),
      columnHelper.accessor('averageDeliveringTime', {
        size: 150,
        header: () => t('averageDeliveringTime'),
        cell: params => {
          const value = params.getValue();
          if (!value || value < 0) {
            return '-';
          }
          const minutes = Math.trunc(value / 60);
          const seconds = value - minutes * 60;
          return dayjs.duration({ minutes, seconds }).format('mm:ss');
        },
      }),
      columnHelper.accessor('averagePickingTime', {
        size: 150,
        header: () => t('averagePickingTime'),
        cell: params => {
          const value = params.getValue();
          if (!value || value < 0) {
            return '-';
          }
          const minutes = Math.trunc(value / 60);
          const seconds = value - minutes * 60;
          return dayjs.duration({ minutes, seconds }).format('mm:ss');
        },
      }),
      columnHelper.accessor('latenessAmount', {
        size: 100,
        header: () => t('latenessAmount'),
      }),
      columnHelper.accessor('latenessPercent', {
        size: 100,
        header: () => t('latenessPercent'),
        cell: params => `${params.getValue() * 100}%`,
      }),
      columnHelper.accessor('averageScore', {
        size: 100,
        header: () => t('averageScore'),
      }),
    ];
    return dataColumns;
  }, [actionsColumn, t]);

  const { isFetching, monitoring } = useMonitoring({ darkstoresIds: [1, 2, 3, 4, 5, 6, 7] });

  return <DataGrid loading={isFetching} columns={columns} data={monitoring} />;
};
