import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Menu } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { Suspense } from 'shared/components';
import { BlacklistCustomer } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatPhone } from 'shared/utils/phone';
import { useBlacklistCustomers } from '../hooks';
import { BlacklistCustomerEditModal } from './blacklist-customer-edit-modal';
import { BlacklistCustomerRemoveModal } from './blacklist-customer-remove-modal';

export const BlacklistCustomersDataGrid = ({
  query,
  actionsColumn,
}: {
  query: string;
  actionsColumn?: boolean;
}) => {
  const { t } = useTranslation('features/blacklist', {
    keyPrefix: 'blacklist-customers-data-grid',
  });

  const modals = useModals();

  const [debouncedQuery] = useDebouncedValue(query, 300);
  const { isFetching, customers } = useBlacklistCustomers({ query: debouncedQuery });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<BlacklistCustomer>();
    const dataColumns = [
      columnHelper.accessor('phone', {
        size: 250,
        header: () => t('columns.0'),
        cell: params => formatPhone(params.getValue()),
      }),
      columnHelper.accessor('minOrderSum', {
        size: 150,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('amnesty', {
        size: 150,
        header: () => t('columns.2'),
      }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<PencilIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('edit-blacklist-customer'),
                        children: (
                          <Suspense>
                            <BlacklistCustomerEditModal
                              customer={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('edit')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-blacklist-customer'),
                        children: (
                          <Suspense>
                            <BlacklistCustomerRemoveModal
                              customer={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [actionsColumn, t, modals]);

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={customers}
      sorting
      sx={{ minHeight: '500px' }}
    />
  );
};
