import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Anchor, Breadcrumbs, Stack, Text } from '@mantine/core';
import { usePermission } from 'features/auth';
import { useCategory } from 'features/categories';
import { ProductForm, useAddProduct } from 'features/products';
import { useSettings } from 'features/settings';
import { useSearch } from 'shared/lib/search';

export const ProductAddPage = () => {
  const navigate = useNavigate();
  const [search] = useSearch<{ categoryId?: number }>({ categoryId: undefined });

  const { t } = useTranslation('pages/products');

  const hasWritePermission = usePermission('products.write');
  const partnerId = useSettings(settings => settings.partnerId);
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { category } = useCategory({ categoryId: search.categoryId });
  const { isAdding, add } = useAddProduct({
    onSuccess: data => {
      navigate(`../${data.id}?categoryId=${category?.id}`, { replace: true });
    },
  });

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to="..">
          {t('assortment')}
        </Anchor>
        {category && (
          <Anchor component={Link} to={`..?categoryId=${category.id}`}>
            {category.name}
          </Anchor>
        )}
        <Text>{t('add-product')}</Text>
      </Breadcrumbs>
      <ProductForm
        title={t('add-product')}
        disabled={!hasWritePermission}
        isSubmitting={isAdding}
        defaultValues={{
          partnerId,
          darkstoreId,

          categories: [{ id: undefined, subcategories: [{ id: undefined }] }],
          availability: [],
          discounts: [],
          suppliers: [
            {
              supplierId: undefined,
              supplierPrice: 0,
              minRequest: 0,
              vat: 0,
              extraCharge: 0,
            },
          ],
          enabled: false,
          liquidating: false,
          splitting: false,
          weighted: false,
          image: '',
          name: '',
          country: '',
          price: 0,
          weight: 0,
          liquid: false,
          pricePerMeasure: 0,
          description: '',
          barcode: '',
          sku: '',
          importer: '',
          manufacturer: '',
          brand: '',
          storageConditions: '',
          content: '',
          minAmount: 0,
          shortTerm: 0,
          protein: 0,
          fat: 0,
          carbs: 0,
          kcal: 0,
          kj: 0,
        }}
        onSubmit={data => add({ data })}
      />
    </Stack>
  );
};
