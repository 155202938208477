import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Breadcrumbs, Button, Card, Group, Stack, Text, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import {
  OrderForm,
  OrderProductAddModal,
  OrderProductsDataGrid,
  OrderStatusBadge,
  OrderStatusMenu,
  useEditOrder,
  useOrder,
} from 'features/orders';
import { Suspense } from 'shared/components';
import { OrderStatus } from 'shared/lib/bazar-api';
import { diff } from 'shared/utils/object';

export const OrderEditPage = () => {
  const { orderId } = useParams();

  const { t } = useTranslation('pages/orders');
  const modals = useModals();

  const hasWritePermission = usePermission('orders.write');

  const { order } = useOrder({ orderId: Number(orderId) });
  const { edit } = useEditOrder();

  if (!order) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="..">
            {t('orders')}
          </Anchor>
          <Text>{t('order-not-found')}</Text>
        </Breadcrumbs>
        <Title order={2}>{t('order-not-found')}</Title>
      </Stack>
    );
  }

  const canEdit = hasWritePermission && order.status !== OrderStatus.Canceled;

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Breadcrumbs>
        <Anchor component={Link} to="..">
          {t('orders')}
        </Anchor>
        <Text>
          {t('order')} {order.id}
        </Text>
      </Breadcrumbs>
      <Group spacing={24} align="flex-start">
        <Card shadow="sm" radius="sm" sx={{ flex: 1 }}>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Group position="apart">
              <Title order={2}>
                {t('order')} {order.id}
              </Title>
              <OrderStatusMenu
                disabled={!canEdit}
                control={<OrderStatusBadge status={order.status} size="lg" />}
                onItemClick={item => edit({ orderId: order.id, data: { status: item.value } })}
              />
            </Group>
          </Card.Section>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Text weight={500}>
              {t('sosedi')} {order.idSosedi}
            </Text>
            <Text weight={500}>
              {t('yandex')} {order.idYandex}
            </Text>
            <Text weight={500}>
              {t('delivio')} {order.idDelivio}
            </Text>
          </Card.Section>
          <Card.Section sx={{ padding: 16 }}>
            <OrderForm
              disabled={!canEdit}
              defaultValues={order}
              onSubmit={data => edit({ orderId: order.id, data: diff(order, data) })}
            />
          </Card.Section>
          <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
            <Group position="apart">
              <Text size="sm" weight={500}>
                {t('sum')}
              </Text>
              <Text size="sm">{order.sum}</Text>
            </Group>
          </Card.Section>
        </Card>
        <Card shadow="sm" radius="sm" sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
          <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
            <Group position="apart">
              <Title order={2}>{t('customer-order')}</Title>
              <Button
                disabled={!canEdit}
                leftIcon={<PlusIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('add-product'),
                    children: (
                      <Suspense>
                        <OrderProductAddModal
                          orderId={order.id}
                          onClose={() => modals.closeModal(modalId)}
                        />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('add-product')}
              </Button>
            </Group>
          </Card.Section>
          <Card.Section sx={{ flexGrow: 1, padding: 16 }}>
            <OrderProductsDataGrid
              orderId={order.id}
              data={order.products}
              actionsColumn={canEdit}
            />
          </Card.Section>
        </Card>
      </Group>
    </Stack>
  );
};
