import { useTranslation } from 'react-i18next';
import { Button, NumberInput, Stack, TextInput } from '@mantine/core';
import { SupplierProductSelect } from 'features/suppliers';
import { Darkstore, Supplier } from 'shared/lib/bazar-api';
import { Form, FormController } from 'shared/lib/form';
import { supplyProductFormSchema } from '../schemas';
import { SupplyProductFormValues } from '../types';

export const SupplyProductForm = ({
  darkstoreId,
  supplierId,
  mode,
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  mode: 'add' | 'edit';
  isSubmitting: boolean;
  defaultValues: Partial<SupplyProductFormValues>;
  onSubmit: (values: SupplyProductFormValues) => void;
}) => {
  const { t } = useTranslation('features/supplies', { keyPrefix: 'supply-product-form' });

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={supplyProductFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        {mode === 'add' ? (
          <FormController
            name="productId"
            render={({ field, fieldState }) => (
              <SupplierProductSelect
                darkstoreId={darkstoreId}
                supplierId={supplierId}
                required
                label={t('product')}
                value={field.value}
                error={fieldState.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        ) : (
          <TextInput disabled label={t('product')} defaultValue={defaultValues.productName} />
        )}
        <FormController
          name="amount"
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              required
              precision={3}
              label={t('amount')}
              placeholder={t('input-amount')}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t(mode === 'add' ? 'add' : 'save')}
        </Button>
      </Stack>
    </Form>
  );
};
