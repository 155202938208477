import { OrderStatus, PaymentMethod } from 'shared/lib/bazar-api';

export const ORDERS_STATUSES = [
  { label: 'Новый', value: OrderStatus.New },
  { label: 'Комплектуется', value: OrderStatus.Collecting },
  { label: 'Собран', value: OrderStatus.Collected },
  { label: 'Доставляется', value: OrderStatus.Delivering },
  { label: 'Передан', value: OrderStatus.Transferred },
  { label: 'Доставлен', value: OrderStatus.Delivered },
  { label: 'Отменен', value: OrderStatus.Canceled },
];

export const ORDERS_STATUSES_MAP = {
  [OrderStatus.New]: 'Новый',
  [OrderStatus.Collecting]: 'Комплектуется',
  [OrderStatus.Collected]: 'Собран',
  [OrderStatus.Delivering]: 'Доставляется',
  [OrderStatus.Transferred]: 'Передан',
  [OrderStatus.Delivered]: 'Доставлен',
  [OrderStatus.Canceled]: 'Отменен',
};

export const PAYMENT_METHODS = [
  { label: 'Наличными', value: PaymentMethod.Cash },
  { label: 'Картой курьеру', value: PaymentMethod.CardOffline },
  { label: 'Картой онлайн', value: PaymentMethod.CardOnline },
  { label: 'Google Pay', value: PaymentMethod.GooglePay },
  { label: 'Apple Pay', value: PaymentMethod.ApplePay },
  { label: 'Онлайн перевод', value: PaymentMethod.TransferOnline },
  { label: 'Оплати', value: PaymentMethod.Oplati },
];

export const PAYMENT_METHODS_MAP = {
  [PaymentMethod.Cash]: 'Наличными',
  [PaymentMethod.CardOffline]: 'Картой курьеру',
  [PaymentMethod.CardOnline]: 'Картой онлайн',
  [PaymentMethod.GooglePay]: 'Google Pay',
  [PaymentMethod.ApplePay]: 'Apple Pay',
  [PaymentMethod.TransferOnline]: 'Онлайн перевод',
  [PaymentMethod.Oplati]: 'Оплати',
};
