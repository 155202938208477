import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { Logout as LogoutIcon } from 'tabler-icons-react';
import { useAuth } from '../hooks';

export const SignOutButton = () => {
  const { t } = useTranslation('features/auth', { keyPrefix: 'sign-out-button' });

  const auth = useAuth();

  return (
    <Button leftIcon={<LogoutIcon size={18} />} onClick={auth.signOut}>
      {t('sign-out')}
    </Button>
  );
};
