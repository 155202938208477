import { useTranslation } from 'react-i18next';
import { Stack, Title } from '@mantine/core';
import { MonitoringDataGrid } from 'features/monitoring';

export const MonitoringPage = () => {
  const { t } = useTranslation('pages/monitoring');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('monitoring')}</Title>
      <MonitoringDataGrid />
    </Stack>
  );
};
