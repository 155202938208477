import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, DarkstoreZone } from 'shared/lib/bazar-api';
import type { DarkstoreZoneFormValues } from '../types';

type EditDarkstoreZoneDTO = {
  zoneId: DarkstoreZone['id'];
  data: DarkstoreZoneFormValues;
};

export const useEditDarkstoreZone = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditDarkstoreZoneDTO>({
    mutationFn: variables => {
      return bazarApi.darkstoreZones.edit({ id: variables.zoneId, ...variables.data });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['darkstore-zones'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
