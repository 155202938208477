import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useBanners = () => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['banners', { darkstoreId }],
    queryFn: () => bazarApi.banners.getList({ darkstoreId }),
  });

  return { isFetching, banners: data ?? [] };
};
