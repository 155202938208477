import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { Category, Subcategory } from 'shared/lib/bazar-api';
import { useSubcategories } from '../hooks';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  categoryId?: Category['id'];
  value?: Subcategory['id'] | null;
  onChange: (value: Subcategory['id']) => void;
};

export const SubcategorySelect = forwardRef<HTMLInputElement, Props>(
  ({ categoryId, value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/categories', { keyPrefix: 'subcategory-select' });

    const { isFetching, subcategories } = useSubcategories({ categoryId });

    const data: SelectItem[] = subcategories.map(s => ({
      label: s.name,
      value: s.id.toString(),
    }));

    return (
      <Select
        ref={ref}
        searchable
        placeholder={t('select-subcategory')}
        data={data}
        value={value?.toString()}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
