import { Group, Input, Stack, Text } from '@mantine/core';
import { useDarkstores } from 'features/darkstores';
import { Product } from 'shared/lib/bazar-api';

export const ProductAvailabilityModal = ({ data }: { data: Product['availability'] }) => {
  const { darkstores } = useDarkstores({ refetchOnMount: false });

  return (
    <Stack>
      {darkstores.map(darkstore => {
        const availability = data.find(d => d.darkstoreId === darkstore.id);
        return (
          <Group key={darkstore.id} position="apart">
            <Text size="sm">{darkstore.name}</Text>
            <Input readOnly value={availability ? availability.amount : 0} />
          </Group>
        );
      })}
    </Stack>
  );
};
