import { useQuery } from '@tanstack/react-query';
import { bazarApi, Supply } from 'shared/lib/bazar-api';

export const useSupply = ({ supplyId }: { supplyId?: Supply['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['supplies', { supplyId }],
    queryFn: () => {
      if (supplyId) {
        return bazarApi.supplies.getOne({ id: supplyId });
      }
    },
    enabled: !!supplyId,
    suspense: true,
  });

  return { isFetching, supply: data };
};
