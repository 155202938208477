import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Banner, bazarApi } from 'shared/lib/bazar-api';
import type { BannerFormValues } from '../types';

type EditBannerDTO = {
  bannerId: Banner['id'];
  data: BannerFormValues;
};

export const useEditBanner = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: EditBannerDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditBannerDTO>({
    mutationFn: variables => {
      return bazarApi.banners.edit({
        id: variables.bannerId,
        ...variables.data,
      });
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['banners'] });
      onSuccess(data, variables);
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
