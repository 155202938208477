import { formatDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { CashRow, Darkstore } from '../types';

const getList = async ({
  darkstoreId,
  date,
}: {
  darkstoreId: Darkstore['id'];
  date: Date;
}): Promise<CashRow[]> => {
  const res = await bazarApiClient.get('admin/cash/list', {
    searchParams: { darkstoreId, date: formatDate(date, 'YYYY-MM-DD') },
  });
  const data = await res.json<{ rows: any[] }>();
  return data.rows.map(row => ({
    id: row.id,
    employeeId: row.employeeId,
    employeeName: row.employeeName,
    employeeSurname: row.employeeSurname,
    cashRegister: row.cashRegister ?? 0,
    deposit: row.deposit ?? 0,
    cash: row.cash ?? 0,
    card: row.card ?? 0,
    deficit: row.deficit ?? 0,
  }));
};

export const cash = { getList };
