import { forwardRef } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { DarkstoreZoneStatus } from 'shared/lib/bazar-api';
import { DARKSTORE_ZONE_STATUSES } from '../../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: DarkstoreZoneStatus | null;
  onChange: (value: DarkstoreZoneStatus) => void;
};

export const DarkstoreZoneStatusSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    return (
      <Select
        ref={ref}
        data={DARKSTORE_ZONE_STATUSES.map(s => ({ label: s.label, value: s.value.toString() }))}
        value={value?.toString()}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
