export const EmployeesIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.364 14.857c1.81 0 3.261-1.531 3.261-3.428C23.625 9.53 22.175 8 20.364 8c-1.811 0-3.273 1.531-3.273 3.429 0 1.897 1.462 3.428 3.273 3.428Zm-8.728 0c1.811 0 3.262-1.531 3.262-3.428C14.898 9.53 13.448 8 11.636 8c-1.81 0-3.272 1.531-3.272 3.429 0 1.897 1.461 3.428 3.272 3.428Zm0 2.286c-2.541 0-7.636 1.337-7.636 4V25h15.273v-3.857c0-2.663-5.095-4-7.637-4Zm8.728 0c-.317 0-.677.023-1.059.057 1.266.96 2.15 2.251 2.15 3.943V25H28v-3.857c0-2.663-5.095-4-7.636-4Z"
      />
    </svg>
  );
};
