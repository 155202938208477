import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Darkstore } from 'shared/lib/bazar-api';
import type { DarkstoreFormValues } from '../types';

type EditDarkstoreDTO = {
  darkstoreId: Darkstore['id'];
  data: DarkstoreFormValues & Pick<Darkstore, 'zones'>;
};

export const useEditDarkstore = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditDarkstoreDTO>({
    mutationFn: variables => {
      return bazarApi.darkstores.edit({ id: variables.darkstoreId, ...variables.data });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['darkstores'] });
    },
  });

  return { isEditing: isLoading, edit: mutate };
};
