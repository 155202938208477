import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useStopList = () => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['stop-list', { darkstoreId }],
    queryFn: async () => {
      return bazarApi.stopList.getList({
        darkstoreId,
      });
    },
  });

  const { rows } = data ?? { rows: [] };

  return { isFetching, stopList: rows };
};
