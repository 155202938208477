import { FILESTORAGE_URL } from 'shared/config/env';
import { formatDate, parseDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import {
  Category,
  Darkstore,
  Product,
  ProductRow,
  Subcategory,
  Supplier,
  WriteOffReason,
  WriteOffRow,
  WriteOffType,
} from '../types';

const getCategories = async ({
  darkstoreId,
}: {
  darkstoreId: Darkstore['id'];
}): Promise<Category[]> => {
  const res = await bazarApiClient.get(`admin/assortiment/categories/${darkstoreId}`);
  const data = await res.json<any[]>();
  return data
    .map(item => ({
      id: item.id,
      name: item.name,
      image: `${FILESTORAGE_URL}/${item.img}`,
      icon: `${FILESTORAGE_URL}/${item.icon}`,
      order: item.sort,
      promoted: Boolean(item.isPromo),
    }))
    .sort((a, b) => (a.order > b.order ? 1 : -1));
};

const getCategory = async ({ id }: Pick<Category, 'id'>): Promise<Category> => {
  const res = await bazarApiClient.get(`products/categories/${id}`);
  const data = await res.json<any>();
  return {
    id: data.id,
    name: data.name,
    image: `${FILESTORAGE_URL}/${data.img}`,
    icon: `${FILESTORAGE_URL}/${data.icon}`,
    order: data.sort,
    promoted: Boolean(data.isPromo),
  };
};

const addCategory = async (variables: Omit<Category, 'id'> & { partnerId: number }) => {
  const body = JSON.stringify({
    partnerId: variables.partnerId,
    name: variables.name,
    img: variables.image.replace(`${FILESTORAGE_URL}/`, ''),
    icon: variables.icon.replace(`${FILESTORAGE_URL}/`, ''),
    sort: variables.order,
    isPromo: variables.promoted ? 1 : 0,
  });
  const res = await bazarApiClient.post(`admin/assortiment/categories`, { body });
  const data = await res.json();
  return data;
};

const editCategory = async (variables: Category & { partnerId: number }) => {
  const body = JSON.stringify({
    categoryId: variables.id,
    partnerId: variables.partnerId,
    name: variables.name,
    img: variables.image.replace(`${FILESTORAGE_URL}/`, ''),
    icon: variables.icon.replace(`${FILESTORAGE_URL}/`, ''),
    sort: variables.order,
    isPromo: variables.promoted ? 1 : 0,
  });
  const res = await bazarApiClient.put(`admin/assortiment/categories`, { body });
  const data = await res.json();
  return data;
};

const removeCategory = async (variables: Pick<Category, 'id'>) => {
  const res = await bazarApiClient.delete(`admin/assortiment/categories/${variables.id}`);
  const data = await res.json();
  return data;
};

const getSubcategories = async ({
  categoryId,
}: {
  categoryId: Category['id'];
}): Promise<Subcategory[]> => {
  const res = await bazarApiClient.get(`products/categories/filters/${categoryId}`);
  const data = await res.json<any[]>();
  return data
    .map(item => ({
      id: item.id,
      categoryId,
      name: item.value,
      order: item.sort,
    }))
    .sort((a, b) => (a.order > b.order ? 1 : -1));
};

const reorderSubcategories = async (
  variables: {
    id: Subcategory['id'];
    name: string;
    order: number;
  }[],
) => {
  const requests = variables.map(v => {
    const body = JSON.stringify({ id: v.id, name: v.name, sort: v.order });
    return bazarApiClient.put('admin/assortiment/categories/sub', { body });
  });
  const responses = await Promise.all(requests);
  const data = await Promise.all(responses.map(res => res.json()));
  return data;
};

const addSubcategory = async (variables: Omit<Subcategory, 'id'>) => {
  const body = JSON.stringify({
    categoryId: variables.categoryId,
    name: variables.name,
    sort: variables.order,
  });
  const res = await bazarApiClient.post(`admin/assortiment/categories/sub`, { body });
  const data = await res.json();
  return data;
};

const editSubcategory = async (variables: Subcategory) => {
  const body = JSON.stringify({
    id: variables.id,
    categoryId: variables.categoryId,
    name: variables.name,
    sort: variables.order,
  });
  const res = await bazarApiClient.put(`admin/assortiment/categories/sub`, { body });
  const data = await res.json();
  return data;
};

const removeSubcategory = async ({ id }: Pick<Subcategory, 'id'>) => {
  const res = await bazarApiClient.delete(`admin/assortiment/categories/sub/${id}`);
  const data = await res.json();
  return data;
};

const getCategoryProducts = async ({
  categoryId,
  darkstoreId,
  sortBy = 'name',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  categoryId: Category['id'];
  darkstoreId: Darkstore['id'];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{ rows: ProductRow[]; totalPages: number }> => {
  const res = await bazarApiClient.get(
    `admin/assortiment/productsCategory/${categoryId}/${darkstoreId}`,
    { searchParams: { sortField: sortBy, sortOrder, page: pageNumber, pageSize } },
  );
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => {
      const discount = row.discounts.find((d: any) => d.darkstoreId === darkstoreId) ?? {
        discountSize: 0,
        discountPrice: 0,
      };
      const availability = row.availability.find((a: any) => a.darkstoreId === darkstoreId) ?? {
        qty: 0,
      };
      return {
        id: row.product_id,
        enabled: Boolean(row.status),
        name: row.name,
        price: row.price,
        discount: discount.discountSize,
        discountedPrice: discount.discountPrice,
        extraCharge: row.extraCharge ?? 0,
        amount: availability.qty,
        barcode: row.cod,
        supplierName: row.providerName,
        subcategoriesNames: row.subCategories.map((s: any) => s.subCategoryName).join(', '),
      };
    }),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const getProductsWithOrder = async ({
  categoryId,
}: {
  categoryId: Category['id'];
}): Promise<
  {
    id: Product['id'];
    name: string;
    order: { subcategoryId: Subcategory['id']; value: number }[];
  }[]
> => {
  const res = await bazarApiClient.get('admin/assortiment/productsSorting', {
    searchParams: { categoryId },
  });
  const data = await res.json<any[]>();
  return data.map(item => ({
    id: item.productId,
    name: item.name,
    order: item.subCategories.map((s: any) => ({
      subcategoryId: s.subCategoryId,
      value: s.sort,
    })),
  }));
};

const reorderProducts = async (
  variables: {
    id: Product['id'];
    darkstoreId: Darkstore['id'];
    order: { subcategoryId: Subcategory['id']; value: number }[];
  }[],
) => {
  const requests = variables.map(v => {
    const body = JSON.stringify({
      id: v.id,
      darkstoreId: v.darkstoreId,
      sort: v.order.map(o => ({ subCategoryId: o.subcategoryId, sort: o.value })),
    });
    return bazarApiClient.put('admin/assortiment/products', { body });
  });
  const responses = await Promise.all(requests);
  const data = await Promise.all(responses.map(res => res.json()));
  return data;
};

const searchProducts = async ({
  darkstoreId,
  query,
}: {
  darkstoreId: Darkstore['id'];
  query: string;
}): Promise<Pick<ProductRow, 'id' | 'name' | 'amount'>[]> => {
  const encodedText = encodeURIComponent(query);
  const res = await bazarApiClient.get(`products/search/${encodedText}/${darkstoreId}`);
  const data = await res.json<any[]>();
  return data.map(item => ({ id: item.id, name: item.name, amount: item.qty }));
};

const getOneProduct = async ({
  id,
  partnerId,
  darkstoreId,
}: {
  id: Product['id'];
  partnerId: number;
  darkstoreId: Darkstore['id'];
}): Promise<Product> => {
  const res = await bazarApiClient.get(`admin/assortiment/products/${id}/${darkstoreId}`);
  const data = await res.json<any>();
  return {
    id,
    partnerId,
    darkstoreId,
    categories: data.categories.map((c: any) => ({
      id: c.categoryId,
      subcategories: data.subCategories
        .filter((s: any) => s.categoryId === c.categoryId)
        .map((s: any) => ({ id: s.subCategoryId })),
    })),
    availability: data.availability.map((a: any) => ({
      darkstoreId: a.darkstoreId,
      amount: a.qty,
    })),
    discounts: data.discounts.map((d: any) => ({
      darkstoreId: d.darkstoreId,
      type: d.discountType,
      from: d.discountStartTime ? parseDate(d.discountStartTime, 'HH:mm:ss') : null,
      to: d.discountFinishTime ? parseDate(d.discountFinishTime, 'HH:mm:ss') : null,
      date: d.discountFinishDate ? parseDate(d.discountFinishDate, 'YYYY-MM-DD') : null,
      size: d.discountSize,
      price: d.discountPrice,
    })),
    suppliers: data.providers.map((p: any) => ({
      supplierId: p.providerId,
      supplierPrice: p.pricePurchases,
      minRequest: p.minPurchases,
      vat: p.vat,
      extraCharge: p.extraCharge,
    })),
    image: `${FILESTORAGE_URL}/${data.img}`,
    name: data.name,
    country: data.country,
    enabled: Boolean(data.status),
    splitting: Boolean(data.isSplit),
    liquidating: Boolean(data.isFinishSelling),
    weighted: Boolean(data.isWeightGoods),
    price: data.price,
    liquid: Boolean(data.liter),
    weight: Number(data.weight),
    pricePerMeasure: data.pricePerKg,
    description: data.description,
    barcode: data.cod,
    sku: data.sku,
    importer: data.importer,
    manufacturer: data.manufacturer,
    brand: data.brand,
    storageConditions: data.expiryFull,
    content: data.composition,
    minAmount: data.qtyMin,
    shortTerm: data.shortTerm,
    protein: Number(data.protein),
    fat: Number(data.fat),
    carbs: Number(data.carbohydrate),
    kcal: Number(data.calorie),
    kj: Number(data.kj),
  };
};

const addProduct = async (variables: Omit<Product, 'id'>): Promise<{ id: Product['id'] }> => {
  const body = JSON.stringify({
    partnerId: variables.partnerId,
    darkstoreId: variables.darkstoreId,
    categories: variables.categories.map(c => ({
      categoryId: c.id,
      // TODO: replace with actual name ?_?
      categoryName: '',
    })),
    subCategories: variables.categories
      .map(c => {
        return c.subcategories.map(s => ({
          subCategoryId: s.id,
          categoryId: c.id,
          // TODO: replace with actual name ?_?
          subCategoryName: '',
        }));
      })
      .flat(),
    availability: variables.availability.map(a => ({
      darkstoreId: a.darkstoreId,
      qty: a.amount,
    })),
    discounts: variables.discounts.map(d => ({
      darkstoreId: d.darkstoreId,
      discountType: d.type,
      discountStartTime: d.from ? formatDate(d.from, 'HH:mm:ss') : null,
      discountFinishTime: d.to ? formatDate(d.to, 'HH:mm:ss') : null,
      discountFinishDate: d.date ? formatDate(d.date, 'YYYY-MM-DD') : null,
      discountSize: d.size,
      discountPrice: d.price,
    })),
    providers: variables.suppliers.map(s => ({
      providerId: s.supplierId,
      // TODO: replace with actual name ?_?
      providerName: '',
      pricePurchases: s.supplierPrice,
      minPurchases: s.minRequest,
      vat: s.vat,
      extraCharge: s.extraCharge,
    })),
    img: variables.image,
    name: variables.name,
    country: variables.country,
    status: variables.enabled ? 1 : 0,
    isSplit: variables.splitting ? 1 : 0,
    isFinishSelling: variables.liquidating ? 1 : 0,
    isWeightGoods: variables.weighted ? 1 : 0,
    price: variables.price,
    liter: variables.liquid ? 1 : 0,
    weight: variables.weight,
    pricePerKg: variables.pricePerMeasure,
    description: variables.description,
    cod: variables.barcode,
    sku: variables.sku,
    importer: variables.importer,
    manufacturer: variables.manufacturer,
    brand: variables.brand,
    expiryFull: variables.storageConditions,
    composition: variables.content,
    qtyMin: variables.minAmount,
    shortTerm: variables.shortTerm,
    protein: variables.protein,
    fat: variables.fat,
    carbohydrate: variables.carbs,
    calorie: variables.kcal,
    kj: variables.kj,
  });
  const res = await bazarApiClient.post('admin/assortiment/products', { body });
  const data = await res.json<any>();
  return { id: data.productId };
};

const editProduct = async (variables: Pick<Product, 'id' | 'darkstoreId'> & Partial<Product>) => {
  const body = JSON.stringify({
    id: variables.id,
    partnerId: variables.partnerId,
    darkstoreId: variables.darkstoreId,
    categories: variables.categories
      ? variables.categories.map(c => ({
          categoryId: c.id,
          // TODO: replace with actual name ?_?
          categoryName: '',
        }))
      : undefined,
    subCategories: variables.categories
      ? variables.categories
          .map(c => {
            return c.subcategories.map(s => ({
              subCategoryId: s.id,
              // TODO: replace with actual name ?_?
              subCategoryName: '',
              categoryId: c.id,
            }));
          })
          .flat()
      : undefined,
    availability: variables.availability
      ? variables.availability.map(a => ({
          darkstoreId: a.darkstoreId,
          qty: a.amount,
        }))
      : undefined,
    discounts: variables.discounts
      ? variables.discounts.map(d => ({
          darkstoreId: d.darkstoreId,
          discountType: d.type,
          discountStartTime: d.from ? formatDate(d.from, 'HH:mm:ss') : null,
          discountFinishTime: d.to ? formatDate(d.to, 'HH:mm:ss') : null,
          discountFinishDate: d.date ? formatDate(d.date, 'YYYY-MM-DD') : null,
          discountSize: d.size,
          discountPrice: d.price,
        }))
      : undefined,
    providers: variables.suppliers
      ? variables.suppliers.map(s => ({
          providerId: s.supplierId,
          // TODO: replace with actual name ?_?
          providerName: '',
          pricePurchases: s.supplierPrice,
          minPurchases: s.minRequest,
          vat: s.vat,
          extraCharge: s.extraCharge,
        }))
      : undefined,
    img: variables.image,
    name: variables.name,
    country: variables.country,
    status: typeof variables.enabled === 'undefined' ? undefined : variables.enabled ? 1 : 0,
    isSplit: typeof variables.splitting === 'undefined' ? undefined : variables.splitting ? 1 : 0,
    isFinishSelling:
      typeof variables.liquidating === 'undefined' ? undefined : variables.liquidating ? 1 : 0,
    isWeightGoods:
      typeof variables.weighted === 'undefined' ? undefined : variables.weighted ? 1 : 0,
    price: variables.price,
    liter: typeof variables.liquid === 'undefined' ? undefined : variables.liquid ? 1 : 0,
    weight: variables.weight,
    pricePerKg: variables.pricePerMeasure,
    description: variables.description,
    cod: variables.barcode,
    sku: variables.sku,
    importer: variables.importer,
    manufacturer: variables.manufacturer,
    brand: variables.brand,
    expiryFull: variables.storageConditions,
    composition: variables.content,
    qtyMin: variables.minAmount,
    shortTerm: variables.shortTerm,
    protein: variables.protein,
    fat: variables.fat,
    carbohydrate: variables.carbs,
    calorie: variables.kcal,
    kj: variables.kj,
  });
  const res = await bazarApiClient.put('admin/assortiment/products', { body });
  const data = await res.json();
  return data;
};

const getWriteOffs = async ({
  darkstoreId,
  month,
  year,
}: {
  darkstoreId: Darkstore['id'];
  month: number;
  year: number;
}): Promise<WriteOffRow[]> => {
  const res = await bazarApiClient.get('admin/assortiment/writeOff', {
    searchParams: { darkstoreId, month, year },
  });
  const data = await res.json<any[]>();
  return data.map(item => ({
    productId: item.productId,
    employeeId: item.employeeId,
    productName: item.name,
    productAmount: item.qty,
    productPrice: item.price,
    productSupplierPrice: item.pricePurchases,
    productVat: item.vat,
    reason: item.reason,
    employeeName: item.employeeName,
    employeeSurname: item.employeeSurname,
    createdAt: parseDate(`${item.date} ${item.time}`, 'DD.MM.YYYY HH:mm'),
  }));
};

const getReturns = async ({
  partnerId,
  darkstoreId,
  supplierId,
  from,
  to,
}: {
  partnerId: number;
  darkstoreId?: Darkstore['id'];
  supplierId?: Supplier['id'];
  from: Date;
  to: Date;
}): Promise<WriteOffRow[]> => {
  const searchParams: {
    partnerId: number;
    darkstoreId?: Darkstore['id'];
    supplierId?: Supplier['id'];
    dateFrom: string;
    dateTo: string;
  } = {
    partnerId,
    dateFrom: formatDate(from, 'YYYY-MM-DD'),
    dateTo: formatDate(to, 'YYYY-MM-DD'),
  };
  if (darkstoreId) {
    searchParams.darkstoreId = darkstoreId;
  }
  if (supplierId) {
    searchParams.supplierId = supplierId;
  }
  const res = await bazarApiClient.get('admin/returns', { searchParams });
  const data = await res.json<any[]>();
  return data.map(item => ({
    productId: item.productId,
    employeeId: item.employeeId,
    productName: item.name,
    productAmount: item.qty,
    productPrice: item.price,
    productSupplierPrice: item.pricePurchases,
    productVat: item.vat,
    reason: item.reason,
    employeeName: item.employeeName,
    employeeSurname: item.employeeSurname,
    createdAt: parseDate(`${item.date} ${item.time}`, 'DD.MM.YYYY HH:mm'),
  }));
};

const getCorrectQty = async ({
  darkstoreId,
  reason,
  month,
  year,
  type,
}: {
  darkstoreId: Darkstore['id'];
  reason: WriteOffReason;
  month: number;
  year: number;
  type: WriteOffType;
}): Promise<WriteOffRow[]> => {
  const searchParams: {
    darkstoreId: Darkstore['id'];
    reason: WriteOffReason;
    month: number;
    year: number;
    type?: WriteOffType;
  } = {
    darkstoreId,
    reason,
    month,
    year,
  };
  if (type !== WriteOffType.Any) {
    searchParams.type = type;
  }
  const res = await bazarApiClient.get('admin/assortiment/correctQty', { searchParams });
  const data = await res.json<any[]>();
  return data.map(item => ({
    productId: item.productId,
    employeeId: item.employeeId,
    productName: item.name,
    productAmount: item.qty,
    productPrice: item.price,
    productSupplierPrice: item.pricePurchases,
    productVat: item.vat,
    reason: item.reason,
    employeeName: item.employeeName,
    employeeSurname: item.employeeSurname,
    createdAt: parseDate(`${item.date} ${item.time}`, 'DD.MM.YYYY HH:mm'),
  }));
};

// TODO: add types
const getRegistry = async ({
  partnerId,
  query,
  categoryId,
  subcategoryId,
  supplierId,
}: {
  partnerId: number;
  query: string;
  categoryId?: Category['id'];
  subcategoryId?: Subcategory['id'];
  supplierId?: Supplier['id'];
}) => {
  const searchParams: {
    partnerId: number;
    search?: string;
    categories?: Category['id'];
    subCategories?: Subcategory['id'];
    providers?: Supplier['id'];
  } = { partnerId };
  if (query) {
    searchParams.search = query;
  }
  if (categoryId) {
    searchParams.categories = categoryId;
  }
  if (subcategoryId) {
    searchParams.subCategories = subcategoryId;
  }
  if (supplierId) {
    searchParams.providers = supplierId;
  }
  const res = await bazarApiClient.get('admin/assortiment/registry', { searchParams });
  const data = await res.json<any[]>();
  return data;
};

export const assortment = {
  getCategories,
  getCategory,
  addCategory,
  editCategory,
  removeCategory,
  getSubcategories,
  reorderSubcategories,
  addSubcategory,
  editSubcategory,
  removeSubcategory,
  getCategoryProducts,
  getProductsWithOrder,
  reorderProducts,
  searchProducts,
  getOneProduct,
  addProduct,
  editProduct,
  getWriteOffs,
  getReturns,
  getCorrectQty,
  getRegistry,
};
