import { LoadingOverlay } from '@mantine/core';
import { RowData } from '@tanstack/react-table';
import { DataGridBody } from './data-grid-body';
import { DataGridContainer } from './data-grid-container';
import { DataGridContent } from './data-grid-content';
import { DataGridContextProvider } from './data-grid-context-provider';
import { DataGridFooter } from './data-grid-footer';
import { DataGridHeader } from './data-grid-header';
import { DataGridScroll } from './data-grid-scroll';
import { DataGridProps } from './types';

export const DataGrid = <TData extends RowData>(props: DataGridProps<TData>) => {
  return (
    <DataGridContextProvider props={props}>
      <DataGridContainer>
        <DataGridScroll>
          <LoadingOverlay visible={!!props.loading} />
          <DataGridContent>
            <DataGridHeader />
            <DataGridBody />
          </DataGridContent>
        </DataGridScroll>
        <DataGridFooter />
      </DataGridContainer>
    </DataGridContextProvider>
  );
};
