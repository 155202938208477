import { FieldPath, FieldValues, PathValue, useFormContext, useWatch } from 'react-hook-form';

export const FormWatch = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  render,
}: {
  name: TName;
  render: (controller: PathValue<TFieldValues, TName>) => JSX.Element | null;
}) => {
  const { control } = useFormContext<TFieldValues>();

  const value = useWatch<TFieldValues, TName>({ control, name });

  return render(value);
};
