import { Outlet } from 'react-router-dom';
import { Box, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Suspense } from 'shared/components';
import { Header } from './header';
import { Navbar } from './navbar';

export const AppLayout = () => {
  const [isOpened, handlers] = useDisclosure(localStorage.getItem('navbar-is-opened') === 'true', {
    onOpen: () => localStorage.setItem('navbar-is-opened', 'true'),
    onClose: () => localStorage.removeItem('navbar-is-opened'),
  });

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
      }}
    >
      <Header isNavbarOpened={isOpened} />
      <Navbar isNavbarOpened={isOpened} onNavbarToggle={handlers.toggle} />
      <ScrollArea sx={{ flex: 1 }}>
        <Box
          component="main"
          sx={{
            position: 'relative',
            zIndex: 1,
            minHeight: 'calc(100vh - 60px)',
            marginTop: '60px',
            marginLeft: isOpened ? '200px' : '48px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F9FAFC',
            transition: 'margin-left linear 0.2s',
          }}
        >
          <Suspense>
            <Outlet />
          </Suspense>
        </Box>
      </ScrollArea>
    </Box>
  );
};
