import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import { UserFormValues } from '../types';

type AddUserDTO = {
  data: UserFormValues;
};

export const useAddUser = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddUserDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddUserDTO>({
    mutationFn: variables => bazarApi.users.add(variables.data),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Пользователь добавлен',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
