import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Group, Stack, Tabs, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { BannerAddModal, BannersCardsGrid } from 'features/banners';
import {
  PromoCodeAddModal,
  PromoCodesCampaignAddModal,
  PromoCodesCampaignsDataGrid,
  PromoCodesDataGrid,
} from 'features/promo-codes';
import { Suspense } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const MarketingPage = () => {
  const [search, setSearch] = useSearch({ tab: 'banners' });

  const { t } = useTranslation('pages/marketing');
  const modals = useModals();

  const hasWritePermission = usePermission('marketing.write');

  return (
    <Stack spacing={0} sx={{ flex: 1, margin: '-24px' }}>
      <Tabs
        keepMounted={false}
        defaultValue={search.tab}
        onTabChange={tab => setSearch({ tab: tab ?? 'customers' })}
      >
        <Tabs.List sx={{ padding: '0px 24px' }}>
          <Tabs.Tab value="banners">{t('banners')}</Tabs.Tab>
        </Tabs.List>
        <Box sx={{ padding: '24px', maxHeight: 'calc(100vh - 108px)', overflow: 'auto' }}>
          <Suspense>
            <Tabs.Panel value="banners">
              <Card shadow="sm" radius="sm">
                <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
                  <Group position="apart">
                    <Title order={3}>{t('banners')}</Title>
                    <Button
                      disabled={!hasWritePermission}
                      leftIcon={<PlusIcon size={14} />}
                      onClick={() => {
                        const modalId = modals.openModal({
                          title: t('add-banner'),
                          children: (
                            <Suspense>
                              <BannerAddModal onClose={() => modals.closeModal(modalId)} />
                            </Suspense>
                          ),
                        });
                      }}
                    >
                      {t('add-banner')}
                    </Button>
                  </Group>
                </Card.Section>
                <Card.Section sx={{ padding: 16 }}>
                  <BannersCardsGrid actionsMenu={hasWritePermission} />
                </Card.Section>
              </Card>
            </Tabs.Panel>
          </Suspense>
        </Box>
      </Tabs>
    </Stack>
  );
};
