import { useTranslation } from 'react-i18next';
import { Anchor, Col } from '@mantine/core';
import { SubcategorySelect } from 'features/categories';
import { FormController, useFormFieldArray } from 'shared/lib/form';
import type { ProductFormValues } from '../../types';

export const ProductSubcategories = ({
  categoryId,
  categoryIndex,
}: {
  categoryId?: number;
  categoryIndex: number;
}) => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  const { fields, append, remove } = useFormFieldArray<ProductFormValues>({
    name: `categories.${categoryIndex}.subcategories`,
  });

  return (
    <>
      {fields.map((field, index) => (
        <Col key={field.id} span={4} sx={{ position: 'relative' }}>
          <FormController
            name={`categories.${categoryIndex}.subcategories.${index}.id`}
            render={({ field, fieldState }) => (
              <SubcategorySelect
                {...field}
                required
                categoryId={categoryId}
                disabled={field.disabled || !categoryId}
                label={t('subcategory')}
                error={fieldState.error?.message}
              />
            )}
          />
          {!field.disabled && (
            <>
              {index === 0 ? (
                <Anchor
                  sx={{ position: 'absolute', top: 12, right: 8 }}
                  size="xs"
                  //@ts-ignore
                  onClick={() => append({ id: undefined })}
                >
                  {t('add-subcategory')}
                </Anchor>
              ) : (
                <Anchor
                  sx={{ position: 'absolute', top: 12, right: 8 }}
                  size="xs"
                  onClick={() => remove(index)}
                >
                  {t('remove-subcategory')}
                </Anchor>
              )}
            </>
          )}
        </Col>
      ))}
    </>
  );
};
