export const groupBy = <T>(array: readonly T[], getGroupId: (item: T) => string) => {
  return array.reduce((acc, item) => {
    const groupId = getGroupId(item);
    const groupArray = acc[groupId] ?? [];
    return { ...acc, [groupId]: [...groupArray, item] };
  }, {} as Record<string, T[]>);
};

export const cluster = <T>(array: readonly T[], size: number = 2): T[][] => {
  const clusterCount = Math.ceil(array.length / size);
  return new Array(clusterCount).fill(null).map((_c: null, i: number) => {
    return array.slice(i * size, i * size + size);
  });
};
