import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { Banner } from 'shared/lib/bazar-api';
import { useRemoveBanner } from '../hooks';

export const BannerRemoveModal = ({
  bannerId,
  onClose,
}: {
  bannerId: Banner['id'];
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/banners', { keyPrefix: 'banner-remove-modal' });

  const { isRemoving, remove } = useRemoveBanner({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{ confirm: t('remove-banner'), cancel: t('dont-remove-banner') }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ bannerId })}
    >
      <Text size="sm">{t('banner-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
