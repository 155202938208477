import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const usePolygons = () => {
  const { zoneId } = useParams();
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data = [] } = useQuery({
    queryKey: ['polygons'],
    queryFn: bazarApi.darkstoreZones.getPolygons,
  });

  const polygons = data.filter(
    p => !(p.darkstoreId === darkstoreId && p.zoneId === Number(zoneId)),
  );

  return { isFetching, polygons };
};
