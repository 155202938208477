import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BlacklistAddress } from 'shared/lib/bazar-api';
import { bazarApi } from 'shared/lib/bazar-api';

type RemoveBlacklistCustomerDTO = {
  customerId: BlacklistAddress['id'];
};

export const useRemoveBlacklistCustomer = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveBlacklistCustomerDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveBlacklistCustomerDTO>({
    mutationFn: async variables => {
      return bazarApi.blacklist.removeCustomer({ id: variables.customerId });
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Клиент удален из черного списка',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['blacklist-customers'] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
