import { Darkstore, Supplier, Supply } from 'shared/lib/bazar-api';
import { useAddSupplyProduct } from '../hooks';
import { SupplyProductForm } from './supply-product-form';

export const SupplyProductAddModal = ({
  supplyId,
  darkstoreId,
  supplierId,
  onClose,
}: {
  supplyId: Supply['id'];
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  onClose: () => void;
}) => {
  const { isAdding, add } = useAddSupplyProduct({ onSuccess: onClose });

  return (
    <SupplyProductForm
      darkstoreId={darkstoreId}
      supplierId={supplierId}
      mode="add"
      isSubmitting={isAdding}
      defaultValues={{ productId: undefined, productName: undefined, amount: 1 }}
      onSubmit={data => add({ supplyId, data })}
    />
  );
};
