import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'features/auth';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useDarkstores = ({ refetchOnMount = true }: { refetchOnMount?: boolean } = {}) => {
  const auth = useAuth();

  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data } = useQuery({
    queryKey: ['darkstores', { partnerId }],
    queryFn: async () => {
      if (auth.user) {
        const { darkstores: userDarkstores = [] } = auth.user;
        const darkstores = await bazarApi.darkstores.getList({ partnerId });
        return darkstores.filter(d => userDarkstores.includes(d.id));
      }
    },
    refetchOnMount,
  });

  return { isFetching, darkstores: data ?? [] };
};
