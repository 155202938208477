import { Checkbox, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { PAYMENT_METHODS } from 'features/orders';
import { useFormController } from 'shared/lib/form';
import { DarkstoreZoneFormValues } from '../../types';

export const DarkstoreZonePaymentMethods = () => {
  const { field } = useFormController<DarkstoreZoneFormValues, 'paymentMethods'>({
    name: 'paymentMethods',
  });

  return (
    <Stack spacing="xs">
      {PAYMENT_METHODS.map(p => {
        const checked = field.value.includes(p.value);
        return (
          <UnstyledButton
            key={p.value}
            disabled={field.disabled}
            sx={theme => ({
              backgroundColor: 'white',
              padding: '12px 16px',
              borderRadius: '4px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: theme.colors.gray[4],
            })}
            onClick={() => {
              const checked = field.value.includes(p.value);
              if (checked) {
                field.onChange(field.value.filter(v => v !== p.value));
              } else {
                field.onChange([...field.value, p.value].sort());
              }
            }}
          >
            <Group>
              <Checkbox
                disabled={field.disabled}
                styles={{ input: { cursor: 'pointer' } }}
                tabIndex={-1}
                checked={checked}
                onChange={() => {}}
              />
              <Text size="sm" weight={checked ? 500 : 400}>
                {p.label}
              </Text>
            </Group>
          </UnstyledButton>
        );
      })}
    </Stack>
  );
};
