import dayjs from 'dayjs';

export const parseDate = (date: string, format?: string) => {
  const tmp = dayjs(date, format);
  const isValid = tmp.isValid();
  if (!isValid) {
    console.warn(`Invalid date: ${date}, return current date object`);
    return new Date();
  }
  return tmp.toDate();
};

export const formatDate = (date: Date, format = 'DD.MM.YYYY') => {
  const tmp = dayjs(date);
  const isValid = tmp.isValid();
  if (!isValid) {
    console.warn(`Invalid date: ${date}, return formatted current date string`);
    return dayjs().format(format);
  }
  return tmp.format(format);
};

export const formatDateTime = (date: Date, format = 'DD.MM.YYYY HH:mm:ss') => {
  return formatDate(date, format);
};
