import { useTranslation } from 'react-i18next';
import { Button, Stack, PasswordInput, TextInput } from '@mantine/core';
import { PhoneInput } from 'shared/components';
import { Form, FormController } from 'shared/lib/form';
import { employeeFormSchema } from '../schemas';
import type { EmployeeFormValues } from '../types';
import { EmployeeRoleSelect } from './employee-role-select';

export const EmployeeForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<EmployeeFormValues>;
  onSubmit: (values: EmployeeFormValues) => void;
}) => {
  const { t } = useTranslation('features/employees', { keyPrefix: 'employee-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={employeeFormSchema} onSubmit={onSubmit}>
      <Stack align="stretch">
        <FormController
          name="phone"
          render={({ field, fieldState }) => (
            <PhoneInput
              {...field}
              required
              label={t('phone')}
              placeholder={t('input-phone')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="name"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              label={t('name')}
              placeholder={t('input-name')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="surname"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              label={t('surname')}
              placeholder={t('input-surname')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="password"
          render={({ field, fieldState }) => (
            <PasswordInput
              {...field}
              required
              autoComplete="new-password"
              label={t('password')}
              placeholder={t('input-password')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="role"
          render={({ field, fieldState }) => (
            <EmployeeRoleSelect
              required
              label={t('role')}
              value={field.value}
              error={fieldState.error?.message}
              onChange={field.onChange}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
