import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import type { BlacklistAddressFormValues } from '../types';

type AddBlacklistAddressDTO = {
  data: BlacklistAddressFormValues;
};

export const useAddBlacklistAddress = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddBlacklistAddressDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddBlacklistAddressDTO>({
    mutationFn: async variables => {
      return bazarApi.blacklist.addAddress(variables.data);
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Адрес добавлен в черный список',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['blacklist-addresses'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
