import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Supplier } from 'shared/lib/bazar-api';
import { useSuppliers } from '../hooks';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: Supplier['id'] | null;
  onChange: (value: Supplier['id']) => void;
};

export const SupplierSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-select' });

    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 300);
    const { isFetching, suppliers } = useSuppliers({ query: debouncedQuery, pageSize: 1000 });

    const data: SelectItem[] = suppliers.map(s => ({ value: s.id.toString(), label: s.name }));

    return (
      <Select
        ref={ref}
        placeholder={t('select-supplier')}
        data={data}
        value={value?.toString()}
        filter={() => true}
        nothingFound={t('nothing-found')}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(Number(newValue))}
        onSearchChange={setQuery}
        {...rest}
      />
    );
  },
);
