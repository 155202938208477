import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { EmployeeRole, bazarApi } from 'shared/lib/bazar-api';

export const useEmployees = ({
  role,
  query,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  role: EmployeeRole;
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['employees', { darkstoreId, role, query, sortBy, sortOrder, pageNumber, pageSize }],
    queryFn: async () => {
      return bazarApi.employees.getList({
        darkstoreId,
        role,
        query,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, employees: rows, totalPages };
};
