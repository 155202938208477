import { z } from 'zod';
import {
  BannerActionType,
  BannerButtonType,
  BannerSize,
  BannerTargetScreen,
} from 'shared/lib/bazar-api';

export const bannerFormSchema = z.object({
  darkstoreId: z.number().int().min(0),
  size: z.nativeEnum(BannerSize),
  actionType: z.nativeEnum(BannerActionType),
  appImage: z.string().url(),
  webImage: z.string().url(),
  targetCategory: z.number().int().min(0).nullable(),
  targetSubcategory: z.number().int().min(0).nullable(),
  targetBrand: z.number().int().min(0).nullable(),
  targetScreen: z.nativeEnum(BannerTargetScreen).nullable(),
  modal: z
    .object({
      title: z.string(),
      text: z.string(),
      image: z.string().url(),
      buttonType: z.nativeEnum(BannerButtonType),
      buttonText: z.string(),
      targetCategory: z.number().int().min(0).nullable(),
      targetSubcategory: z.number().int().min(0).nullable(),
      targetBrand: z.number().int().min(0).nullable(),
      targetScreen: z.nativeEnum(BannerTargetScreen).nullable(),
    })
    .nullable(),
  stories: z.array(
    z.object({
      title: z.string(),
      text: z.string(),
      image: z.string().url(),
      buttonType: z.nativeEnum(BannerButtonType),
      buttonText: z.string(),
      targetCategory: z.number().int().min(0).nullable(),
      targetSubcategory: z.number().int().min(0).nullable(),
      targetBrand: z.number().int().min(0).nullable(),
      targetScreen: z.nativeEnum(BannerTargetScreen).nullable(),
    }),
  ),
});
