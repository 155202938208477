import { BannerActionType, BannerSize } from 'shared/lib/bazar-api';

export const BANNER_SIZES = [
  { label: 'Маленький', value: BannerSize.Small },
  { label: 'Большой', value: BannerSize.Big },
];

export const BANNER_ACTION_TYPES_MAP = {
  [BannerActionType.Category]: 'Переход в категорию',
  [BannerActionType.Subcategory]: 'Переход в подкатегорию',
  [BannerActionType.Brand]: 'Переход к бренду',
  [BannerActionType.Screen]: 'Переход на экран (страницу сайта)',
  [BannerActionType.Modal]: 'Открытие модального окна',
  [BannerActionType.Stories]: 'Stories',
};

export const BANNER_ACTION_TYPES = [
  { label: 'Переход в категорию', value: BannerActionType.Category },
  { label: 'Переход в подкатегорию', value: BannerActionType.Subcategory },
  { label: 'Переход к бренду', value: BannerActionType.Brand },
  { label: 'Переход на экран (страницу сайта)', value: BannerActionType.Screen },
  { label: 'Открытие модального окна', value: BannerActionType.Modal },
  { label: 'Stories', value: BannerActionType.Stories },
];
