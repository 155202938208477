import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, Darkstore, Product, Subcategory } from 'shared/lib/bazar-api';

type ReorderProductsDTO = {
  data: {
    id: Product['id'];
    darkstoreId: Darkstore['id'];
    order: { subcategoryId: Subcategory['id']; value: number }[];
  }[];
};

export const useReorderProducts = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, ReorderProductsDTO>({
    mutationFn: variables => {
      return bazarApi.assortment.reorderProducts(variables.data);
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['products-with-order'] });
    },
  });

  return { isReordering: isLoading, reorder: mutate };
};
