import { useTranslation } from 'react-i18next';
import { Grid, NumberInput, Stack, TextInput } from '@mantine/core';
import { Form, FormController } from 'shared/lib/form';
import { darkstoreZoneFormSchema } from '../../schemas';
import { DarkstoreZoneFormValues } from '../../types';
import { DarkstoreZoneMap } from './darkstore-zone-map';
import { DarkstoreZonePaymentMethods } from './darkstore-zone-payment-methods';
import { DarkstoreZoneStatusSelect } from './darkstore-zone-status-select';
import { DefaultDeliveryTimeSelect } from './default-delivery-time-select';

export const DarkstoreZoneForm = ({
  id,
  disabled,
  defaultValues,
  onSubmit,
}: {
  id: string;
  disabled?: boolean;
  defaultValues: Partial<DarkstoreZoneFormValues>;
  onSubmit: (values: DarkstoreZoneFormValues) => void;
}) => {
  const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-zone-form' });

  return (
    <Form
      id={id}
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={darkstoreZoneFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        <Grid>
          <Grid.Col span={4}>
            <Stack>
              <FormController
                name="name"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    required
                    label={t('name')}
                    placeholder={t('input-name')}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <Grid>
                <Grid.Col span={6}>
                  <FormController
                    name="status"
                    render={({ field, fieldState }) => (
                      <DarkstoreZoneStatusSelect
                        {...field}
                        required
                        label={t('status')}
                        placeholder={t('select-status')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <FormController
                    name="defaultDeliveryTime"
                    render={({ field, fieldState }) => (
                      <DefaultDeliveryTimeSelect
                        {...field}
                        required
                        label={t('default-delivery-time')}
                        placeholder={t('select-default-delivery-time')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <FormController
                    name="deliveryTime"
                    render={({ field, fieldState }) => (
                      <NumberInput
                        {...field}
                        required
                        precision={0}
                        label={t('delivery-time')}
                        placeholder={t('input-delivery-time')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <FormController
                    name="deliveryCost"
                    render={({ field, fieldState }) => (
                      <NumberInput
                        {...field}
                        required
                        precision={2}
                        label={t('delivery-cost')}
                        placeholder={t('input-delivery-cost')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <FormController
                    name="minOrderSum"
                    render={({ field, fieldState }) => (
                      <NumberInput
                        {...field}
                        required
                        precision={2}
                        label={t('min-order-sum')}
                        placeholder={t('input-min-order-sum')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
              <DarkstoreZonePaymentMethods />
            </Stack>
          </Grid.Col>
          <Grid.Col span={8}>
            <FormController
              name="points"
              render={({ field }) => (
                <DarkstoreZoneMap
                  editingToolbar={!field.disabled}
                  points={field.value}
                  onPointsChange={field.onChange}
                />
              )}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </Form>
  );
};
