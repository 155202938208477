import { Checkbox, Group } from '@mantine/core';
import { CellContext } from '@tanstack/react-table';
import { User } from 'shared/lib/bazar-api';

export const PermissionsCell = (props: CellContext<User, User['acl']> & { module: string }) => {
  const acl = props.getValue() ?? {};
  const value = acl[props.module] ?? { read: false, write: false };
  return (
    <Group spacing={8}>
      <Checkbox
        styles={{ input: { cursor: 'pointer' }, label: { paddingLeft: '4px' } }}
        label="read"
        checked={value.read}
        onChange={e => {
          props.table.options.meta?.onCellEditStop?.({
            rowId: props.row.id,
            columnId: props.column.id,
            value: { write: value.write, read: e.target.checked },
          });
        }}
      />
      <Checkbox
        styles={{ input: { cursor: 'pointer' }, label: { paddingLeft: '4px' } }}
        label="write"
        checked={value.write}
        onChange={e => {
          props.table.options.meta?.onCellEditStop?.({
            rowId: props.row.id,
            columnId: props.column.id,
            value: { read: value.read, write: e.target.checked },
          });
        }}
      />
    </Group>
  );
};
