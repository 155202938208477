import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { BrandShopee as ShoppingBagIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import {
  CustomerForm,
  CustomerOrdersDataGrid,
  CustomerStatsCard,
  useCustomer,
} from 'features/customers';
import { useEditCustomer } from 'features/customers';
import { formatDate } from 'shared/utils/date';
import { formatPhone } from 'shared/utils/phone';

export const CustomerEditPage = () => {
  const { customerId } = useParams();

  const { t } = useTranslation('pages/customers');

  const hasWritePermission = usePermission('customers.write');

  const { isFetching, customer } = useCustomer({ customerId: Number(customerId) });
  const { isEditing, edit } = useEditCustomer();

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  if (!customer) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="/customers">
            {t('customers')}
          </Anchor>
          <Text>{t('customer-nof-found')}</Text>
        </Breadcrumbs>
        <Title order={2}>{t('customer-nof-found')}</Title>
      </Stack>
    );
  }

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to="/customers">
          {t('customers')}
        </Anchor>
        <Text>{customer.name}</Text>
      </Breadcrumbs>
      <Group spacing={24} align="flex-start">
        <Card shadow="sm" radius="sm" sx={{ flex: 1 }}>
          <Card.Section sx={{ padding: 24 }}>
            <Group spacing={24}>
              <Avatar size={140} radius={70} />
              <Stack spacing={8}>
                <Text>{customer.name}</Text>
                <Anchor href={`tel:${customer.phone}`}>{formatPhone(customer.phone)}</Anchor>
                <Group
                  spacing={4}
                  align="center"
                  sx={{
                    padding: '8px 20px',
                    color: '#54586C',
                    backgroundColor: '#F9FAFC',
                    borderRadius: '36px',
                  }}
                >
                  <ShoppingBagIcon size={20} />
                  <Text size={14} weight={700} sx={{ lineHeight: 1 }}>
                    {t('orders-amount')} {customer.ordersAmount}
                  </Text>
                </Group>
              </Stack>
            </Group>
          </Card.Section>
          <Card.Section sx={{ padding: 16, backgroundColor: '#F9FAFC' }}>
            <Group grow>
              <Stack
                spacing={4}
                align="center"
                justify="flex-end"
                sx={{ borderRight: '1px solid #D2D2DD' }}
              >
                <Text size={14} weight={600}>
                  {formatDate(customer.createdAt)}
                </Text>
                <Text size={10}>{t('created-at')}</Text>
              </Stack>
              <Stack
                spacing={4}
                align="center"
                justify="flex-end"
                sx={{ borderRight: '1px solid #D2D2DD' }}
              >
                <Text size={14} weight={600}>
                  {customer.firstOrderedAt ? formatDate(customer.firstOrderedAt) : '-'}
                </Text>
                <Text size={10}>{t('first-ordered-at')}</Text>
              </Stack>
              <Stack spacing={4} align="center" justify="flex-end">
                <Text size={14} weight={600}>
                  {customer.lastOrderedAt ? formatDate(customer.lastOrderedAt) : '-'}
                </Text>
                <Text size={10}>{t('last-ordered-at')}</Text>
              </Stack>
            </Group>
          </Card.Section>
          <Card.Section sx={{ padding: 24 }}>
            <CustomerForm
              id="customer-form"
              defaultValues={customer}
              onSubmit={data => edit({ customerId: customer.id, data })}
            />
          </Card.Section>
          <Card.Section sx={{ padding: '16px 24px', borderTop: '1px solid #D2D2DB' }}>
            <Button
              type="submit"
              form="customer-form"
              disabled={!hasWritePermission}
              loading={isEditing}
              sx={{ display: 'block', marginLeft: 'auto' }}
            >
              {t('save')}
            </Button>
          </Card.Section>
        </Card>
        <Stack spacing={24} sx={{ flex: 2 }}>
          <Card shadow="sm" radius="sm" sx={{ backgroundColor: '#F9FAFC' }}>
            <SimpleGrid cols={4}>
              <CustomerStatsCard label={t('orders-sum')} unit="byn" value={customer.ordersSum} />
              <CustomerStatsCard
                label={t('average-order-sum')}
                unit="byn"
                value={customer.averageOrderSum ?? 0}
              />
              <CustomerStatsCard
                label={t('average-order-size')}
                unit="шт"
                value={customer.averageOrderSize ?? 0}
              />
              <CustomerStatsCard
                label={t('invited-customers-amount')}
                unit="чел"
                value={customer.invitedCustomersAmount}
              />
              <CustomerStatsCard
                label={t('bonuses-received-sum')}
                unit="byn"
                value={customer.bonusesReceivedSum}
              />
              <CustomerStatsCard
                label={t('bonuses-spent-sum')}
                unit="byn"
                value={customer.bonusesSpentSum}
              />
              <CustomerStatsCard
                label={t('last-month-orders-amount')}
                unit="шт"
                value={customer.lastMonthOrdersAmount}
              />
              <CustomerStatsCard
                label={t('last-week-orders-amount')}
                unit="шт"
                value={customer.lastWeekOrdersAmount}
              />
            </SimpleGrid>
          </Card>
          <Card shadow="sm" radius="sm">
            <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
              <Title order={2}>{t('orders')}</Title>
            </Card.Section>
            <Card.Section sx={{ padding: 16 }}>
              <CustomerOrdersDataGrid data={customer.orders} />
            </Card.Section>
          </Card>
        </Stack>
      </Group>
    </Stack>
  );
};
