import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Select, Stack } from '@mantine/core';
import { ProductAutocomplete } from 'features/products';
import { Form, FormController } from 'shared/lib/form';
import { StopListReasonSelect } from '.';
import { STOP_LIST_INTERVALS } from '../constants';

export const getCurrentHour = () => {
  const now = new Date();
  return now.getHours();
};

export const generateTimeOptions = (allHours = false) => {
  const currentHour = getCurrentHour();
  const times = [];

  const startHour = allHours ? 0 : currentHour + 1;
  const endHour = 24;

  for (let hour = startHour; hour < endHour; hour++) {
    const timeLabel = `${hour.toString().padStart(2, '0')}:00`;
    times.push({
      label: timeLabel,
      value: timeLabel,
    });
  }

  return times;
};

export const StopListItemForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: any;
  onSubmit: (values: any) => void;
}) => {
  const { t } = useTranslation('features/stop-list', { keyPrefix: 'stop-list-form' });

  const [interval, setInterval] = useState(defaultValues?.interval || '');
  const [time, setTime] = useState(defaultValues?.time || '');
  const [productId, setProductId] = useState(defaultValues?.productId || '');
  const [productName, setProductName] = useState(defaultValues?.productName || '');

  const intervalOptions = STOP_LIST_INTERVALS.map(interval => ({
    value: interval,
    label: t(`intervals.${interval}`),
  }));

  const timeOptions = generateTimeOptions(false);

  useEffect(() => {
    if (interval) setTime('');
  }, [interval]);

  useEffect(() => {
    if (time) setInterval('');
  }, [time]);

  const handleSubmit = (data: any) => {
    const updatedData = {
      ...data,
      interval,
      time,
      productId,
      productName,
    };
    onSubmit(updatedData);
  };

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit}>
      <Stack align="stretch">
        <FormController
          name="productId"
          render={({ field, fieldState }) => (
            <ProductAutocomplete
              {...field}
              required
              label={t('input-product')}
              error={fieldState.error?.message}
              value={productName}
              onChange={item => {
                console.log(item);
                setProductId(item.id);
                setProductName(item.name);
                field.onChange(item.id);
              }}
            />
          )}
        />
        <FormController
          name="reason"
          render={({ field, fieldState }) => (
            <StopListReasonSelect
              required
              label={t('input-reason')}
              value={field.value}
              error={fieldState.error?.message}
              onChange={field.onChange}
            />
          )}
        />
        <Grid>
          <Grid.Col>
            <FormController
              name="interval"
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  required
                  data={intervalOptions}
                  label={t('input-interval')}
                  placeholder={t('input-interval')}
                  error={fieldState.error?.message}
                  value={interval}
                  onChange={value => setInterval(value as string)}
                />
              )}
            />
            <FormController
              name="time"
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  required
                  data={timeOptions}
                  label={t('input-time')}
                  placeholder={t('input-time')}
                  error={fieldState.error?.message}
                  value={time}
                  onChange={value => setTime(value as string)}
                />
              )}
            />
          </Grid.Col>
        </Grid>

        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
