import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi } from 'shared/lib/bazar-api';

export const useCustomers = ({
  query,
  sortBy,
  sortOrder,
  pageNumber,
  pageSize,
}: {
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data } = useQuery({
    queryKey: ['customers', { partnerId, query, sortBy, sortOrder, pageNumber, pageSize }],
    queryFn: async () => {
      return bazarApi.customers.getList({
        partnerId,
        query,
        sortBy,
        sortOrder,
        pageNumber,
        pageSize,
      });
    },
  });

  const { rows, totalPages } = data ?? { rows: [], totalPages: 0 };

  return { isFetching, customers: rows, totalPages };
};
