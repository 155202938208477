import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Anchor, Breadcrumbs, Button, Card, Stack, Text, Title } from '@mantine/core';
import { usePermission } from 'features/auth';
import { useSettings } from 'features/settings';
import { SupplierForm, useAddSupplier } from 'features/suppliers';

export const SupplierAddPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('pages/suppliers');

  const hasWritePermission = usePermission('suppliers.write');
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isAdding, add } = useAddSupplier({
    onSuccess: data => navigate(`../${data.id}`, { replace: true }),
  });

  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to="/suppliers">
          {t('suppliers')}
        </Anchor>
        <Text> {t('add-supplier')}</Text>
      </Breadcrumbs>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{t('add-supplier')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplierForm
            id="supplier-form"
            disabled={!hasWritePermission}
            defaultValues={{
              darkstoreId,
              name: '',
              upn: '',
              contractNumber: '',
              contractUrl: null,
              contacts: [{ name: '', phone: '', birthday: null, email: '', comment: '' }],
              requestDays: [],
              paymentDelay: 0,
              paymentTermComment: '',
              comment: '',
              email: '',
              requisites: '',
            }}
            onSubmit={data => add({ data })}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="supplier-form"
            disabled={!hasWritePermission}
            loading={isAdding}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>
    </Stack>
  );
};
