import { useMutation } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';

export const useSignIn = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: { email: string; password: string }) => void;
}) => {
  const { isLoading, mutate } = useMutation<unknown, Error, { email: string; password: string }>({
    mutationFn: variables => bazarApi.auth.signIn(variables),
    onSuccess,
  });

  return { isSigningIn: isLoading, signIn: mutate };
};
