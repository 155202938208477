import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, DarkstoreZone } from 'shared/lib/bazar-api';

export const useDarkstoreZoneDeliveryCost = ({ zoneId }: { zoneId?: DarkstoreZone['id'] }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['darkstore-zones-delivery-cost', { zoneId, darkstoreId }],
    queryFn: () => {
      if (!zoneId) {
        return null;
      }
      return bazarApi.darkstoreZones.getDeliveryCost({ id: zoneId, darkstoreId });
    },
    enabled: !!zoneId,
    suspense: true,
  });

  return { isFetchingDeliveryCost: isFetching, deliveryCost: data };
};
