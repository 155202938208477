import { useTranslation } from 'react-i18next';
import { NumberInput } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { ProductFormValues } from 'features/products/types';
import { useSettings } from 'features/settings';
import { Suspense } from 'shared/components';
import { useFormController } from 'shared/lib/form';
import { ProductAvailabilityModal } from '../product-availability-modal';

export const ProductAvailability = () => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  const { darkstoreId } = useSettings();

  const modals = useModals();

  const { field, fieldState } = useFormController<ProductFormValues, 'availability'>({
    name: 'availability',
  });

  const availability = field.value.find(a => a.darkstoreId === darkstoreId);

  return (
    <NumberInput
      {...field}
      hideControls
      precision={2}
      label={t('amount')}
      placeholder={t('input-amount')}
      value={availability ? availability.amount : 0}
      error={fieldState.error?.message}
      onFocus={e => {
        e.target.blur();
        modals.openModal({
          title: t('amount'),
          children: (
            <Suspense>
              <ProductAvailabilityModal data={field.value} />
            </Suspense>
          ),
        });
      }}
    />
  );
};
