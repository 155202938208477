import { useTranslation } from 'react-i18next';
import { Button, Group, Radio, Stack, TextInput } from '@mantine/core';
import { Form, FormController } from 'shared/lib/form';
import { BLACKLIST_ADDRESS_TYPES } from '../../constants';
import { blacklistAddressFormSchema } from '../../schemas';
import { BlacklistAddressFormValues } from '../../types';
import { BlacklistAddressFormAddress } from './blacklist-address-form-address';

export const BlacklistAddressForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<BlacklistAddressFormValues>;
  onSubmit: (values: BlacklistAddressFormValues) => void;
}) => {
  const { t } = useTranslation('features/blacklist', { keyPrefix: 'blacklist-address-form' });

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={blacklistAddressFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        <FormController
          name="type"
          render={({ field, fieldState }) => (
            <Radio.Group
              {...field}
              withAsterisk
              label={t('type')}
              error={fieldState.error?.message}
            >
              {BLACKLIST_ADDRESS_TYPES.map(a => (
                <Radio key={a.value} value={a.value} label={a.label} />
              ))}
            </Radio.Group>
          )}
        />
        <BlacklistAddressFormAddress />
        <Group noWrap>
          <FormController
            name="building"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label={t('building')}
                placeholder={t('input-building')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="flat"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label={t('flat')}
                placeholder={t('input-flat')}
                error={fieldState.error?.message}
              />
            )}
          />
        </Group>
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
