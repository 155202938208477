import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, DarkstoreZone } from 'shared/lib/bazar-api';
import type { DarkstoreZoneFormValues } from '../types';

type EditDarkstoreZoneDeliveryCostDTO = {
  zoneId: DarkstoreZone['id'];
  data: DarkstoreZoneFormValues;
};

export const useEditDarkstoreZoneDeliveryCost = () => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, EditDarkstoreZoneDeliveryCostDTO>({
    mutationFn: variables => {
      return bazarApi.darkstoreZones.editDeliveryCost({
        darkstoreId,
        variables,
      });
    },
    onSuccess: () => {
      showNotification({
        title: 'Успех',
        message: 'Изменения сохранены',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['darkstore-zones-delivery-cost'] });
    },
  });

  return { isEditingDeliveryCost: isLoading, editDeliveryCost: mutate };
};
