import { useTranslation } from 'react-i18next';
import { Button, Group } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { z } from 'zod';
import { DarkstoreMultiSelect } from 'features/darkstores';
import { SuppliersMultiSelect } from 'features/suppliers';
import { Form, FormController } from 'shared/lib/form';

const validationSchema = z.object({
  darkstoresIds: z.array(z.number().int().min(0)).optional(),
  suppliersIds: z.array(z.number().int().min(0)).optional(),
  createdAt: z.date().nullish(),
  expectedAt: z.date().nullish(),
});

type FiltersFormValues = z.infer<typeof validationSchema>;

export const SuppliesFiltersForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FiltersFormValues>;
  onSubmit: (values: FiltersFormValues) => void;
}) => {
  const { t } = useTranslation('features/supplies', { keyPrefix: 'supplies-filters-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Group position="apart" align="flex-end">
        <Group>
          <FormController
            name="darkstoresIds"
            render={({ field, fieldState }) => (
              <DarkstoreMultiSelect
                label={t('darkstore')}
                value={field.value}
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
          <FormController
            name="suppliersIds"
            render={({ field, fieldState }) => (
              <SuppliersMultiSelect
                label={t('supplier')}
                value={field.value}
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
          <FormController
            name="createdAt"
            render={({ field, fieldState }) => (
              <DatePicker
                label={t('application-date')}
                placeholder={t('select-date')}
                value={field.value ?? undefined}
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
          <FormController
            name="expectedAt"
            render={({ field, fieldState }) => (
              <DatePicker
                label={t('supply-date')}
                placeholder={t('select-date')}
                value={field.value ?? undefined}
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Group>
        <Button type="submit">{t('apply-filters')}</Button>
      </Group>
    </Form>
  );
};
