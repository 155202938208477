import { bazarApiClient } from '../client';
import { Darkstore, StopList } from '../types';

const getList = async ({ darkstoreId }: { darkstoreId: Darkstore['id'] }): Promise<any> => {
  const res = await bazarApiClient.get('admin/stop-list', {
    searchParams: {
      darkstoreId,
    },
  });
  const data = await res.json<{ rows: any[] }>();
  return {
    rows: data,
  };
};

const remove = async ({ id }: Pick<StopList, 'id'>) => {
  const res = await bazarApiClient.delete(`admin/stop-list/${id}`);
  const data = await res.json();
  return data;
};

const add = async (variables: Omit<StopList, 'id'> & { darkstoreId: Darkstore['id'] }) => {
  const body = JSON.stringify({
    ...variables,
    productId: Number(variables.productId),
  });
  const res = await bazarApiClient.post('admin/stop-list', { body });
  const data = await res.json();
  return data;
};

export const stopList = { getList, add, remove };
