import { useTranslation } from 'react-i18next';
import { Loader, Select, Sx } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { Darkstore, Supplier } from 'shared/lib/bazar-api';
import { useSupplierProducts } from '../hooks';

export const SupplierProductSelect = ({
  className,
  sx,
  darkstoreId,
  supplierId,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  darkstoreId?: Darkstore['id'];
  supplierId?: Supplier['id'];
  required?: boolean;
  label?: string;
  value?: number | null;
  error?: string;
  onChange: (value: number) => void;
}) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-product-select' });

  const { isFetching, products } = useSupplierProducts({ darkstoreId, supplierId });

  const data: SelectItem[] = products.map(product => ({
    value: product.id.toString(),
    label: product.name,
  }));

  return (
    <Select
      className={className}
      sx={sx}
      searchable
      required={required}
      label={label}
      placeholder={t('select-product')}
      data={data}
      value={value?.toString()}
      error={error}
      nothingFound={t('nothing-found')}
      rightSection={isFetching && <Loader size="xs" />}
      onChange={newValue => onChange(Number(newValue))}
    />
  );
};
