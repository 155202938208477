import { useTranslation } from 'react-i18next';
import { NumberInput } from '@mantine/core';
import { closeModal, useModals } from '@mantine/modals';
import { ProductFormValues } from 'features/products/types';
import { useSettings } from 'features/settings';
import { Suspense } from 'shared/components';
import { useFormController } from 'shared/lib/form';
import { ProductDiscountsModal } from '../product-discounts-modal';

export const ProductDiscount = () => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-form' });

  const { darkstoreId } = useSettings();

  const modals = useModals();

  const { field, fieldState } = useFormController<ProductFormValues, 'discounts'>({
    name: 'discounts',
  });

  const discount = field.value.find(a => a.darkstoreId === darkstoreId);

  return (
    <NumberInput
      {...field}
      hideControls
      precision={0}
      label={t('discount')}
      placeholder={t('input-discount')}
      value={discount ? discount.size : 0}
      error={fieldState.error?.message}
      onFocus={e => {
        e.target.blur();
        const modalId = modals.openModal({
          title: t('discount'),
          size: 'xl',
          children: (
            <Suspense>
              <ProductDiscountsModal
                hasWritePermissions={!field.disabled}
                data={field.value}
                onChange={discounts => {
                  field.onChange(discounts);
                  closeModal(modalId);
                }}
              />
            </Suspense>
          ),
        });
      }}
    />
  );
};
