export const AnalyticsIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.969 14.906c.472 0 .9-.18 1.216-.47l1.735.825c-.009.067-.022.133-.022.202 0 .92.789 1.67 1.758 1.67.97 0 1.758-.75 1.758-1.67 0-.257-.066-.498-.176-.715l2.353-2.236c.229.104.482.168.753.168.969 0 1.758-.75 1.758-1.67a1.57 1.57 0 0 0-.088-.495l2.044-1.456c.28.177.613.28.973.28.97 0 1.758-.748 1.758-1.67C24.79 6.75 24 6 23.031 6s-1.758.75-1.758 1.67c0 .173.036.337.088.495L19.317 9.62a1.811 1.811 0 0 0-.973-.281c-.97 0-1.758.749-1.758 1.67 0 .257.067.497.176.715L14.41 13.96a1.798 1.798 0 0 0-1.97.302l-1.734-.823c.009-.067.022-.133.022-.203 0-.92-.789-1.67-1.758-1.67-.97 0-1.758.75-1.758 1.67 0 .921.789 1.67 1.758 1.67Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.414 23.887h-.625V12.123c0-.308-.262-.557-.586-.557H21.86c-.323 0-.586.25-.586.557v11.764h-1.171v-8.424c0-.308-.263-.557-.586-.557h-2.344c-.324 0-.586.25-.586.557v8.424h-1.172v-3.971c0-.308-.262-.557-.586-.557h-2.344c-.323 0-.586.25-.586.557v3.97h-1.171V17.69c0-.307-.263-.556-.586-.556H7.797c-.324 0-.586.249-.586.556v6.198h-.625c-.324 0-.586.249-.586.556 0 .308.262.557.586.557h18.828c.324 0 .586-.249.586-.557 0-.307-.262-.556-.586-.556Z"
      />
    </svg>
  );
};
