import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Button,
  Checkbox,
  Grid,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { Trash as TrashIcon, Copy as CopyIcon } from 'tabler-icons-react';
import { z } from 'zod';
import { useDarkstores } from 'features/darkstores';
import { useSettings } from 'features/settings';
import { Product, ProductDiscountType } from 'shared/lib/bazar-api';
import { Form, FormController, FormWatch } from 'shared/lib/form';

const validationSchema = z.object({
  discounts: z.array(
    z.object({
      darkstoreId: z.number().int().min(0),
      type: z.nativeEnum(ProductDiscountType),
      from: z.date().nullable(),
      to: z.date().nullable(),
      date: z.date().nullable(),
      size: z.number().min(0),
      price: z.number().min(0),
    }),
  ),
});

export const ProductDiscountsModal = ({
  hasWritePermissions,
  data,
  onChange,
}: {
  hasWritePermissions?: boolean;
  data: Product['discounts'];
  onChange: (data: Product['discounts']) => void;
}) => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-discounts-modal' });

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { darkstores } = useDarkstores({ refetchOnMount: false });

  const [forAll, setForAll] = useState(false);

  const defaultValues = {
    discounts: darkstores.map(darkstore => {
      const discount = data.find(item => item.darkstoreId === darkstore.id);
      return (
        discount ?? {
          darkstoreId: darkstore.id,
          type: ProductDiscountType.Default,
          from: null,
          to: null,
          date: null,
          size: 0,
          price: 0,
        }
      );
    }),
  };

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={values => onChange(values.discounts)}
    >
      {form => (
        <Stack>
          {darkstores.map(darkstore => {
            const index = defaultValues.discounts.findIndex(d => d.darkstoreId === darkstore.id);
            const disabled = !hasWritePermissions || (forAll && darkstore.id !== darkstoreId);
            if (index !== -1) {
              return (
                <Grid key={darkstore.id} align="center">
                  <Grid.Col span={3}>
                    <Text size="sm">{darkstore.name}</Text>
                  </Grid.Col>
                  <Grid.Col span="auto">
                    <FormController
                      name={`discounts.${index}.type`}
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          disabled={disabled}
                          sx={{ minWidth: '150px' }}
                          data={[
                            { label: 'Обычная', value: ProductDiscountType.Default },
                            { label: 'По дату', value: ProductDiscountType.ByDate },
                            { label: 'Ежедневная', value: ProductDiscountType.Daily },
                          ]}
                          error={fieldState.error?.message}
                          onChange={type => {
                            if (forAll) {
                              const values = form.getValues();
                              form.setValue(
                                'discounts',
                                values.discounts.map(discount => ({
                                  ...discount,
                                  type,
                                })) as Product['discounts'],
                              );
                            } else {
                              field.onChange(type);
                            }
                          }}
                        />
                      )}
                    />
                  </Grid.Col>
                  <FormWatch
                    name={`discounts.${index}.type`}
                    render={type => {
                      switch (type) {
                        case ProductDiscountType.ByDate:
                          return (
                            <FormController
                              name={`discounts.${index}.date`}
                              render={({ field, fieldState }) => (
                                <DatePicker
                                  {...field}
                                  disabled={disabled}
                                  placeholder={t('input-date')}
                                  error={fieldState.error?.message}
                                  onChange={date => {
                                    if (forAll) {
                                      const values = form.getValues();
                                      form.setValue(
                                        'discounts',
                                        values.discounts.map(discount => ({
                                          ...discount,
                                          date,
                                        })) as Product['discounts'],
                                      );
                                    } else {
                                      field.onChange(date);
                                    }
                                  }}
                                />
                              )}
                            />
                          );
                        case ProductDiscountType.Daily:
                          return (
                            <>
                              <Grid.Col span="auto">
                                <FormController
                                  name={`discounts.${index}.from`}
                                  render={({ field, fieldState }) => (
                                    <TimeInput
                                      {...field}
                                      disabled={disabled}
                                      placeholder={t('input-time')}
                                      error={fieldState.error?.message}
                                      onChange={from => {
                                        if (forAll) {
                                          const values = form.getValues();
                                          form.setValue(
                                            'discounts',
                                            values.discounts.map(discount => ({
                                              ...discount,
                                              from,
                                            })) as Product['discounts'],
                                          );
                                        } else {
                                          field.onChange(from);
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span="auto">
                                <FormController
                                  name={`discounts.${index}.to`}
                                  render={({ field, fieldState }) => (
                                    <TimeInput
                                      {...field}
                                      disabled={disabled}
                                      placeholder={t('input-time')}
                                      error={fieldState.error?.message}
                                      onChange={to => {
                                        if (forAll) {
                                          const values = form.getValues();
                                          form.setValue(
                                            'discounts',
                                            values.discounts.map(discount => ({
                                              ...discount,
                                              to,
                                            })) as Product['discounts'],
                                          );
                                        } else {
                                          field.onChange(to);
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </Grid.Col>
                            </>
                          );
                        default:
                          return null;
                      }
                    }}
                  />
                  <Grid.Col span="auto">
                    <FormController
                      name={`discounts.${index}.size`}
                      render={({ field, fieldState }) => (
                        <NumberInput
                          {...field}
                          disabled={disabled}
                          precision={0}
                          icon="%"
                          placeholder={t('input-discount')}
                          error={fieldState.error?.message}
                          onChange={size => {
                            if (forAll) {
                              const values = form.getValues();
                              form.setValue(
                                'discounts',
                                values.discounts.map(discount => ({
                                  ...discount,
                                  size,
                                })) as Product['discounts'],
                              );
                            } else {
                              field.onChange(size);
                            }
                          }}
                        />
                      )}
                    />
                  </Grid.Col>
                  {!disabled && !forAll && (
                    <Grid.Col span="content">
                      <Group noWrap spacing={4} position="right">
                        <ActionIcon>
                          <CopyIcon
                            size={14}
                            onClick={() => {
                              const values = form.getValues();
                              const copy = values.discounts.find(
                                d => d.darkstoreId === darkstoreId,
                              );
                              if (copy) {
                                form.setValue(`discounts.${index}`, {
                                  ...copy,
                                  darkstoreId: values.discounts[index].darkstoreId,
                                });
                              }
                            }}
                          />
                        </ActionIcon>
                        <ActionIcon
                          onClick={() => {
                            const values = form.getValues();
                            form.setValue(`discounts.${index}`, {
                              ...values.discounts[index],
                              from: null,
                              to: null,
                              date: null,
                              size: 0,
                              price: 0,
                            });
                          }}
                        >
                          <TrashIcon size={14} />
                        </ActionIcon>
                      </Group>
                    </Grid.Col>
                  )}
                </Grid>
              );
            }
            return null;
          })}
          {hasWritePermissions && (
            <>
              <Checkbox
                label={t('for-all')}
                checked={forAll}
                onChange={e => setForAll(e.target.checked)}
              />
              <Button type="submit" sx={{ display: 'block', marginLeft: 'auto' }}>
                {t('save')}
              </Button>
            </>
          )}
        </Stack>
      )}
    </Form>
  );
};
