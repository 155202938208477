import { z } from 'zod';
import { ProductDiscountType } from 'shared/lib/bazar-api';

export const productFormSchema = z.object({
  partnerId: z.number().int().min(0),
  darkstoreId: z.number().int().min(0),
  categories: z.array(
    z.object({
      id: z.number().int().min(0),
      subcategories: z.array(z.object({ id: z.number().int().min(0) })),
    }),
  ),
  availability: z.array(
    z.object({
      darkstoreId: z.number().int().min(0),
      amount: z.number().min(0),
    }),
  ),
  discounts: z.array(
    z.object({
      darkstoreId: z.number().int().min(0),
      type: z.nativeEnum(ProductDiscountType),
      from: z.date().nullable(),
      to: z.date().nullable(),
      date: z.date().nullable(),
      size: z.number().min(0),
      price: z.number().min(0),
    }),
  ),
  suppliers: z.array(
    z.object({
      supplierId: z.number().int().min(0),
      supplierPrice: z.number().min(0),
      minRequest: z.number().int().min(0),
      vat: z.number().int().min(0),
      extraCharge: z.number().min(0),
    }),
  ),
  enabled: z.boolean(),
  liquidating: z.boolean(),
  splitting: z.boolean(),
  weighted: z.boolean(),
  image: z.string().url(),
  name: z.string().min(1),
  country: z.string(),
  price: z.number().positive(),
  liquid: z.boolean(),
  weight: z.number().positive(),
  pricePerMeasure: z.number().positive(),
  description: z.string(),
  barcode: z.string(),
  sku: z.string(),
  importer: z.string(),
  manufacturer: z.string(),
  brand: z.string(),
  storageConditions: z.string(),
  content: z.string(),
  minAmount: z.number().int().min(0),
  shortTerm: z.number().int().min(0),
  protein: z.number().min(0),
  fat: z.number().min(0),
  carbs: z.number().min(0),
  kcal: z.number().min(0),
  kj: z.number().min(0),
});
