import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { ConfirmModal } from 'shared/components';
import { Category } from 'shared/lib/bazar-api';
import { useRemoveCategory } from '../hooks';

export const CategoryRemoveModal = ({
  category,
  onClose,
}: {
  category: Category;
  onClose: () => void;
}) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'category-remove-modal' });

  const { isRemoving, remove } = useRemoveCategory({ onSuccess: onClose });

  return (
    <ConfirmModal
      labels={{ confirm: t('remove-category'), cancel: t('dont-remove-category') }}
      confirmProps={{ loading: isRemoving, color: 'red' }}
      onCancel={onClose}
      onConfirm={() => remove({ categoryId: category.id })}
    >
      <Text size="sm">{t('category-remove-confirmation')}</Text>
    </ConfirmModal>
  );
};
