import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import type { SubcategoryFormValues } from '../types';

type AddSubcategoryDTO = {
  data: SubcategoryFormValues;
};

export const useAddSubcategory = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddSubcategoryDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddSubcategoryDTO>({
    mutationFn: variables => bazarApi.assortment.addSubcategory(variables.data),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Подкатегория добавлена',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
