import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Group, Menu, Stack } from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  List as ListIcon,
  Plus as PlusIcon,
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import {
  CategoriesMenu,
  CategoryAddModal,
  CategoryEditModal,
  CategoryRemoveModal,
  SubcategoriesList,
  useCategory,
} from 'features/categories';
import { ProductsDataGrid, ProductsList } from 'features/products';
import { SearchInput, Suspense } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const ProductsPage = () => {
  const [search, setSearch] = useSearch<{ categoryId?: number }>({ categoryId: undefined });

  const { t } = useTranslation('pages/products');
  const modals = useModals();

  const hasWritePermission = usePermission('products.write');

  const { category } = useCategory({ categoryId: search.categoryId });

  if (!category) {
    return (
      <Stack spacing={0} sx={{ flex: 1, margin: '-24px' }}>
        <Group sx={{ padding: '16px 24px', borderBottom: '1px solid #D2D2DB' }}>
          <SearchInput sx={{ flexGrow: 1 }} onChange={e => console.log(e)} />
          <Button
            disabled={!hasWritePermission}
            leftIcon={<PlusIcon size={14} />}
            onClick={() => {
              const modalId = modals.openModal({
                title: t('add-category'),
                children: (
                  <Suspense>
                    <CategoryAddModal onAdd={() => modals.closeModal(modalId)} />
                  </Suspense>
                ),
              });
            }}
          >
            {t('add-category')}
          </Button>
        </Group>
        <Stack spacing={16} sx={{ flex: 1, padding: '16px 24px' }}>
          <Group>
            <CategoriesMenu
              value={category}
              onChange={newCategory => setSearch({ categoryId: newCategory.id })}
            />
          </Group>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={0} sx={{ flex: 1, margin: '-24px' }}>
      <Group sx={{ padding: '16px 24px', borderBottom: '1px solid #D2D2DB' }}>
        <SearchInput sx={{ flexGrow: 1 }} onChange={e => console.log(e)} />
        <Group>
          <Menu position="bottom-end">
            <Menu.Target>
              <Button
                disabled={!hasWritePermission}
                variant="outline"
                leftIcon={<DotsVerticalIcon size={14} />}
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
              >
                {t('category')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component="button"
                icon={<PlusIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('add-category'),
                    children: (
                      <Suspense>
                        <CategoryAddModal onAdd={() => modals.closeModal(modalId)} />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('add-category')}
              </Menu.Item>
              <Menu.Item
                component="button"
                icon={<PencilIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('edit-category'),
                    children: (
                      <Suspense>
                        <CategoryEditModal
                          category={category}
                          onEdit={() => modals.closeModal(modalId)}
                        />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('edit-category')}
              </Menu.Item>
              <Menu.Item
                component="button"
                icon={<TrashIcon size={14} />}
                onClick={() => {
                  const modalId = modals.openModal({
                    title: t('remove-category'),
                    children: (
                      <Suspense>
                        <CategoryRemoveModal
                          category={category}
                          onClose={() => modals.closeModal(modalId)}
                        />
                      </Suspense>
                    ),
                  });
                }}
              >
                {t('remove-category')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Button
            disabled={!hasWritePermission}
            variant="outline"
            leftIcon={<ListIcon size={14} />}
            onClick={() => {
              modals.openModal({
                title: t('subcategories'),
                children: (
                  <Suspense>
                    <SubcategoriesList categoryId={category.id} />
                  </Suspense>
                ),
              });
            }}
          >
            {t('subcategories')}
          </Button>
          <Button
            disabled={!hasWritePermission}
            component={Link}
            to={`add?categoryId=${category.id}`}
            leftIcon={<PlusIcon size={14} />}
          >
            {t('add-product')}
          </Button>
        </Group>
      </Group>
      <Stack spacing={16} sx={{ flex: 1, padding: '16px 24px' }}>
        <Group position="apart">
          <CategoriesMenu
            value={category}
            onChange={newCategory => setSearch({ categoryId: newCategory.id })}
          />
          <Button
            disabled={!hasWritePermission}
            variant="outline"
            leftIcon={<ListIcon size={14} />}
            onClick={() => {
              modals.openModal({
                title: t('products-order'),
                children: (
                  <Suspense>
                    <ProductsList categoryId={category.id} />
                  </Suspense>
                ),
              });
            }}
          >
            {t('products-order')}
          </Button>
        </Group>
        <Suspense>
          <ProductsDataGrid key={category.id} categoryId={category.id} />
        </Suspense>
      </Stack>
    </Stack>
  );
};
