import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, MultiSelect, MultiSelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { Darkstore } from 'shared/lib/bazar-api';
import { useDarkstores } from '../hooks';

type Props = Omit<MultiSelectProps, 'data' | 'value' | 'onChange'> & {
  value?: Darkstore['id'][];
  onChange: (value: Darkstore['id'][]) => void;
};

export const DarkstoreMultiSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstores-multi-select' });

    const { isFetching, darkstores } = useDarkstores();

    const data: SelectItem[] = darkstores.map(darkstore => ({
      value: darkstore.id.toString(),
      label: darkstore.name,
    }));

    return (
      <MultiSelect
        ref={ref}
        placeholder={t('select-darkstore')}
        data={data}
        value={value?.map(v => v.toString())}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(newValue.map(v => Number(v)))}
        {...rest}
      />
    );
  },
);
