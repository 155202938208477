import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Banner, bazarApi } from 'shared/lib/bazar-api';

type RemoveBannerDTO = {
  bannerId: Banner['id'];
};

export const useRemoveBanner = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: RemoveBannerDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, RemoveBannerDTO>({
    mutationFn: variables => bazarApi.banners.remove({ id: variables.bannerId }),
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Баннер удален',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['banners'] });
      onSuccess(data, variables);
    },
  });

  return { isRemoving: isLoading, remove: mutate };
};
