import { Order } from 'shared/lib/bazar-api';
import { useAddOrderProduct } from '../hooks';
import { OrderProductForm } from './order-product-form';

export const OrderProductAddModal = ({
  orderId,
  onClose,
}: {
  orderId: Order['id'];
  onClose: () => void;
}) => {
  const { isAdding, add } = useAddOrderProduct({ onSuccess: onClose });

  return (
    <OrderProductForm
      mode="add"
      isSubmitting={isAdding}
      defaultValues={{ productId: undefined, productName: undefined, amount: 1 }}
      onSubmit={data => add({ orderId, data })}
    />
  );
};
