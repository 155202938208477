import { FormEvent, useRef } from 'react';
import { Box, Input } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { CellContext, RowData } from '@tanstack/react-table';

export const EditableCell = <TData extends RowData, TValue>(props: CellContext<TData, TValue>) => {
  const [state, setState] = useSetState({ editing: false, value: '' });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const value = props.getValue() as string;

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setState({ value: e.currentTarget.value });
  };

  const handleBlur = () => {
    setState({ editing: false, value: '' });
    props.table.options.meta?.onCellEditStop?.({
      rowId: props.row.id,
      columnId: props.column.id,
      value: state.value,
    });
  };

  if (!state.editing) {
    return (
      <Box
        sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
        onClick={() => {
          setState({ editing: true, value });
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }, 0);
        }}
      >
        {value}
      </Box>
    );
  }

  return (
    <Input
      ref={inputRef}
      size="xs"
      value={state.value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
