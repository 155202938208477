import { bazarApiClient } from '../client';
import { Darkstore } from '../types';

const get = async ({ darkstoresIds }: { darkstoresIds: Darkstore['id'][] }) => {
  const res = await bazarApiClient.get('admin/reports/today-orders', {
    searchParams: {
      darkstores: darkstoresIds.join(','),
    },
  });
  const data = await res.json<any[]>();
  return data;
};

export const monitoring = { get };
