import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Group, Stack, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { List as ListIcon, Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { CustomerAddModal, CustomersDataGrid } from 'features/customers';
import { SearchInput, Suspense } from 'shared/components';
import { useSearch } from 'shared/lib/search';

export const CustomersPage = () => {
  const [search, setSearch] = useSearch({ query: '' });

  const { t } = useTranslation('pages/customers');
  const modals = useModals();

  const hasWritePermission = usePermission('customers.write');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('customers')}</Title>
      <Group>
        <SearchInput
          sx={{ flex: 1 }}
          value={search.query}
          onChange={query => setSearch({ query })}
        />
        <Button variant="outline" component={Link} to="blacklist" leftIcon={<ListIcon size={14} />}>
          {t('blacklist')}
        </Button>
        <Button
          disabled={!hasWritePermission}
          leftIcon={<PlusIcon size={14} />}
          onClick={() => {
            const modalId = modals.openModal({
              title: t('add-customer'),
              children: (
                <Suspense>
                  <CustomerAddModal onClose={() => modals.closeModal(modalId)} />
                </Suspense>
              ),
            });
          }}
        >
          {t('add-customer')}
        </Button>
      </Group>
      <CustomersDataGrid query={search.query} />
    </Stack>
  );
};
