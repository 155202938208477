import { z } from 'zod';
import { PaymentTermType, SupplierType } from 'shared/lib/bazar-api';
import { validatePhone } from 'shared/utils/phone';

export const supplierFormSchema = z.object({
  darkstoreId: z.number().int().min(0),
  type: z.nativeEnum(SupplierType),
  paymentTermType: z.nativeEnum(PaymentTermType),
  name: z.string().min(1),
  upn: z.string().min(1),
  contractNumber: z.string().min(1),
  contractUrl: z.string().url().nullable(),
  contacts: z.array(
    z.object({
      name: z.string().min(1),
      phone: z.string().refine(validatePhone),
      birthday: z.date().nullable(),
      email: z.union([z.string().email(), z.string().length(0)]),
      comment: z.string(),
    }),
  ),
  requestDays: z.array(z.number()),
  paymentDelay: z.number(),
  paymentTermComment: z.string(),
  comment: z.string(),
  email: z.union([z.string().email(), z.string().length(0)]),
  requisites: z.string(),
});
