import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@mantine/core';
import { REPORT_TYPES } from '../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: string | null;
  onChange: (value: string) => void;
};

export const ReportTypeSelect = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { t } = useTranslation('features/reports', { keyPrefix: 'report-type-select' });

  return <Select {...ref} placeholder={t('select-report-type')} data={REPORT_TYPES} {...props} />;
});
