import { useTranslation } from 'react-i18next';
import { Group, Input, Stack, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { DarkstoreFormValues } from 'features/darkstores/types';
import { useFormController } from 'shared/lib/form';

export const DarkstoreSchedule = () => {
  const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-form' });

  const { field } = useFormController<DarkstoreFormValues, 'schedule'>({ name: 'schedule' });

  return (
    <Stack spacing="sm">
      <Input.Label>{t('schedule')}</Input.Label>
      <Group spacing="sm">
        <Input.Label sx={{ width: '50px' }}>{t('mo-fr')}</Input.Label>
        <Group spacing="xs">
          <Text size="sm">{t('from')}</Text>
          <TimeInput
            {...field}
            value={field.value[1].start}
            onChange={time => {
              const value = { ...field.value };
              for (let index = 1; index <= 5; index++) {
                value[index].start = time;
              }
              field.onChange(value);
            }}
          />
        </Group>
        <Group spacing="xs">
          <Text size="sm">{t('to')}</Text>
          <TimeInput
            {...field}
            value={field.value[1].finish}
            onChange={time => {
              const value = { ...field.value };
              for (let index = 1; index <= 5; index++) {
                value[index].finish = time;
              }
              field.onChange(value);
            }}
          />
        </Group>
      </Group>
      <Group spacing="sm">
        <Input.Label sx={{ width: '50px' }}>{t('sa-su')}</Input.Label>
        <Group spacing="xs">
          <Text size="sm">{t('from')}</Text>
          <TimeInput
            {...field}
            value={field.value[6].start}
            onChange={time => {
              const value = { ...field.value };
              for (let index = 6; index <= 7; index++) {
                value[index].start = time;
              }
              field.onChange(value);
            }}
          />
        </Group>
        <Group spacing="xs">
          <Text size="sm">{t('to')}</Text>
          <TimeInput
            {...field}
            value={field.value[6].finish}
            onChange={time => {
              const value = { ...field.value };
              for (let index = 6; index <= 7; index++) {
                value[index].finish = time;
              }
              field.onChange(value);
            }}
          />
        </Group>
      </Group>
    </Stack>
  );
};
