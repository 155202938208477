import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { useFormPluginsContext } from './form-plugins';

export const useFormController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
}: {
  name: TName;
}) => {
  const { control } = useFormContext<TFieldValues>();

  const { disabled, readOnly } = useFormPluginsContext();

  const controller = useController<TFieldValues, TName>({ control, name });

  controller.field.disabled = disabled;
  controller.field.readOnly = readOnly;

  return controller;
};
