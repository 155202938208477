import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Stack, LoadingOverlay, Title, Card, Button, Group } from '@mantine/core';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { DarkstoreForm, DarkstoreZonesDataGrid, useDarkstore } from 'features/darkstores';
import { useEditDarkstore } from 'features/darkstores';
import { useSettings } from 'features/settings';

export const DarkstoreEditPage = () => {
  const { t } = useTranslation('pages/darkstores');

  const hasWritePermission = usePermission('darkstores.write');
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, darkstore } = useDarkstore({ darkstoreId });
  const { isEditing, edit } = useEditDarkstore();

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  if (!darkstore) {
    return <Title order={2}>{t('darkstore-not-found')}</Title>;
  }

  return (
    <Stack spacing="xl">
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{t('information')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <DarkstoreForm
            id="darkstore-form"
            disabled={!hasWritePermission}
            defaultValues={darkstore}
            onSubmit={data => {
              edit({
                darkstoreId: darkstore.id,
                data: { ...data, zones: darkstore.zones },
              });
            }}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="darkstore-form"
            disabled={!hasWritePermission}
            loading={isEditing}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Group position="apart">
            <Title order={2} sx={{ marginRight: 16 }}>
              {t('darkstore-zones')}
            </Title>
            <Button
              disabled={!hasWritePermission}
              component={Link}
              to="zones/add"
              leftIcon={<PlusIcon size={14} />}
            >
              {t('add-darkstore-zone')}
            </Button>
          </Group>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <DarkstoreZonesDataGrid data={darkstore.zones} />
        </Card.Section>
      </Card>
    </Stack>
  );
};
