import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Menu } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { DotsVertical as DotsVerticalIcon, Trash as TrashIcon } from 'tabler-icons-react';
import type { StopList } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { STOP_LIST_REASONS_MAP } from '../constants';
import { useStopList } from '../hooks';
import { StopListItemRemoveModal } from './stop-list-item-remove-modal';

export const StopListDataGrid = ({ actionsColumn }: { actionsColumn?: boolean }) => {
  const { t } = useTranslation('features/stop-list', { keyPrefix: 'stop-list-data-grid' });

  const modals = useModals();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<StopList>();
    const dataColumns = [
      columnHelper.accessor('product.cod', {
        size: 100,
        header: () => t('columns.0'),
      }),
      columnHelper.accessor('product.name', {
        size: 200,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('reason', {
        size: 150,
        header: () => t('columns.2'),
        cell: params => STOP_LIST_REASONS_MAP[params.getValue()],
      }),
      columnHelper.accessor('finish', { size: 175, header: () => t('columns.3') }),
      columnHelper.accessor('term', { size: 175, header: () => t('columns.4') }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove'),
                        children: (
                          <Suspense>
                            <StopListItemRemoveModal
                              stopList={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [actionsColumn, t, modals]);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'id', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, stopList } = useStopList();

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={stopList}
      sorting
      manualSorting
      manualPagination
      state={state}
      onStateChange={setState}
    />
  );
};
