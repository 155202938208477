import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Box,
  Card,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  Stack,
  Text,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { Suspense } from 'shared/components';
import { BANNER_ACTION_TYPES_MAP } from '../constants';
import { useBanners } from '../hooks';
import { BannerEditModal } from './banner-edit-modal';
import { BannerRemoveModal } from './banner-remove-modal';

export const BannersCardsGrid = ({ actionsMenu }: { actionsMenu?: boolean }) => {
  const { t } = useTranslation('features/banners', { keyPrefix: 'banners-cards-grid' });

  const modals = useModals();

  const { isFetching, banners } = useBanners();

  return (
    <Group align="flex-start" sx={{ position: 'relative', minHeight: '400px' }}>
      <LoadingOverlay visible={isFetching} />
      {banners.map(banner => (
        <Card key={banner.id} withBorder>
          <Stack spacing={8}>
            <Group>
              <Text size="sm" weight={500}>
                {BANNER_ACTION_TYPES_MAP[banner.actionType]}
              </Text>
              {actionsMenu && (
                <Menu position="bottom-end">
                  <Menu.Target>
                    <ActionIcon sx={{ marginLeft: 'auto' }}>
                      <DotsVerticalIcon size={14} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      component="button"
                      icon={<PencilIcon size={14} />}
                      onClick={() => {
                        const modalId = modals.openModal({
                          title: t('edit-banner'),
                          children: (
                            <Suspense>
                              <BannerEditModal
                                banner={banner}
                                onClose={() => modals.closeModal(modalId)}
                              />
                            </Suspense>
                          ),
                        });
                      }}
                    >
                      {t('edit')}
                    </Menu.Item>
                    <Menu.Item
                      component="button"
                      icon={<TrashIcon size={14} />}
                      onClick={() => {
                        const modalId = modals.openModal({
                          title: t('remove-banner'),
                          children: (
                            <Suspense>
                              <BannerRemoveModal
                                bannerId={banner.id}
                                onClose={() => modals.closeModal(modalId)}
                              />
                            </Suspense>
                          ),
                        });
                      }}
                    >
                      {t('remove')}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
            <Group>
              <Box>
                <Image
                  radius="md"
                  src={banner.webImage}
                  alt="banner-web-image"
                  height={80}
                  fit="contain"
                />
              </Box>
              <Box>
                <Image
                  radius="md"
                  src={banner.appImage}
                  alt="banner-app-image"
                  height={80}
                  fit="contain"
                />
              </Box>
            </Group>
          </Stack>
        </Card>
      ))}
    </Group>
  );
};
