import { Box, Group } from '@mantine/core';
import { SignOutButton } from 'features/auth';
import { SettingsDarkstoreSelect, SettingsLanguageSelect } from 'features/settings';

export const Header = ({ isNavbarOpened }: { isNavbarOpened: boolean }) => {
  return (
    <Box
      component="header"
      sx={{
        position: 'fixed',
        right: '0px',
        width: `calc(100% - ${isNavbarOpened ? '200px' : '48px'})`,
        transition: 'width linear 0.2s',
        height: '60px',
        zIndex: 2,
        paddingInline: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.11)',
      }}
    >
      <SettingsDarkstoreSelect />
      <Group>
        <SettingsLanguageSelect />
        <SignOutButton />
      </Group>
    </Box>
  );
};
