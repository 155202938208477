import { Card, Group, Stack, Text } from '@mantine/core';

export const CustomerStatsCard = ({
  label,
  unit,
  value,
}: {
  label: string;
  unit: string;
  value: number;
}) => {
  return (
    <Card radius="sm" withBorder>
      <Card.Section sx={{ padding: 10 }}>
        <Stack spacing={2} align="center">
          <Group spacing={4}>
            <Text size={24} weight={700} color="orange" sx={{ lineHeight: 1 }}>
              {value}
            </Text>
            <Text
              size={18}
              weight={700}
              color="gray"
              sx={{ lineHeight: 1, textTransform: 'uppercase' }}
            >
              {unit}
            </Text>
          </Group>
          <Text size={10}>{label}</Text>
        </Stack>
      </Card.Section>
    </Card>
  );
};
