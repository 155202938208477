import { useCallback } from 'react';
import { useVirtual } from 'react-virtual';
import { Box } from '@mantine/core';
import { flexRender } from '@tanstack/react-table';
import { EditableCell } from './editable-cell';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridBody = () => {
  const { table, scrollElement } = useDataGridContext();

  const { rows } = table.getRowModel();

  const scrollRef = scrollElement ? { current: scrollElement } : { current: null };

  const rowVirtualizer = useVirtual({
    parentRef: scrollRef,
    size: rows.length,
    estimateSize: useCallback(() => 40, []),
  });

  return (
    <Box
      component="tbody"
      sx={{
        position: 'relative',
        width: '100%',
      }}
      style={{
        height: `${rowVirtualizer.totalSize}px`,
      }}
    >
      {rowVirtualizer.virtualItems.map(virtualRow => {
        const row = rows[virtualRow.index];
        return (
          <Box
            key={row.id}
            component="tr"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              flex: '1 0 auto',
              width: '100%',
              minWidth: '0px',
            }}
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            {row.getVisibleCells().map(cell => {
              if (cell.column.columnDef.meta?.editable) {
                cell.column.columnDef.cell = EditableCell;
              }
              return (
                <Box
                  key={cell.id}
                  component="td"
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    ...(cell.column.columnDef.meta?.sticky
                      ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: virtualRow.index % 2 === 0 ? '#f8f9fa' : '#ffffff',
                        }
                      : {}),
                  }}
                  style={{
                    flex: `${cell.column.getSize()} 0 auto`,
                    width: cell.column.getSize(),
                    minWidth: '0px',
                  }}
                  onClick={() => {
                    table.options.meta?.onCellClick?.({
                      rowId: row.id,
                      columnId: cell.column.id,
                      value: cell.getValue(),
                    });
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
