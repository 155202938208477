import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Menu } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import type { User } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { USER_ROLES_MAP } from '../../constants';
import { useEditUserPermissions, useUsers } from '../../hooks';
import { UserEditModal } from '../user-edit-modal';
import { UserRemoveModal } from '../user-remove-modal';
import { DarkstoresCell } from './darkstores-cell';
import { PermissionsCell } from './permissions-cell';

const MODULES = [
  'orders',
  'employees',
  'cash-accounting',
  'darkstore',
  // 'info/map',
  // 'info/payment-terms',
  // 'info/technical-break',
  'products',
  'registry',
  'write-offs',
  'suppliers',
  'supplies',
  // 'supplies-generate',
  'clients',
  'analytics',
  'marketing',
  'reports',
  'users',
];

export const UsersDataGrid = ({
  query,
  actionsColumn,
}: {
  query: string;
  actionsColumn?: boolean;
}) => {
  const { t } = useTranslation('features/users', { keyPrefix: 'users-data-grid' });

  const modals = useModals();

  const [debouncedQuery] = useDebouncedValue(query, 300);

  const { isFetching, users } = useUsers({ query: debouncedQuery });

  const { edit } = useEditUserPermissions();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<User>();
    const dataColumns = [
      columnHelper.accessor('id', {
        size: 75,
        // meta: { sticky: true },
        header: () => t('columns.0'),
      }),
      columnHelper.accessor('email', { size: 200, header: () => t('columns.1') }),
      columnHelper.accessor('name', { size: 150, header: () => t('columns.2') }),
      columnHelper.accessor('surname', { size: 150, header: () => t('columns.3') }),
      columnHelper.accessor('role', {
        size: 150,
        header: () => t('columns.4'),
        cell: params => USER_ROLES_MAP[params.getValue()],
      }),
      columnHelper.accessor('darkstores', {
        size: 250,
        header: () => t('columns.5'),
        cell: params => <DarkstoresCell {...params} />,
      }),
      // TODO: update to more deep accessor (like row.acl.module) when problem in library will be fixed
      ...MODULES.map(module =>
        columnHelper.accessor(row => row.acl, {
          id: `acl.${module}`,
          size: 150,
          cell: params => <PermissionsCell module={module} {...params} />,
        }),
      ),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<PencilIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('edit-user'),
                        children: (
                          <Suspense>
                            <UserEditModal
                              user={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('edit')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-user'),
                        children: (
                          <Suspense>
                            <UserRemoveModal
                              userId={params.row.original.id}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [actionsColumn, t, modals]);

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={users}
      sorting
      onCellEditStop={e => {
        const index = Number(e.rowId);
        const row = { ...users[index] };
        if (e.columnId.includes('acl')) {
          const module = e.columnId.split('.')[1];
          row.acl[module] = e.value as any;
          edit(row);
        }
      }}
    />
  );
};
