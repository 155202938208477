import { ReactNode, Suspense as ReactSuspense } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { ErrorBoundary } from './error-boundary';

export const Suspense = ({
  children,
  fallback = <LoadingOverlay visible />,
}: {
  children: ReactNode;
  fallback?: JSX.Element;
}) => {
  return (
    <ErrorBoundary>
      <ReactSuspense fallback={fallback}>{children}</ReactSuspense>
    </ErrorBoundary>
  );
};
