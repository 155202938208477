import { useTranslation } from 'react-i18next';
import { Stack, Title } from '@mantine/core';
import { ReportDataGrid, ReportsFiltersForm, ReportType, useReport } from 'features/reports';
import { Darkstore } from 'shared/lib/bazar-api';
import { useSearch } from 'shared/lib/search';
import { formatDate, parseDate } from 'shared/utils/date';
import { equal } from 'shared/utils/object';

export const ReportsPage = () => {
  const [search, setSearch] = useSearch<{
    type?: ReportType;
    darkstoresIds: Darkstore['id'][];
    period: [string, string];
    date: string;
    service?: string;
  }>({
    type: undefined,
    darkstoresIds: [],
    period: [formatDate(new Date(), 'YYYY-MM-DD'), formatDate(new Date(), 'YYYY-MM-DD')],
    date: formatDate(new Date(), 'YYYY-MM-DD'),
  });

  const { t } = useTranslation('pages/reports');

  const filters: {
    type?: ReportType;
    darkstoresIds: Darkstore['id'][];
    period: [Date, Date];
    date: Date;
    service?: string;
  } = {
    ...search,
    period: [parseDate(search.period[0]), parseDate(search.period[1])],
    date: parseDate(search.date),
  };

  const { isFetching, report, refetch } = useReport(filters);

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('reports')}</Title>
      <ReportsFiltersForm
        defaultValues={filters}
        onSubmit={data => {
          const isEqual = equal(filters, data);
          if (isEqual) {
            refetch();
          } else {
            setSearch({
              ...data,
              period: [
                formatDate(data.period[0], 'YYYY-MM-DD'),
                formatDate(data.period[1], 'YYYY-MM-DD'),
              ],
              date: formatDate(data.date, 'YYYY-MM-DD'),
            });
          }
        }}
      />
      <ReportDataGrid isLoading={isFetching} type={filters.type} data={report} />
    </Stack>
  );
};
