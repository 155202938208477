import { DarkstoreSelect } from 'features/darkstores';
import { useSettings } from './use-settings';

export const SettingsDarkstoreSelect = () => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);
  const setDarkstoreId = useSettings(settings => settings.setDarkstoreId);

  console.log(`darkstoreId = ${darkstoreId}`);

  return <DarkstoreSelect value={darkstoreId} onChange={setDarkstoreId} />;
};
