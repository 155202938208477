import { ReactNode } from 'react';
import { Box } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridScroll = ({ children }: { children: ReactNode }) => {
  const { setScrollElement } = useDataGridContext();

  const [mainRef, rect] = useResizeObserver();

  return (
    <Box
      ref={mainRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          overflow: 'visible',
          width: 0,
          height: 0,
        }}
      >
        <Box
          ref={setScrollElement}
          sx={{
            overflow: 'auto',
            border: '1px solid #dee2e6',
            borderRadius: '4px',
            backgroundColor: '#ffffff',
          }}
          style={{
            height: rect?.height,
            width: rect?.width,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
