import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, TextInput } from '@mantine/core';
import { Form, FormController } from 'shared/lib/form';

export const DarkstoreZoneDeliveryCost = ({
  id,
  disabled,
  defaultValues,
  onSubmit,
  values,
}: {
  id: string;
  disabled?: boolean;
  defaultValues: any;
  onSubmit: (values: any) => void;
  values: any;
}) => {
  const { t } = useTranslation('features/darkstores', {
    keyPrefix: 'darkstore-zone-delivery-cost',
  });

  const weekDaysOrder = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const groupedValues = weekDaysOrder.reduce((acc: any, day) => {
    const dayValues = values.filter((item: { weekDay: string }) => item.weekDay === day);
    dayValues.forEach((item: any) => {
      acc[`${day}_${item.id}`] = item;
    });
    return acc;
  }, {});

  return (
    <Form
      id={id}
      disabled={disabled}
      defaultValues={groupedValues}
      //validationSchema={darkstoreZoneDeliveryCostFormSchema}
      onSubmit={formValues => {
        const updatedValues = Object.values(formValues);
        onSubmit(updatedValues);
      }}
    >
      <Stack>
        <Grid>
          {weekDaysOrder.map(day => (
            <Stack key={day}>
              {values.some((item: { weekDay: string }) => item.weekDay === day) && (
                <h4>{t(day)}</h4>
              )}
              {values
                .filter((item: { weekDay: string }) => item.weekDay === day)
                .map((column: { id: number; orderSum: string; cost: string }, index: Key) => (
                  <Stack key={index}>
                    <Grid.Col span={12}>
                      <FormController
                        name={`${day}_${column.id}.orderSum`}
                        render={({ field, fieldState }) => (
                          <TextInput
                            {...field}
                            required
                            label={t('orderSum')}
                            placeholder={t('orderSum')}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                      <FormController
                        name={`${day}_${column.id}.cost`}
                        render={({ field, fieldState }) => {
                          return (
                            <TextInput
                              {...field}
                              required
                              label={t('deliveryCost')}
                              placeholder={t('deliveryCost')}
                              error={fieldState.error?.message}
                            />
                          );
                        }}
                      />
                    </Grid.Col>
                  </Stack>
                ))}
            </Stack>
          ))}
        </Grid>
      </Stack>
    </Form>
  );
};
