import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, Darkstore, Supplier, WriteOffReason, WriteOffType } from 'shared/lib/bazar-api';

export const useWriteOffs = ({
  darkstoreId,
  reason,
  month,
  year,
  supplierId,
  from,
  to,
  type,
}: {
  darkstoreId?: Darkstore['id'];
  reason: WriteOffReason;
  month: number;
  year: number;
  supplierId?: Supplier['id'];
  from: Date;
  to: Date;
  type: WriteOffType;
}) => {
  const partnerId = useSettings(settings => settings.partnerId);

  const { isFetching, data } = useQuery({
    queryKey: [
      'write-offs',
      { partnerId, darkstoreId, reason, month, year, supplierId, from, to, type },
    ],
    queryFn: () => {
      switch (reason) {
        case WriteOffReason.WriteOff: {
          if (darkstoreId) {
            return bazarApi.assortment.getWriteOffs({ darkstoreId, month, year });
          }
          return [];
        }
        case WriteOffReason.Return: {
          return bazarApi.assortment.getReturns({ partnerId, darkstoreId, supplierId, from, to });
        }
        default:
          if (darkstoreId) {
            return bazarApi.assortment.getCorrectQty({ darkstoreId, reason, month, year, type });
          }
          return [];
      }
    },
  });

  return { isFetching, writeOffs: data ?? [] };
};
