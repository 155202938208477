import { StopListReason } from 'shared/lib/bazar-api';

export const STOP_LIST_REASONS_MAP = {
  [StopListReason.wrongQty]: 'Неправильное количество',
  [StopListReason.onWay]: 'В пути / на складе',
  [StopListReason.discount]: 'Уцененный товар',
  [StopListReason.bakery]: 'СП выпечка',
  [StopListReason.defect]: 'Брак',
  [StopListReason.defectFROV]: 'Брак - ФРОВ',
};

export const STOP_LIST_REASONS = [
  { label: 'Неправильное количество', value: StopListReason.wrongQty },
  { label: 'В пути / на складе', value: StopListReason.onWay },
  { label: 'Уцененный товар', value: StopListReason.discount },
  { label: 'СП выпечка', value: StopListReason.bakery },
  { label: 'Брак', value: StopListReason.defect },
  { label: 'Брак - ФРОВ', value: StopListReason.defectFROV },
];

export const STOP_LIST_INTERVALS = ['2hours', '4hours', '6hours', '8hours', '10hours', 'dayOff'];
