export const REPORT_TYPES = [
  { label: 'Бухгалтерский учет', value: 'fiscal' },
  { label: 'Заказы за день', value: 'orders-by-day' },
  { label: 'Стоп-лист', value: 'stop-list' },
  { label: 'Сторонние сервисы', value: 'delivery-services' },
];

export const REPORT_DELVIERY_SERVICES = [
  { label: 'Yandex.Bazar', value: 'yandex-bazar' },
  { label: 'Yandex.Sosedi', value: 'yandex-sosedi' },
  { label: 'Delivio', value: 'delivio' },
];
