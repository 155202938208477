import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerReturn,
  useFormContext,
} from 'react-hook-form';
import { useFormPluginsContext } from './form-plugins';

export const FormController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  render,
}: {
  name: TName;
  render: (controller: UseControllerReturn<TFieldValues, TName>) => JSX.Element;
}) => {
  const { control } = useFormContext<TFieldValues>();

  const { disabled, readOnly } = useFormPluginsContext();

  const controller = useController<TFieldValues, TName>({ control, name });

  controller.field.disabled = disabled;
  controller.field.readOnly = readOnly;

  return render(controller);
};
