import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi, DarkstoreZone } from 'shared/lib/bazar-api';
import type { DarkstoreZoneFormValues } from '../types';

type AddDarkstoreZoneDTO = {
  data: DarkstoreZoneFormValues;
};

export const useAddDarkstoreZone = ({
  onSuccess,
}: {
  onSuccess: (data: { id: DarkstoreZone['id'] }, variables: AddDarkstoreZoneDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    { id: DarkstoreZone['id'] },
    Error,
    AddDarkstoreZoneDTO
  >({
    mutationFn: variables => {
      return bazarApi.darkstoreZones.add(variables.data);
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Зона добавлена',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['darkstore-zones'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
