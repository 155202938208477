import { formatDate, parseDate } from 'shared/utils/date';
import { bazarApiClient } from '../client';
import { Darkstore } from '../types';

const getList = async ({
  partnerId,
}: {
  partnerId: number;
}): Promise<Pick<Darkstore, 'id' | 'name'>[]> => {
  const res = await bazarApiClient.get('admin/darkstores', { searchParams: { partnerId } });
  const data = await res.json<any[]>();
  return data.map(item => ({ id: item.id, name: item.name }));
};

const getOne = async ({ id }: { id: Darkstore['id'] }): Promise<Darkstore> => {
  const res = await bazarApiClient.get(`admin/darkstores/${id}`);
  const data = await res.json<any>();
  return {
    id,
    zones: data.zones.map((p: any) => ({
      id: p.id,
      status: p.status,
      paymentMethods: p.paymentMethods.map((pm: any) => pm.id),
      //points: JSON.parse(p.points).map(([lat, lng]: any) => ({ lat, lng })),
      points: [],
      name: p.name,
      defaultDeliveryTime: p.defaultDeliveryTime,
      deliveryTime: p.deliveryTime,
      minOrderSum: p.minOrderSum,
      deliveryCost: p.deliveryCost,
    })),
    schedule: Object.values(data.schedule as any[]).reduce(
      (
        acc: Record<number, { start: Date; finish: Date }>,
        item: { start: string; finish: string },
        index,
      ) => {
        acc[index + 1] = {
          start: parseDate(item.start, 'HH:mm'),
          finish: parseDate(item.finish, 'HH:mm'),
        };
        return acc;
      },
      {},
    ),
    name: data.name,
    address: `${data.address.street}, ${data.address.house}, ${data.address.city}, ${data.address.country}`,
    phone: data.phone,
    phones: data.phones.map((t: any) => ({ icon: t.icon, phone: t.phone })),
    links: data.links.map((l: any) => ({ title: l.title, url: l.url })),
    legal: data.legal,
  };
};

const edit = async (variables: Darkstore) => {
  const body = JSON.stringify({
    id: variables.id,
    zones: variables.zones.map(z => ({
      id: z.id,
      status: z.status,
      paymentMethods: z.paymentMethods.map(id => ({
        id,
        // TODO: replace with actual name ?_?
        title: '',
      })),
      //points: JSON.stringify(z.points.map(p => [p.lat, p.lng])),
      name: z.name,
      defaultDeliveryTime: z.defaultDeliveryTime,
      deliveryTime: z.deliveryTime,
      deliveryCost: z.deliveryCost,
      minOrderSum: z.minOrderSum,
    })),
    schedule: Object.values(variables.schedule).reduce(
      (acc: Record<number, { start: string; finish: string }>, item, index) => {
        acc[index + 1] = {
          start: formatDate(item.start, 'HH:mm'),
          finish: formatDate(item.finish, 'HH:mm'),
        };
        return acc;
      },
      {},
    ),
    selfDeliverySchedule: Object.values(variables.schedule).reduce(
      (acc: Record<number, { start: string; finish: string }>, item, index) => {
        acc[index + 1] = {
          start: formatDate(item.start, 'HH:mm'),
          finish: formatDate(item.finish, 'HH:mm'),
        };
        return acc;
      },
      {},
    ),
    hasSelfDelivery: false,
    name: variables.name,
    //address: variables.address,
    phone: variables.phone,
    phones: variables.phones.map(p => ({ icon: p.icon, phone: p.phone })),
    links: variables.links.map(l => ({ title: l.title, url: l.url })),
    legal: variables.legal,
  });
  const res = await bazarApiClient.put(`admin/darkstores`, { body });
  const data = await res.json();
  return data;
};

export const darkstores = { getList, getOne, edit };
