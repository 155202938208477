import { formatDate, parseDate } from 'shared/utils/date';
import { round } from 'shared/utils/number';
import { bazarApiClient } from '../client';
import { Customer, CustomerRow } from '../types';

const getList = async ({
  partnerId,
  query,
  sortBy = 'name',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  partnerId: number;
  query: string;
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{ rows: CustomerRow[]; totalPages: number }> => {
  const res = await bazarApiClient.get('admin/customers', {
    searchParams: {
      partnerId,
      phone: query.toString().replace('+', ''),
      sortField: sortBy,
      sortOrder,
      page: pageNumber,
      pageSize,
    },
  });
  const data = await res.json<{ rows: any[]; rowsCount: number }>();
  return {
    rows: data.rows.map(row => {
      return {
        id: row.id,
        name: row.name,
        surname: row.surname,
        phone: row.phone,
        ordersAmount: row.qtyOrders,
        ordersSum: round(row.sumOrders),
        averageOrderSum: round(row.averageCheck),
        averageOrderSize: round(row.averageProducts),
        lastOrderedAt: row.lastOrderDate ? parseDate(row.lastOrderDate, 'DD/MM/YYYY') : null,
      };
    }),
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

const getOne = async ({ id }: { id: Customer['id'] }): Promise<Customer> => {
  const res = await bazarApiClient.get(`admin/customers/${id}`);
  const data = await res.json<any>();
  return {
    id: data.customerId,
    name: data.name,
    surname: data.surname,
    phone: data.phone,
    addresses: data.address.map((a: any) => ({
      street: a.addressStreet,
      building: a.addressCorps,
      house: a.addressBuilding,
      flat: a.addressFlat,
      entrance: a.addressEntrance,
      floor: a.addressFloor,
      intercom: a.intercom,
    })),
    orders: data.orders.map((o: any) => ({
      id: o.id,
      courierId: o.courierId,
      courierName: o.courierName,
      courierSurname: o.courierSurname,
      status: o.status,
      paymentMethod: o.paymentMethod,
      sum: o.sum,
      createdAt: parseDate(o.date, 'DD.MM.YYYY'),
    })),
    ordersAmount: data.qtyOrders,
    ordersSum: round(data.sumOrders),
    averageOrderSum: round(data.averageCheck),
    averageOrderSize: round(data.averageProducts),
    invitedCustomersAmount: data.friends.length,
    bonusesReceivedSum: data.bonuses,
    bonusesSpentSum: data.debitedBonuses,
    lastMonthOrdersAmount: data.qtyOrdersLastMonth,
    lastWeekOrdersAmount: data.qtyOrdersLastWeek,
    createdAt: parseDate(data.dateReg, 'DD.MM.YYYY'),
    bornAt: data.birthday ? parseDate(data.birthday) : null,
    firstOrderedAt: data.firstOrderDate ? parseDate(data.firstOrderDate, 'DD.MM.YYYY') : null,
    lastOrderedAt: data.lastOrderDate ? parseDate(data.lastOrderDate, 'DD.MM.YYYY') : null,
  };
};

const add = async (
  variables: Pick<Customer, 'name' | 'surname' | 'bornAt' | 'phone' | 'addresses'>,
) => {
  const body = JSON.stringify({
    name: variables.name,
    surname: variables.surname,
    birthday: variables.bornAt ? formatDate(variables.bornAt, 'YYYY-MM-DD') : null,
    phone: variables.phone,
    address: variables.addresses.map(a => ({
      addressStreet: a.street,
      addressCorps: a.building,
      addressBuilding: a.house,
      addressFlat: a.flat,
      addressEntrance: a.entrance,
      addressFloor: a.floor,
      intercom: a.intercom,
    })),
  });
  const res = await bazarApiClient.post('admin/customers', { body });
  const data = await res.json();
  return data;
};

const edit = async (
  variables: Pick<Customer, 'id' | 'name' | 'surname' | 'bornAt' | 'phone' | 'addresses'>,
) => {
  const body = JSON.stringify({
    customerId: variables.id,
    name: variables.name,
    surname: variables.surname,
    birthday: variables.bornAt ? formatDate(variables.bornAt, 'YYYY-MM-DD') : null,
    phone: variables.phone,
    address: variables.addresses.map(a => ({
      addressStreet: a.street,
      addressCorps: a.building,
      addressBuilding: a.house,
      addressFlat: a.flat,
      addressEntrance: a.entrance,
      addressFloor: a.floor,
      intercom: a.intercom,
    })),
  });
  const res = await bazarApiClient.put('admin/customers', { body });
  const data = await res.json();
  return data;
};

export const customers = { getList, getOne, add, edit };
