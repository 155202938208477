import { ReactNode, useMemo, useState } from 'react';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { DataGridContext } from './data-grid-context';
import { DataGridContextValue, DataGridProps } from './types';

export const DataGridContextProvider = <TData extends RowData>({
  children,
  props,
}: {
  children: ReactNode;
  props: DataGridProps<TData>;
}) => {
  const [scrollElement, setScrollElement] = useState<HTMLDivElement>();

  const { columns, data, onCellClick, onCellEditStop, ...rest } = props;

  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const options: TableOptions<TData> = {
    ...rest,
    columns: memoizedColumns,
    data: memoizedData,
    getCoreRowModel: getCoreRowModel(),
    meta: { onCellClick, onCellEditStop },
  };

  if (props.sorting && !props.manualSorting) {
    options.getSortedRowModel = getSortedRowModel();
  }

  if (props.pagination && !props.manualPagination) {
    options.getPaginationRowModel = getPaginationRowModel();
  }

  const table = useReactTable<TData>(options);

  const value: DataGridContextValue<TData> = { props, table, scrollElement, setScrollElement };

  return <DataGridContext.Provider value={value}>{children}</DataGridContext.Provider>;
};
