import { Darkstore, Supplier, Supply, SupplyProduct } from 'shared/lib/bazar-api';
import { useEditSupplyProduct } from '../hooks';
import { SupplyProductForm } from './supply-product-form';

export const SupplyProductEditModal = ({
  supplyId,
  darkstoreId,
  supplierId,
  product,
  onClose,
}: {
  supplyId: Supply['id'];
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  product: SupplyProduct;
  onClose: () => void;
}) => {
  const { isEditing, edit } = useEditSupplyProduct({ onSuccess: onClose });

  return (
    <SupplyProductForm
      darkstoreId={darkstoreId}
      supplierId={supplierId}
      mode="edit"
      isSubmitting={isEditing}
      defaultValues={{
        productId: product.id,
        productName: product.name,
        amount: product.amount,
      }}
      onSubmit={data => edit({ supplyId, data })}
    />
  );
};
