import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { Darkstore } from 'shared/lib/bazar-api';
import { useDarkstores } from '../hooks';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: Darkstore['id'] | null;
  onChange: (value: Darkstore['id']) => void;
};

export const DarkstoreSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-select' });

    const { isFetching, darkstores } = useDarkstores();

    const data: SelectItem[] = darkstores.map(darkstore => ({
      value: darkstore.id.toString(),
      label: darkstore.name,
    }));

    return (
      <Select
        ref={ref}
        placeholder={t('select-darkstore')}
        data={data}
        value={value?.toString()}
        rightSection={isFetching && <Loader size="xs" />}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
