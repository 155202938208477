import { round } from 'shared/utils/number';
import { bazarApiClient } from '../client';
import { Darkstore, ViralityRow, ViralityStatistic } from '../types';

const getVirality = async ({
  darkstoreId,
  sortBy = 'name',
  sortOrder = 'descending',
  pageNumber = 1,
  pageSize = 10,
}: {
  darkstoreId: Darkstore['id'];
  sortBy?: string;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<{
  rows: ViralityRow[];
  total: ViralityStatistic;
  totalPages: number;
}> => {
  const res = await bazarApiClient.get('admin/analytics/virality', {
    searchParams: {
      darkstoreId,
      sortField: sortBy,
      sortOrder,
      page: pageNumber,
      pageSize,
    },
  });
  const data = await res.json<{ rows: any[]; total: any; rowsCount: number }>();
  return {
    rows: data.rows.map(row => ({
      customer: {
        id: row.customerId,
        name: row.name,
        surname: row.surname,
        phone: row.phone,
      },
      statistic: {
        invites: row.countInvite,
        one: row.order1,
        two: row.order2,
        three: row.order3,
        sum: row.sum,
      },
    })),
    total: {
      invites: data.total.countInvite,
      one: data.total.order1,
      two: data.total.order2,
      three: data.total.order3,
      sum: round(data.total.sum),
    },
    totalPages: Number((data.rowsCount / pageSize).toFixed(0)),
  };
};

export const analytics = { getVirality };
