import { bazarApiClient } from '../client';
import { Darkstore, DarkstoreZone, DarkstoreZonePoint } from '../types';

const getOne = async ({
  id,
  darkstoreId,
}: {
  id: DarkstoreZone['id'];
  darkstoreId: Darkstore['id'];
}): Promise<DarkstoreZone> => {
  const res = await bazarApiClient.get(`admin/darkstores-zone`, {
    searchParams: { zoneId: id, darkstoreId },
  });
  const data = await res.json<any>();
  return {
    id,
    darkstoreId,
    status: data.zoneStatus,
    paymentMethods: data.paymentMethods.map((pm: any) => pm.id),
    points: JSON.parse(data.points).map(([lat, lng]: [number, number]) => ({ lat, lng })),
    name: data.name,
    defaultDeliveryTime: data.defaultDeliveryTime,
    deliveryTime: data.delivery,
    deliveryCost: data.deliveryCost,
    minOrderSum: data.minSum,
  };
};

const getPolygons = async (): Promise<
  {
    darkstoreId: Darkstore['id'];
    zoneId: DarkstoreZone['id'];
    points: DarkstoreZonePoint[];
  }[]
> => {
  const res = await bazarApiClient.get('polygons');
  const data = await res.json<any[]>();
  const polygons = data.map(p => ({
    darkstoreId: p.darkstoreId,
    zoneId: p.zoneId,
    points: JSON.parse(p.points).map(([lat, lng]: [number, number]) => ({ lat, lng })),
  }));
  return polygons;
};

const add = async (
  variables: Omit<DarkstoreZone, 'id'> & { darkstoreId: Darkstore['id'] },
): Promise<{ id: DarkstoreZone['id'] }> => {
  const body = JSON.stringify({
    darkstoreId: variables.darkstoreId,
    zoneStatus: variables.status,
    paymentMethods: variables.paymentMethods.map(id => ({
      id,
      // TODO: replace with actual name ?_?
      title: '',
    })),
    points: JSON.stringify(variables.points.map(p => [p.lat, p.lng])),
    name: variables.name,
    defaultDeliveryTime: variables.defaultDeliveryTime,
    delivery: variables.deliveryTime,
    deliveryCost: variables.deliveryCost,
    minSum: variables.minOrderSum,
  });
  const res = await bazarApiClient.post(`admin/darkstores-zone`, { body });
  const data = await res.json<any>();
  return { id: data.zoneId };
};

const edit = async (variables: DarkstoreZone & { darkstoreId: Darkstore['id'] }) => {
  const body = JSON.stringify({
    zoneId: variables.id,
    darkstoreId: variables.darkstoreId,
    zoneStatus: variables.status,
    paymentMethods: variables.paymentMethods.map(id => ({
      id,
      // TODO: replace with actual name ?_?
      title: '',
    })),
    points: JSON.stringify(variables.points.map(p => [p.lat, p.lng])),
    name: variables.name,
    defaultDeliveryTime: variables.defaultDeliveryTime,
    delivery: variables.deliveryTime,
    deliveryCost: variables.deliveryCost,
    minSum: variables.minOrderSum,
  });
  const res = await bazarApiClient.put(`admin/darkstores-zone`, { body });
  const data = await res.json();
  return data;
};

const getDeliveryCost = async ({
  id,
  darkstoreId,
}: {
  id: DarkstoreZone['id'];
  darkstoreId: Darkstore['id'];
}): Promise<any> => {
  const res = await bazarApiClient.get(`admin/darkstores/${darkstoreId}/delivery-cost`, {
    searchParams: { zoneId: id },
  });
  const data = await res.json<any>();
  return data;
};

const editDeliveryCost = async ({
  darkstoreId,
  variables,
}: {
  darkstoreId: number;
  variables: any;
}) => {
  const res = await bazarApiClient.post(`admin/darkstores/${darkstoreId}/delivery-cost`, {
    body: JSON.stringify(variables.data),
  });
  const data = await res.text();
  return data;
};

export const darkstoreZones = { getOne, getPolygons, add, edit, getDeliveryCost, editDeliveryCost };
