import { useContext } from 'react';
import { AuthContext } from '../auth-context';

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  if (ctx === undefined) {
    throw new Error('AuthContext not found');
  }
  return ctx;
};
