import { z } from 'zod';
import { EmployeeRole } from 'shared/lib/bazar-api';
import { validatePhone } from 'shared/utils/phone';

export const employeeFormSchema = z.object({
  darkstoreId: z.number().int().gte(0),
  phone: z.string().refine(validatePhone),
  name: z.string().min(1),
  surname: z.string().min(1),
  photo: z.string().url(),
  password: z.string().min(6),
  role: z.nativeEnum(EmployeeRole),
});
