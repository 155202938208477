import { useTranslation } from 'react-i18next';
import { Box, Grid, Group, NumberInput, Stack, Textarea, TextInput } from '@mantine/core';
import { supplierFormSchema } from 'features/suppliers/schemas';
import { DaysMultiSelect, FileDropzone } from 'shared/components';
import { FileType, PaymentTermType } from 'shared/lib/bazar-api';
import { Form, FormController, FormWatch } from 'shared/lib/form';
import { SupplierFormValues } from '../../types';
import { PaymentTermTypeSelect } from './payment-term-type-select';
import { SupplierContacts } from './supplier-contacts';
import { SupplierTypeSelect } from './supplier-type-select';

export const SupplierForm = ({
  id,
  disabled,
  defaultValues,
  onSubmit,
}: {
  id: string;
  disabled?: boolean;
  defaultValues: Partial<SupplierFormValues>;
  onSubmit: (values: SupplierFormValues) => void;
}) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-form' });

  return (
    <Form
      id={id}
      disabled={disabled}
      defaultValues={defaultValues}
      validationSchema={supplierFormSchema}
      onSubmit={onSubmit}
    >
      <Grid>
        <Grid.Col span={6}>
          <Stack>
            <FormController
              name="name"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label={t('name')}
                  placeholder={t('input-name')}
                  error={fieldState.error?.message}
                />
              )}
            />
            <FormController
              name="upn"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label={t('upn')}
                  placeholder={t('input-upn')}
                  error={fieldState.error?.message}
                />
              )}
            />
            <FormController
              name="contractNumber"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label={t('contract-number')}
                  placeholder={t('input-contract-number')}
                  error={fieldState.error?.message}
                />
              )}
            />
            <FormController
              name="contract"
              render={({ field, fieldState }) => {
                return (
                  <FileDropzone
                    {...field}
                    type={FileType.Document}
                    required
                    label={t('contract')}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack>
            <Group grow>
              <Box>
                <FormController
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      required
                      label={t('email')}
                      placeholder={t('input-email')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <FormController
                  name="type"
                  render={({ field, fieldState }) => (
                    <SupplierTypeSelect
                      {...field}
                      required
                      label={t('supplier-type')}
                      placeholder={t('select-supplier-type')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Group>
            <FormController
              name="requisites"
              render={({ field, fieldState }) => (
                <Textarea
                  {...field}
                  label={t('requisites')}
                  placeholder={t('input-requisites')}
                  error={fieldState.error?.message}
                  minRows={5}
                />
              )}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={12}>
          <SupplierContacts />
        </Grid.Col>
        <Grid.Col span={3}>
          <FormController
            name="requestDays"
            render={({ field, fieldState }) => (
              <DaysMultiSelect
                {...field}
                required
                label={t('request-days')}
                error={fieldState.error?.message}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <FormController
            name="paymentTermType"
            render={({ field, fieldState }) => (
              <PaymentTermTypeSelect
                {...field}
                required
                label={t('payment-term-type')}
                placeholder={t('select-payment-term-type')}
                error={fieldState.error?.message}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <FormWatch
            name="paymentTermType"
            render={paymentTermType => {
              if (paymentTermType === PaymentTermType.Postponement) {
                return (
                  <FormController
                    name="paymentDelay"
                    render={({ field, fieldState }) => (
                      <NumberInput
                        {...field}
                        precision={0}
                        label={t('payment-delay')}
                        placeholder={t('input-payment-delay')}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                );
              }
              return null;
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <FormController
            name="paymentTermComment"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label={t('payment-term-comment')}
                placeholder={t('input-payment-term-comment')}
                error={fieldState.error?.message}
                minRows={5}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <FormController
            name="comment"
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                label={t('comment')}
                placeholder={t('input-comment')}
                error={fieldState.error?.message}
                minRows={5}
              />
            )}
          />
        </Grid.Col>
      </Grid>
    </Form>
  );
};
