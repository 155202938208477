import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './core';
import { BASE_URL } from './shared/config/env';

const element = document.getElementById('root');

// TODO: return strict mode after "@react-google-maps/api" updates

if (element) {
  const root = createRoot(element);
  root.render(
    <BrowserRouter basename={BASE_URL}>
      <App />
    </BrowserRouter>,
  );
}
