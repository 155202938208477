import { Badge, MantineSize } from '@mantine/core';
import { OrderStatus } from 'shared/lib/bazar-api';
import { ORDERS_STATUSES_MAP } from '../constants';

const COLORS_MAP = {
  [OrderStatus.New]: 'gray',
  [OrderStatus.Collecting]: 'yellow',
  [OrderStatus.Collected]: 'yellow',
  [OrderStatus.Delivering]: 'yellow',
  [OrderStatus.Transferred]: 'green',
  [OrderStatus.Delivered]: 'green',
  [OrderStatus.Canceled]: 'gray',
};

export const OrderStatusBadge = ({ status, size }: { status: OrderStatus; size?: MantineSize }) => {
  return (
    <Badge color={COLORS_MAP[status]} size={size}>
      {ORDERS_STATUSES_MAP[status]}
    </Badge>
  );
};
