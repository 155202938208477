import { z } from 'zod';
import { DarkstoreZoneStatus, PaymentMethod } from 'shared/lib/bazar-api';
import { validatePhone } from 'shared/utils/phone';

export const darkstoreFormSchema = z.object({
  schedule: z.record(z.string(), z.object({ start: z.date(), finish: z.date() })),
  name: z.string().min(1),
  address: z.string().min(1),
  phone: z.string().refine(validatePhone),
  phones: z.array(z.object({ icon: z.string(), phone: z.string().refine(validatePhone) })),
  links: z.array(z.object({ title: z.string().min(1), url: z.string().url() })),
  legal: z.string().min(1),
});

export const darkstoreZoneFormSchema = z.object({
  darkstoreId: z.number().int().gte(0),
  status: z.nativeEnum(DarkstoreZoneStatus),
  paymentMethods: z.array(z.nativeEnum(PaymentMethod)),
  points: z.array(z.object({ lat: z.number(), lng: z.number() })),
  name: z.string().min(1),
  defaultDeliveryTime: z.number().int().gte(1),
  deliveryTime: z.number().int().gte(1),
  deliveryCost: z.number().int().gte(0),
  minOrderSum: z.number().int().gte(0),
});
