export const MarketingIcon = ({
  size = 24,
  color = 'currentColor',
  ...restProps
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.333 9.923a1 1 0 0 1 1-1h7.334l7.657-2.89a.5.5 0 0 1 .677.467v14.282a.5.5 0 0 1-.677.468l-7.657-2.89h-7.334a1 1 0 0 1-1-1V9.923Zm3.335 9.835a.5.5 0 0 1 .5-.5h3.445a.5.5 0 0 1 .5.5v5.74a.5.5 0 0 1-.5.5h-3.445a.5.5 0 0 1-.5-.5v-5.74Zm-4.445-8.325v-.04C6.955 11.605 6 12.531 6 13.64c0 1.11.955 2.035 2.223 2.248a1 1 0 0 1 0-.035v-4.42Z"
      />
    </svg>
  );
};
