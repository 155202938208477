import { useTranslation } from 'react-i18next';
import { Button, Group, Stack, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Plus as PlusIcon } from 'tabler-icons-react';
import { usePermission } from 'features/auth';
import { StopListAddModal, StopListDataGrid } from 'features/stop-list';
import { Suspense } from 'shared/components';

export const StopListPage = () => {
  const { t } = useTranslation('pages/stop-list');
  const modals = useModals();

  const hasWritePermission = usePermission('employees.write');

  return (
    <Stack sx={{ flex: 1 }}>
      <Title order={2}>{t('title')}</Title>
      <Group>
        <Button
          disabled={!hasWritePermission}
          leftIcon={<PlusIcon size={14} />}
          onClick={() => {
            const modalId = modals.openModal({
              title: t('add'),
              children: (
                <Suspense>
                  <StopListAddModal onClose={() => modals.closeModal(modalId)} />
                </Suspense>
              ),
            });
          }}
        >
          {t('add')}
        </Button>
      </Group>
      <StopListDataGrid actionsColumn={hasWritePermission} />
    </Stack>
  );
};
