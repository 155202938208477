import { useTranslation } from 'react-i18next';
import { useDidUpdate } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { LanguageSelect } from 'shared/components';

export const SettingsLanguageSelect = () => {
  const queryClient = useQueryClient();

  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  useDidUpdate(() => {
    document.documentElement.lang = language;
    queryClient.invalidateQueries();
  }, [language]);

  return <LanguageSelect value={language} onChange={changeLanguage} />;
};
