import { useSettings } from 'features/settings';
import { FILESTORAGE_URL } from 'shared/config/env';
import { Employee, EmployeeRole } from 'shared/lib/bazar-api';
import { useAddEmployee } from '../hooks';
import { EmployeeForm } from './employee-form';

export const EmployeeAddModal = ({
  employee,
  onClose,
}: {
  employee?: Employee;
  onClose: () => void;
}) => {
  const { isAdding, add } = useAddEmployee({ onSuccess: onClose });

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  return (
    <EmployeeForm
      isSubmitting={isAdding}
      defaultValues={{
        darkstoreId,
        phone: '',
        name: '',
        surname: '',
        photo: `${FILESTORAGE_URL}/img/courier-photo.png`,
        role: EmployeeRole.Courier,
        ...employee,
        password: '',
      }}
      onSubmit={data => add({ data })}
    />
  );
};
