import { useQuery } from '@tanstack/react-query';
import { bazarApi, Customer } from 'shared/lib/bazar-api';

export const useCustomer = ({ customerId }: { customerId?: Customer['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['customers', { customerId }],
    queryFn: () => {
      if (customerId) {
        return bazarApi.customers.getOne({ id: customerId });
      }
    },
    enabled: !!customerId,
    suspense: true,
  });

  return { isFetching, customer: data };
};
