import { useTranslation } from 'react-i18next';
import { Anchor, Card, Grid, Group, Input, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { PhoneInput } from 'shared/components';
import { FormController, useFormFieldArray } from 'shared/lib/form';
import { SupplierFormValues } from '../../types';

export const SupplierContacts = () => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'supplier-form' });

  const { fields, append, remove } = useFormFieldArray<SupplierFormValues, 'contacts'>({
    name: 'contacts',
  });

  return (
    <Grid>
      {fields.map((field, index) => (
        <Grid.Col key={field.id} span={6}>
          <Stack spacing={2}>
            <Group position="apart">
              <Input.Label>{index === 0 && t('contacts')}</Input.Label>
              {!field.disabled && (
                <>
                  {index === 0 ? (
                    <Anchor
                      size="xs"
                      onClick={() => {
                        append({ name: '', phone: '', birthday: null, email: '', comment: '' });
                      }}
                    >
                      {t('add-contact')}
                    </Anchor>
                  ) : (
                    <Anchor size="xs" onClick={() => remove(index)}>
                      {t('remove-contact')}
                    </Anchor>
                  )}
                </>
              )}
            </Group>
            <Card radius="sm" withBorder>
              <SimpleGrid cols={2}>
                <FormController
                  name={`contacts.${index}.name`}
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      required
                      label={t('contact-name')}
                      placeholder={t('input-contact-name')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <FormController
                  name={`contacts.${index}.phone`}
                  render={({ field, fieldState }) => (
                    <PhoneInput
                      {...field}
                      required
                      label={t('contact-phone')}
                      placeholder={t('input-contact-phone')}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </SimpleGrid>
            </Card>
          </Stack>
        </Grid.Col>
      ))}
    </Grid>
  );
};
