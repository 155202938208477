import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import dayjs from 'dayjs';
import { OrderStatusBadge, PAYMENT_METHODS_MAP } from 'features/orders';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { formatDate, formatDateTime } from 'shared/utils/date';
import { ReportType } from '../types';

export const ReportDataGrid = ({
  isLoading,
  type,
  data,
}: {
  isLoading: boolean;
  type?: ReportType;
  data: any[];
}) => {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    switch (type) {
      case ReportType.Fiscal:
        return [
          columnHelper.accessor(row => row.order.id, {
            id: 'order.id',
            meta: { sticky: true },
            header: 'Id заказа',
            cell: params => {
              const value = params.getValue();
              return (
                <Anchor component={Link} to={`/orders/${value}`}>
                  {value}
                </Anchor>
              );
            },
          }),
          columnHelper.accessor(row => row.order.createdAt, {
            id: 'order.createdAt',
            header: 'Дата и время заказа',
            cell: params => {
              const value = params.getValue();
              if (value) {
                return formatDateTime(value);
              }
              return '';
            },
          }),
          columnHelper.accessor(row => row.darkstore.name, {
            id: 'darkstore.name',
            header: 'Даркстор',
          }),
          columnHelper.accessor(row => row.order.status, {
            id: 'order.status',
            header: 'Статус заказа',
            cell: params => <OrderStatusBadge status={params.getValue()} />,
          }),
          columnHelper.accessor(row => row.order.deliveredAt, {
            id: 'order.deliveredAt',
            header: 'Дата и время доставки',
            cell: params => {
              const value = params.getValue();
              if (value) {
                return formatDateTime(value);
              }
              return '';
            },
          }),
          columnHelper.accessor(row => row.zone?.name, {
            id: 'zone.name',
            header: 'Зона доставки',
          }),
          columnHelper.accessor(row => row.zone?.deliveryTime, {
            id: 'zone.deliveryTime',
            header: 'Время доставки (зона)',
          }),
          columnHelper.accessor(row => row.zone?.minOrderSum, {
            id: 'zone.minOrderSum',
            header: 'Мин. заказ (зона)',
          }),
          columnHelper.accessor(row => row.order.paymentMethod, {
            id: 'order.paymentMethod',
            header: 'Способ оплаты',
            //@ts-ignore
            cell: params => PAYMENT_METHODS_MAP[params.getValue()],
          }),
          columnHelper.accessor(
            row => {
              if (row.order.deliveryTime === '00:00:00') {
                return 'Сейчас';
              }
              if (
                dayjs(row.order.createdAt).get('date') === dayjs(row.order.deliveryDate).get('date')
              ) {
                return `Сегодня ${row.order.deliveryTime}`;
              }
              return `Завтра ${row.order.deliveryTime}`;
            },
            { id: 'order.deliveryTo', header: 'Доставка к' },
          ),
          columnHelper.accessor(
            row => {
              if (row.picker) {
                return `${row.picker.name} ${row.picker.surname}`;
              }
              return '';
            },
            { id: 'picker-info', header: 'Комплектовщик' },
          ),
          columnHelper.accessor(
            row => {
              if (row.courier) {
                return `${row.courier.name} ${row.courier.surname}`;
              }
              return '';
            },
            { id: 'courier-info', header: 'Курьер' },
          ),
          columnHelper.accessor('name', {
            header: 'Название',
            cell: params => {
              return (
                <Anchor component={Link} to={`/products/${params.row.original.id}`}>
                  {params.getValue()}
                </Anchor>
              );
            },
          }),
          columnHelper.accessor('barcode', { header: 'Штрихкод' }),
          columnHelper.accessor('amount', { header: 'Количество' }),
          columnHelper.accessor('price', { header: 'Цена' }),
          columnHelper.accessor('clearPrice', { header: 'Цена со скидкой (бонусом)' }),
          columnHelper.accessor(row => row.order.sum, { id: 'order.sum', header: 'Сумма заказа' }),
          columnHelper.accessor(
            row => {
              return Math.round((row.order.sum - row.order.clearSum) * 100) / 100;
            },
            { id: 'order.discountSum', header: 'Сумма скидки (бонуса)' },
          ),
          columnHelper.accessor(row => row.order.promocode, {
            id: 'order.promocode',
            header: 'Промокод',
          }),
          columnHelper.accessor(row => row.order.bonuses, {
            id: 'order.bonuses',
            header: 'Бонусы',
          }),
        ];
      case ReportType.OrdersByDay:
        return [
          columnHelper.accessor('date', {
            size: 150,
            header: 'Дата',
            cell: params => formatDate(params.getValue()),
          }),
          columnHelper.accessor('orders', {
            size: 200,
            header: 'Кол-во заказов',
          }),
          columnHelper.accessor('sum', {
            size: 200,
            header: 'Сумма заказов',
          }),
          columnHelper.accessor('ordersWithoutPickup', {
            size: 200,
            header: 'Заказы Соседи-Доставка',
          }),
          columnHelper.accessor('sumWithoutPickup', {
            size: 200,
            header: 'ТО, Соседи-Доставка',
          }),
          columnHelper.accessor('ordersAggregators', {
            size: 200,
            header: 'Заказы агрегаторов',
          }),
          columnHelper.accessor('sumOrdersAggregators', {
            size: 200,
            header: 'ТО, заказы агрегаторов',
          }),
        ];
      case ReportType.StopList:
        return [
          columnHelper.accessor('darkstoreName', {
            size: 200,
            header: 'Даркстор',
          }),
          columnHelper.accessor('qty', {
            size: 200,
            header: 'Кол-во позиций за период',
          }),
          columnHelper.accessor('qtyUnique', {
            size: 200,
            header: 'Кол-во товаров за период',
          }),
          columnHelper.accessor('qtyRenewal', {
            size: 200,
            header: 'Кол-во продлений',
          }),
        ];
      case ReportType.DeliveryServices:
        return [
          columnHelper.accessor('date', {
            size: 150,
            header: 'Дата',
            cell: params => formatDate(params.getValue()),
          }),
          columnHelper.accessor('ordersAmount', {
            size: 200,
            header: 'Кол-во заказов',
          }),
          columnHelper.accessor('averageOrderSum', {
            size: 200,
            header: 'Средний чек',
          }),
          columnHelper.accessor('orderSum', {
            size: 200,
            header: 'Сумма',
          }),
        ];
      default:
        return [];
    }
  }, [type]);

  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      data={data}
      sorting
      sx={{ minHeight: '500px' }}
    />
  );
};
