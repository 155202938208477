import { useTranslation } from 'react-i18next';
import { Box, Button, Group } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { z } from 'zod';
import { DarkstoreSelect } from 'features/darkstores';
import { SupplierSelect } from 'features/suppliers';
import { Form, FormController } from 'shared/lib/form';

const validationSchema = z.object({
  darkstoreId: z.number().int().min(0),
  supplierId: z.number().int().min(0),
  date: z.date(),
});

type FiltersFormValues = z.infer<typeof validationSchema>;

export const FiltersForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FiltersFormValues>;
  onSubmit: (values: FiltersFormValues) => void;
}) => {
  const { t } = useTranslation('features/supplies-generate', { keyPrefix: 'filters-form' });

  return (
    <Form defaultValues={defaultValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Group position="apart" align="flex-end">
        <Group>
          <Box>
            <FormController
              name="darkstoreId"
              render={({ field, fieldState }) => (
                <DarkstoreSelect
                  required
                  label={t('darkstore')}
                  value={field.value}
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Box>
            <FormController
              name="supplierId"
              render={({ field, fieldState }) => (
                <SupplierSelect
                  required
                  label={t('supplier')}
                  value={field.value}
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Box>
            <FormController
              name="date"
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  clearable={false}
                  label={t('supply-date')}
                  placeholder={t('select-date')}
                  value={field.value}
                  error={fieldState?.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
        </Group>
        <Button type="submit">{t('load-products')}</Button>
      </Group>
    </Form>
  );
};
