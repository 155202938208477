import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Menu } from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { Suspense } from 'shared/components';
import { BlacklistAddress } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { BLACKLIST_ADDRESS_TYPES_MAP } from '../constants';
import { useBlacklistAddresses } from '../hooks';
import { BlacklistAddressEditModal } from './blacklist-address-edit-modal';
import { BlacklistAddressRemoveModal } from './blacklist-address-remove-modal';

export const BlacklistAddressesDataGrid = ({ actionsColumn }: { actionsColumn?: boolean }) => {
  const { t } = useTranslation('features/blacklist', {
    keyPrefix: 'blacklist-addresses-data-grid',
  });

  const modals = useModals();

  const { isFetching, addresses } = useBlacklistAddresses();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<BlacklistAddress>();
    const dataColumns = [
      columnHelper.accessor('street', {
        size: 250,
        header: () => t('columns.0'),
      }),
      columnHelper.accessor('building', {
        size: 150,
        header: () => t('columns.1'),
      }),
      columnHelper.accessor('house', {
        size: 150,
        header: () => t('columns.2'),
      }),
      columnHelper.accessor('flat', {
        size: 150,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor('type', {
        size: 200,
        header: () => t('columns.4'),
        cell: params => BLACKLIST_ADDRESS_TYPES_MAP[params.getValue()],
      }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<PencilIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('edit-blacklist-address'),
                        children: (
                          <Suspense>
                            <BlacklistAddressEditModal
                              address={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('edit')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-blacklist-address'),
                        children: (
                          <Suspense>
                            <BlacklistAddressRemoveModal
                              address={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [actionsColumn, t, modals]);

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={addresses}
      sorting
      sx={{ minHeight: '500px' }}
    />
  );
};
