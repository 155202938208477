import { useContext } from 'react';
import { RowData } from '@tanstack/react-table';
import { DataGridContext } from './data-grid-context';
import { DataGridContextValue } from './types';

export const useDataGridContext = <TData extends RowData>() => {
  const ctx = useContext(DataGridContext);

  if (ctx === undefined) {
    throw new Error('DataGridContext not found');
  }

  return ctx as DataGridContextValue<TData>;
};
