import { useTranslation } from 'react-i18next';
import { Box, Group, Stack, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { PhoneInput } from 'shared/components';
import { Form, FormController } from 'shared/lib/form';
import { customerFormSchema } from '../../schemas';
import { CustomerFormValues } from '../../types';
import { CustomerFormAddress } from './customer-form-address';

export const CustomerForm = ({
  id,
  defaultValues,
  onSubmit,
}: {
  id?: string;
  defaultValues: Partial<CustomerFormValues>;
  onSubmit: (values: CustomerFormValues) => void;
}) => {
  const { t } = useTranslation('features/customers', { keyPrefix: 'customer-form' });

  return (
    <Form
      id={id}
      defaultValues={defaultValues}
      validationSchema={customerFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        <FormController
          name="name"
          render={({ field, fieldState }) => (
            <TextInput
              required
              {...field}
              label={t('name')}
              placeholder={t('input-name')}
              error={fieldState.error?.message}
            />
          )}
        />
        <FormController
          name="surname"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label={t('surname')}
              placeholder={t('input-surname')}
              error={fieldState.error?.message}
            />
          )}
        />
        <Group grow>
          <Box>
            <FormController
              name="bornAt"
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label={t('born-at')}
                  placeholder={t('select-born-at')}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
          <Box>
            <FormController
              name="phone"
              render={({ field, fieldState }) => (
                <PhoneInput
                  {...field}
                  required
                  label={t('phone')}
                  placeholder={t('input-phone')}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Group>
        <CustomerFormAddress />
        <Group noWrap>
          <FormController
            name="addresses.0.building"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label={t('building')}
                placeholder={t('input-building')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="addresses.0.flat"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                required
                label={t('flat')}
                placeholder={t('input-flat')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="addresses.0.entrance"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label={t('entrance')}
                placeholder={t('input-entrance')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="addresses.0.floor"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label={t('floor')}
                placeholder={t('input-floor')}
                error={fieldState.error?.message}
              />
            )}
          />
        </Group>
      </Stack>
    </Form>
  );
};
