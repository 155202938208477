import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, MultiSelect, Sx } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSuppliers } from '../hooks';

export const SuppliersMultiSelect = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: number[];
  error?: string;
  onChange: (value: number[]) => void;
}) => {
  const { t } = useTranslation('features/suppliers', { keyPrefix: 'suppliers-multi-select' });

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 300);
  const { isFetching, suppliers } = useSuppliers({ query: debouncedQuery, pageSize: 1000 });

  const data: SelectItem[] = suppliers.map(s => ({ value: s.id.toString(), label: s.name }));

  return (
    <MultiSelect
      className={className}
      sx={sx}
      searchable
      required={required}
      label={label}
      placeholder={t('select-supplier')}
      data={data}
      value={value?.map(v => v.toString())}
      error={error}
      nothingFound={t('nothing-found')}
      filter={() => true}
      rightSection={isFetching && <Loader size="xs" />}
      onChange={newValues => onChange(newValues.map(v => Number(v)))}
      onSearchChange={setQuery}
    />
  );
};
