import { useQuery } from '@tanstack/react-query';
import { useDarkstores } from 'features/darkstores';
import { bazarApi, Darkstore } from 'shared/lib/bazar-api';

export const useMonitoring = ({ darkstoresIds }: { darkstoresIds: Darkstore['id'][] }) => {
  const { darkstores } = useDarkstores();

  const { isFetching, data, refetch } = useQuery({
    queryKey: ['monitoring', { darkstoresIds }],
    queryFn: () => {
      return bazarApi.monitoring.get({
        darkstoresIds: darkstoresIds.length === 0 ? darkstores.map(d => d.id) : darkstoresIds,
      });
    },
  });

  return { isFetching, monitoring: data ?? [], refetch };
};
