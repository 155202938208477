import { useTranslation } from 'react-i18next';
import { Button, Group, NumberInput, Stack, TextInput } from '@mantine/core';
import { Form, FormController } from 'shared/lib/form';
import { blacklistCustomerFormSchema } from '../schemas';
import { BlacklistCustomerFormValues } from '../types';

export const BlacklistCustomerForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<BlacklistCustomerFormValues>;
  onSubmit: (values: BlacklistCustomerFormValues) => void;
}) => {
  const { t } = useTranslation('features/blacklist', { keyPrefix: 'blacklist-customer-form' });

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={blacklistCustomerFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        <Group noWrap>
          <FormController
            name="phone"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                required
                label={t('phone')}
                placeholder={t('input-phone')}
                error={fieldState.error?.message}
              />
            )}
          />
          <FormController
            name="minOrderSum"
            render={({ field, fieldState }) => (
              <NumberInput
                {...field}
                required
                precision={2}
                label={t('min-order-sum')}
                placeholder={t('input-min-order-sum')}
                error={fieldState.error?.message}
              />
            )}
          />
        </Group>
        <FormController
          name="amnesty"
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              required
              precision={0}
              label={t('amnesty')}
              placeholder={t('input-amnesty')}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
