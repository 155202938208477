import { z } from 'zod';
import { OrderStatus, PaymentMethod } from 'shared/lib/bazar-api';

export const orderFormSchema = z.object({
  pickerId: z.number().nullish(),
  courierId: z.number().nullish(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  status: z.nativeEnum(OrderStatus),
  deliveryDate: z.date(),
  deliveryTime: z.string(),
});

export const orderProductFormSchema = z.object({
  productId: z.number().int().gte(0),
  productName: z.string().optional(),
  amount: z.number().int().positive(),
});
