import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { DarkstoreZone } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { DARKSTORE_ZONE_STATUSES_MAP } from '../constants';

export const DarkstoreZonesDataGrid = ({ data }: { data: DarkstoreZone[] }) => {
  const { t } = useTranslation('features/darkstores', { keyPrefix: 'darkstore-zones-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DarkstoreZone>();
    return [
      columnHelper.accessor('name', {
        size: 250,
        header: () => t('columns.0'),
        cell: params => {
          return (
            <Anchor component={Link} to={`zones/${params.row.original.id}`}>
              {params.getValue()}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('status', {
        size: 250,
        header: () => t('columns.1'),
        cell: params => DARKSTORE_ZONE_STATUSES_MAP[params.getValue()],
      }),
    ];
  }, [t]);

  return <DataGrid columns={columns} data={data} sorting sx={{ minHeight: '300px' }} />;
};
