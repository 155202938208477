import { useTranslation } from 'react-i18next';
import { Box, Button, Group, Image, MantineTheme, Popover, Stack, Tabs, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Category } from 'shared/lib/bazar-api';
import { cluster, groupBy } from 'shared/utils/array';
import { useCategories } from '../hooks';

const CategoriesAlphabetList = ({
  data,
  onItemClick,
}: {
  data: Category[];
  onItemClick: (item: Category) => void;
}) => {
  const ordered = [...data];
  ordered.sort((a, b) => (a.name > b.name ? 1 : -1));
  const grouped = groupBy(ordered, item => item.name[0]);
  const columns = cluster(
    Object.entries(grouped).map(([name, categories]) => ({ name, categories })),
    5,
  );
  return (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      {columns.map((column, index) => (
        <Stack key={index} spacing={12}>
          {column.map(item => (
            <Stack key={item.name} spacing={2}>
              <Text
                sx={{ padding: '0px 6px', fontSize: '14px', fontWeight: 500, color: '#54586C' }}
              >
                {item.name}
              </Text>
              {item.categories.map(category => (
                <Box
                  key={category.id}
                  sx={(theme: MantineTheme) => ({
                    padding: '3px 6px',
                    borderRadius: theme.radius.sm,
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: theme.colors.gray[1],
                    },
                    '&:active': {
                      backgroundColor: theme.colors.gray[3],
                    },
                  })}
                  onClick={() => onItemClick(category)}
                >
                  <Text sx={{ fontSize: '12px' }}>{category.name}</Text>
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>
      ))}
    </Box>
  );
};

const CategoriesList = ({
  data,
  onItemClick,
}: {
  data: Category[];
  onItemClick: (item: Category) => void;
}) => {
  const columns = cluster(data, 10);
  return (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      {columns.map((column, index) => (
        <Stack key={index} spacing={4}>
          {column.map(item => (
            <Box
              key={item.id}
              sx={(theme: MantineTheme) => ({
                padding: '6px 12px',
                borderRadius: theme.radius.sm,
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: theme.colors.gray[1],
                },
                '&:active': {
                  backgroundColor: theme.colors.gray[3],
                },
              })}
              onClick={() => onItemClick(item)}
            >
              <Group spacing={12}>
                <Image
                  src={item.icon}
                  alt={item.name}
                  fit="contain"
                  width={20}
                  height={20}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
                <Text sx={{ fontSize: '12px' }}>{item.name}</Text>
              </Group>
            </Box>
          ))}
        </Stack>
      ))}
    </Box>
  );
};

export const CategoriesMenu = ({
  value,
  onChange,
}: {
  value?: Category;
  onChange: (value: Category) => void;
}) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'categories-menu' });

  const { isFetching, categories } = useCategories();

  const [opened, { close, open }] = useDisclosure(false);

  const handleChange = (item: Category) => {
    close();
    onChange(item);
  };

  return (
    <Popover position="bottom-start" shadow="md" opened={opened} onClose={close}>
      <Popover.Target>
        <Button loading={isFetching} onClick={open}>
          {value ? value.name : t('select-category')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Tabs defaultValue="alphabet">
          <Tabs.List>
            <Tabs.Tab value="alphabet">{t('by-alphabet')}</Tabs.Tab>
            <Tabs.Tab value="site">{t('as-on-the-site')}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="alphabet" pt="xs">
            <CategoriesAlphabetList data={categories} onItemClick={handleChange} />
          </Tabs.Panel>
          <Tabs.Panel value="site" pt="xs">
            <CategoriesList data={categories} onItemClick={handleChange} />
          </Tabs.Panel>
        </Tabs>
      </Popover.Dropdown>
    </Popover>
  );
};
