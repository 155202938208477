import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, Category } from 'shared/lib/bazar-api';

export const useCategory = ({ categoryId }: { categoryId?: Category['id'] }) => {
  const queryClient = useQueryClient();

  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['categories', { categoryId }],
    queryFn: () => {
      if (categoryId) {
        return bazarApi.assortment.getCategory({ id: categoryId });
      }
    },
    enabled: !!categoryId,
    suspense: true,
    initialData: () => {
      const categories = queryClient.getQueryData<Category[]>(['categories', { darkstoreId }]);
      if (!categories) {
        return undefined;
      }
      const category = categories.find(c => c.id === Number(categoryId));
      return category;
    },
  });

  return { isFetching, category: data };
};
