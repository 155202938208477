import { useQuery } from '@tanstack/react-query';
import { useDarkstores } from 'features/darkstores';
import { bazarApi, Darkstore } from 'shared/lib/bazar-api';
import { ReportType } from '../types';

export const useReport = ({
  type,
  darkstoresIds,
  period,
  date,
  service,
}: {
  type?: ReportType;
  darkstoresIds: Darkstore['id'][];
  period: [Date, Date];
  date: Date;
  service?: string;
}) => {
  const { darkstores } = useDarkstores();

  const { isFetching, data, refetch } = useQuery({
    queryKey: ['report', { type, darkstoresIds, period, date, service }],
    queryFn: () => {
      switch (type) {
        case ReportType.Fiscal:
          return bazarApi.reports.fiscal({
            darkstoresIds: darkstoresIds.length === 0 ? darkstores.map(d => d.id) : darkstoresIds,
            period,
          });
        case ReportType.OrdersByDay:
          return bazarApi.reports.ordersByDay({
            darkstoresIds: darkstoresIds.length === 0 ? darkstores.map(d => d.id) : darkstoresIds,
            date,
          });
        case ReportType.StopList:
          return bazarApi.reports.stopList({
            darkstoresIds: darkstoresIds.length === 0 ? darkstores.map(d => d.id) : darkstoresIds,
            period,
          });
        case ReportType.DeliveryServices:
          return bazarApi.reports.deliveryServices({
            darkstoresIds: darkstoresIds.length === 0 ? darkstores.map(d => d.id) : darkstoresIds,
            date,
            service: service ? service : 'yandex-sosedi',
          });
        default:
          return [];
      }
    },
    enabled: !!type,
  });

  return { isFetching, report: data ?? [], refetch };
};
