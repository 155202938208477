import { ReactNode } from 'react';
import {
  FormProvider,
  useForm,
  FieldValues,
  SubmitHandler,
  DefaultValues,
  UseFormReturn,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Sx } from '@mantine/core';
import { z } from 'zod';
import { FormPluginsProvider } from './form-plugins';

export const Form = <
  TFieldValues extends FieldValues = FieldValues,
  TSchema extends z.Schema<any, any> = z.Schema<any, any>,
>({
  id,
  sx,
  disabled,
  readOnly,
  defaultValues,
  validationSchema,
  children,
  onSubmit,
}: {
  id?: string;
  sx?: Sx;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValues: DefaultValues<TFieldValues>;
  validationSchema?: TSchema;
  children: ReactNode | ((form: UseFormReturn<TFieldValues>) => JSX.Element);
  onSubmit: SubmitHandler<TFieldValues>;
}) => {
  const form = useForm<TFieldValues>({ defaultValues /*resolver: zodResolver(validationSchema)*/ });

  return (
    <FormProvider {...form}>
      <FormPluginsProvider value={{ disabled, readOnly }}>
        <Box
          id={id}
          sx={sx}
          component="form"
          noValidate
          onSubmit={form.handleSubmit(onSubmit, e => console.warn('Form validation error', e))}
        >
          {typeof children === 'function' ? children(form) : children}
        </Box>
      </FormPluginsProvider>
    </FormProvider>
  );
};
