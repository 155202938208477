import { useTranslation } from 'react-i18next';
import { Button, Stack, TextInput } from '@mantine/core';
import { Form, FormController } from 'shared/lib/form';
import { subcategoryFormSchema } from '../schemas';
import type { SubcategoryFormValues } from '../types';

export const SubcategoryForm = ({
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  isSubmitting: boolean;
  defaultValues: Partial<SubcategoryFormValues>;
  onSubmit: (values: SubcategoryFormValues) => void;
}) => {
  const { t } = useTranslation('features/categories', { keyPrefix: 'subcategory-form' });

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={subcategoryFormSchema}
      onSubmit={onSubmit}
    >
      <Stack align="stretch">
        <FormController
          name="name"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              label={t('name')}
              placeholder={t('input-name')}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t('save')}
        </Button>
      </Stack>
    </Form>
  );
};
