import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ActionIcon, Anchor, Menu } from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  DotsVertical as DotsVerticalIcon,
  Pencil as PencilIcon,
  Trash as TrashIcon,
} from 'tabler-icons-react';
import { Suspense } from 'shared/components';
import { Darkstore, Supplier, Supply, SupplyProduct } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid } from 'shared/lib/data-grid';
import { SupplyProductEditModal } from './supply-product-edit-modal';
import { SupplyProductRemoveModal } from './supply-product-remove-modal';

export const SupplyProductsDataGrid = ({
  supplyId,
  darkstoreId,
  supplierId,
  data,
  actionsColumn,
}: {
  supplyId: Supply['id'];
  darkstoreId: Darkstore['id'];
  supplierId: Supplier['id'];
  data: SupplyProduct[];
  actionsColumn?: boolean;
}) => {
  const { t } = useTranslation('features/supplies', {
    keyPrefix: 'supply-products-data-grid',
  });
  const modals = useModals();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplyProduct>();
    const dataColumns = [
      columnHelper.accessor('id', {
        size: 100,
        // meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={`/products/${value}`}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('name', { size: 250, header: () => t('columns.1') }),
      columnHelper.accessor('barcode', { size: 150, header: () => t('columns.2') }),
      columnHelper.accessor('weight', { size: 150, header: () => t('columns.3') }),
      columnHelper.accessor('amount', { size: 100, header: () => t('columns.4') }),
      columnHelper.accessor('priceWithoutVat', { size: 100, header: () => t('columns.5') }),
      columnHelper.accessor('sumWithoutVat', { size: 100, header: () => t('columns.6') }),
      columnHelper.accessor('vatPercent', { size: 100, header: () => t('columns.7') }),
      columnHelper.accessor('vat', { size: 100, header: () => t('columns.8') }),
      columnHelper.accessor('vatSum', { size: 100, header: () => t('columns.9') }),
      columnHelper.accessor('extraCharge', { size: 150, header: () => t('columns.10') }),
      columnHelper.accessor('price', { size: 150, header: () => t('columns.11') }),
      columnHelper.accessor('sku', { size: 150, header: () => t('columns.12') }),
    ];
    if (actionsColumn) {
      return [
        columnHelper.display({
          id: 'actions',
          size: 50,
          meta: { sticky: true },
          cell: params => {
            return (
              <Menu position="bottom-start">
                <Menu.Target>
                  <ActionIcon>
                    <DotsVerticalIcon size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component="button"
                    icon={<PencilIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('edit-product'),
                        children: (
                          <Suspense>
                            <SupplyProductEditModal
                              supplyId={supplyId}
                              darkstoreId={darkstoreId}
                              supplierId={supplierId}
                              product={params.row.original}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('edit')}
                  </Menu.Item>
                  <Menu.Item
                    component="button"
                    icon={<TrashIcon size={14} />}
                    onClick={() => {
                      const modalId = modals.openModal({
                        title: t('remove-product'),
                        children: (
                          <Suspense>
                            <SupplyProductRemoveModal
                              supplyId={supplyId}
                              productId={params.row.original.id}
                              onClose={() => modals.closeModal(modalId)}
                            />
                          </Suspense>
                        ),
                      });
                    }}
                  >
                    {t('remove')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          },
        }),
        ...dataColumns,
      ];
    }
    return dataColumns;
  }, [supplyId, darkstoreId, supplierId, actionsColumn, t, modals]);

  return <DataGrid columns={columns} data={data} sx={{ minHeight: '500px' }} />;
};
