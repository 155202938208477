import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { usePermission } from 'features/auth';
import {
  SupplierForm,
  SupplierProductsDataGrid,
  SupplierSuppliesDataGrid,
  useEditSupplier,
  useSupplier,
} from 'features/suppliers';

export const SupplierEditPage = () => {
  const { supplierId } = useParams();

  const { t } = useTranslation('pages/suppliers');

  const hasWritePermission = usePermission('suppliers.write');

  const { isFetching, supplier } = useSupplier({ supplierId: Number(supplierId) });
  const { isEditing, edit } = useEditSupplier();

  if (isFetching) {
    return <LoadingOverlay visible />;
  }

  if (!supplier) {
    return (
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="/suppliers">
            {t('suppliers')}
          </Anchor>
          <Text>{t('supplier-nof-found')}</Text>
        </Breadcrumbs>
        <Title order={2}>{t('supplier-nof-found')}</Title>
      </Stack>
    );
  }

  return (
    <Stack spacing="xl">
      <Breadcrumbs>
        <Anchor component={Link} to="/suppliers">
          {t('suppliers')}
        </Anchor>
        <Text>{supplier.name}</Text>
      </Breadcrumbs>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{supplier.name}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplierForm
            id="supplier-form"
            disabled={!hasWritePermission}
            defaultValues={supplier}
            onSubmit={data => edit({ supplierId: supplier.id, data })}
          />
        </Card.Section>
        <Card.Section sx={{ padding: 16, borderTop: '1px solid #D2D2DB' }}>
          <Button
            type="submit"
            form="supplier-form"
            disabled={!hasWritePermission}
            loading={isEditing}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('save')}
          </Button>
        </Card.Section>
      </Card>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{t('supplies')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplierSuppliesDataGrid data={supplier.supplies} />
        </Card.Section>
      </Card>
      <Card shadow="sm" radius="sm">
        <Card.Section sx={{ padding: 16, borderBottom: '1px solid #D2D2DB' }}>
          <Title order={2}>{t('products')}</Title>
        </Card.Section>
        <Card.Section sx={{ padding: 16 }}>
          <SupplierProductsDataGrid data={supplier.products} />
        </Card.Section>
      </Card>
    </Stack>
  );
};
