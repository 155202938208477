import { Banner } from 'shared/lib/bazar-api';
import { useEditBanner } from '../hooks';
import { BannerForm } from './banner-form';

export const BannerEditModal = ({ banner, onClose }: { banner: Banner; onClose: () => void }) => {
  const { isEditing, edit } = useEditBanner({ onSuccess: onClose });

  return (
    <BannerForm
      isSubmitting={isEditing}
      defaultValues={banner}
      onSubmit={data => edit({ bannerId: banner.id, data })}
    />
  );
};
