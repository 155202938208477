import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, Sx } from '@mantine/core';
import type { SelectItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useSearchProducts } from '../hooks';

export const ProductSelect = ({
  className,
  sx,
  required,
  label,
  value,
  error,
  onChange,
}: {
  className?: string;
  sx?: Sx;
  required?: boolean;
  label?: string;
  value?: string | null;
  error?: string;
  onChange: (value: number) => void;
}) => {
  const { t } = useTranslation('features/products', { keyPrefix: 'product-select' });

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 300);

  const { isFetching, products } = useSearchProducts({ query: debouncedQuery });

  const data: SelectItem[] = products.map(product => ({
    value: product.id.toString(),
    label: product.name,
  }));

  return (
    <Select
      className={className}
      sx={sx}
      searchable
      required={required}
      label={label}
      placeholder={t('select-product')}
      data={data}
      value={value?.toString()}
      error={error}
      nothingFound={t('nothing-found')}
      filter={() => true}
      rightSection={isFetching && <Loader size="xs" />}
      onChange={newValue => onChange(Number(newValue))}
      onSearchChange={setQuery}
    />
  );
};
