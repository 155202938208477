import { Group, Pagination, Select } from '@mantine/core';
import { useDataGridContext } from './use-data-grid-context';

export const DataGridFooter = () => {
  const { props, table } = useDataGridContext();

  if (!props.pagination) {
    return null;
  }

  return (
    <Group position="apart">
      <Select
        sx={{ width: 64 }}
        data={['10', '20', '30', '40', '50']}
        value={table.getState().pagination.pageSize.toString()}
        onChange={value => table.setPageSize(Number(value))}
      />
      <Pagination
        total={table.getPageCount() || 1}
        page={table.getState().pagination.pageIndex + 1}
        onChange={value => table.setPageIndex(value - 1)}
      />
    </Group>
  );
};
