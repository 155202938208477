import { SupplyStatus } from 'shared/lib/bazar-api';

export const SUPPLY_STATUSES = [
  { label: 'Ожидается', value: SupplyStatus.Pending },
  { label: 'Исполнена', value: SupplyStatus.Completed },
  { label: 'Отменена', value: SupplyStatus.Canceled },
];

export const SUPPLY_STATUSES_MAP = {
  [SupplyStatus.Pending]: 'Ожидается',
  [SupplyStatus.Completed]: 'Исполнена',
  [SupplyStatus.Canceled]: 'Отменена',
};
