import { useQuery } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';

export const useAddresses = ({ query }: { query: string }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['addresses', { query }],
    queryFn: () => bazarApi.addresses.search({ query }),
    keepPreviousData: true,
  });

  return { isFetching, addresses: data ?? [] };
};
