import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
import { Employee, OrderRow } from 'shared/lib/bazar-api';
import { createColumnHelper, DataGrid, useDataGridState } from 'shared/lib/data-grid';
import { formatDateTime } from 'shared/utils/date';
import { formatPhone } from 'shared/utils/phone';
import { PAYMENT_METHODS_MAP } from '../constants';
import { useOrders } from '../hooks';
import { OrderStatusBadge } from './order-status-badge';

export const OrdersDataGrid = ({
  from,
  to,
  query,
  courierId,
}: {
  from: Date;
  to: Date;
  query: string;
  courierId?: Employee['id'];
}) => {
  const { t } = useTranslation('features/orders', { keyPrefix: 'orders-data-grid' });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<OrderRow>();
    return [
      columnHelper.accessor('id', {
        size: 100,
        meta: { sticky: true },
        header: () => t('columns.0'),
        cell: params => {
          const value = params.getValue();
          return (
            <Anchor component={Link} to={value.toString()}>
              {value}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor('status', {
        size: 150,
        header: () => t('columns.1'),
        cell: params => <OrderStatusBadge status={params.getValue()} />,
      }),
      columnHelper.accessor('paymentMethod', {
        size: 150,
        header: () => t('columns.2'),
        cell: params => PAYMENT_METHODS_MAP[params.getValue()],
      }),
      columnHelper.accessor('sum', {
        size: 75,
        header: () => t('columns.3'),
      }),
      columnHelper.accessor(
        row => {
          if (row.deliveryTime === 'now') {
            const plannedTimeDeliveryString = row.plannedTimeDelivery
              ? `, ${row.plannedTimeDelivery}`
              : '';
            return `Сейчас ${plannedTimeDeliveryString}`;
          }
          if (dayjs(row.createdAt).get('date') === dayjs(row.deliveryDate).get('date')) {
            return `Сегодня ${row.deliveryTime}`;
          }
          return `Завтра ${row.deliveryTime}`;
        },
        {
          id: 'delivery-to',
          size: 150,
          header: () => t('columns.4'),
        },
      ),
      /*
      columnHelper.accessor('deliveredIn', {
        size: 150,
        header: () => t('columns.5'),
        cell: params => {
          const value = params.getValue();
          if (typeof value !== 'number') {
            return '';
          }
          return `${Math.floor(value / 60)} мин. ${value % 60} сек`;
        },
      }),*/
      columnHelper.accessor('createdAt', {
        size: 200,
        header: () => t('columns.6'),
        cell: params => formatDateTime(params.getValue()),
      }),
      columnHelper.accessor(
        row => {
          return `${formatPhone(row.customerPhone)} ${row.customerName ?? ''}`.trim();
        },
        {
          id: 'customer-info',
          size: 250,
          header: () => t('columns.7'),
        },
      ),
      columnHelper.accessor(
        row => {
          return `${row.courierName ?? ''} ${row.courierSurname ?? ''}`.trim();
        },
        {
          id: 'courier-info',
          size: 250,
          header: () => t('columns.8'),
        },
      ),
    ];
  }, [t]);

  const [debouncedQuery] = useDebouncedValue(query, 300);

  const [state, setState] = useDataGridState({
    sorting: [{ id: 'number', desc: false }],
    pagination: { pageIndex: 0, pageSize: 30 },
  });

  const { isFetching, orders, totalPages } = useOrders({
    from,
    to,
    query: debouncedQuery,
    courierId,
    ...state.server,
  });

  return (
    <DataGrid
      loading={isFetching}
      columns={columns}
      data={orders}
      sorting
      manualSorting
      pagination
      manualPagination
      pageCount={totalPages}
      state={state}
      onStateChange={setState}
    />
  );
};
