import { BlacklistCustomer } from 'shared/lib/bazar-api';
import { useEditBlacklistCustomer } from '../hooks';
import { BlacklistCustomerForm } from './blacklist-customer-form';

export const BlacklistCustomerEditModal = ({
  customer,
  onClose,
}: {
  customer: BlacklistCustomer;
  onClose: () => void;
}) => {
  const { isEditing, edit } = useEditBlacklistCustomer({ onSuccess: onClose });

  return (
    <BlacklistCustomerForm
      isSubmitting={isEditing}
      defaultValues={customer}
      onSubmit={data => edit({ customerId: customer.id, data })}
    />
  );
};
