import { addresses } from './addresses';
import { analytics } from './analytics';
import { assortment } from './assortment';
import { auth } from './auth';
import { banners } from './banners';
import { blacklist } from './blacklist';
import { cash } from './cash';
import { customers } from './customers';
import { darkstoreZones } from './darkstore-zones';
import { darkstores } from './darkstores';
import { employees } from './employees';
import { files } from './files';
import { mailings } from './mailings';
import { monitoring } from './monitoring';
import { orders } from './orders';
import { promoCodes } from './promo-codes';
import { reports } from './reports';
import { stopList } from './stop-list';
import { suppliers } from './suppliers';
import { supplies } from './supplies';
import { users } from './users';

export const bazarApi = {
  addresses,
  analytics,
  assortment,
  auth,
  banners,
  blacklist,
  cash,
  customers,
  darkstoreZones,
  darkstores,
  employees,
  files,
  mailings,
  orders,
  promoCodes,
  reports,
  suppliers,
  supplies,
  users,
  stopList,
  monitoring,
};
