import { showNotification } from '@mantine/notifications';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: any) => {
        showNotification({
          title: 'Ошибка',
          message: error.message,
          color: 'red',
        });
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        showNotification({
          title: 'Ошибка',
          message: error.message,
          color: 'red',
        });
      },
    },
  },
});
