import { useQuery } from '@tanstack/react-query';
import { useSettings } from 'features/settings';
import { bazarApi, Supplier } from 'shared/lib/bazar-api';

export const useSupplier = ({ supplierId }: { supplierId?: Supplier['id'] }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isFetching, data } = useQuery({
    queryKey: ['suppliers', { supplierId }],
    queryFn: () => {
      if (supplierId) {
        return bazarApi.suppliers.getOne({ id: supplierId, darkstoreId });
      }
    },
    enabled: !!supplierId,
    suspense: true,
  });

  return { isFetching, supplier: data };
};
