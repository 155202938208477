import { FieldArrayPath, FieldValues, useFormContext, useFieldArray } from 'react-hook-form';
import { useFormPluginsContext } from './form-plugins';

export const useFormFieldArray = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  name,
  keyName,
}: {
  name: TFieldArrayName;
  keyName?: TKeyName | undefined;
}) => {
  const { control } = useFormContext<TFieldValues>();

  const { disabled, readOnly } = useFormPluginsContext();

  const fieldArray = useFieldArray<TFieldValues, TFieldArrayName, TKeyName>({
    control,
    name,
    keyName,
  });

  fieldArray.fields = fieldArray.fields.map(field => ({ ...field, disabled, readOnly }));

  return fieldArray;
};
