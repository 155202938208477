import { useSettings } from 'features/settings';
import { BannerActionType, BannerSize } from 'shared/lib/bazar-api';
import { useAddBanner } from '../hooks';
import { BannerForm } from './banner-form';

export const BannerAddModal = ({ onClose }: { onClose: () => void }) => {
  const darkstoreId = useSettings(settings => settings.darkstoreId);

  const { isAdding, add } = useAddBanner({ onSuccess: onClose });

  return (
    <BannerForm
      isSubmitting={isAdding}
      defaultValues={{
        darkstoreId,
        size: BannerSize.Small,
        actionType: BannerActionType.Category,
        appImage: undefined,
        webImage: undefined,
        targetCategory: null,
        targetSubcategory: null,
        targetBrand: null,
        targetScreen: null,
      }}
      onSubmit={data => add({ data })}
    />
  );
};
