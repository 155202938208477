import { showNotification } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bazarApi } from 'shared/lib/bazar-api';
import type { CustomerFormValues } from '../types';

type AddCustomerDTO = {
  data: CustomerFormValues;
};

export const useAddCustomer = ({
  onSuccess,
}: {
  onSuccess: (data: unknown, variables: AddCustomerDTO) => void;
}) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, Error, AddCustomerDTO>({
    mutationFn: variables => {
      return bazarApi.customers.add(variables.data);
    },
    onSuccess: (data, variables) => {
      showNotification({
        title: 'Успех',
        message: 'Клиент добавлен',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      onSuccess(data, variables);
    },
  });

  return { isAdding: isLoading, add: mutate };
};
