import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from '@mantine/core';
import { PaymentMethod } from 'shared/lib/bazar-api';
import { PAYMENT_METHODS } from '../constants';

type Props = Omit<SelectProps, 'data' | 'value' | 'onChange'> & {
  value?: PaymentMethod | null;
  onChange: (value: PaymentMethod) => void;
};

export const PaymentMethodSelect = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation('features/orders', { keyPrefix: 'payment-method-select' });

    return (
      <Select
        ref={ref}
        placeholder={t('select-payment-method')}
        data={PAYMENT_METHODS.map(pm => ({ label: pm.label, value: pm.value.toString() }))}
        value={value?.toString()}
        onChange={newValue => onChange(Number(newValue))}
        {...rest}
      />
    );
  },
);
