import { useQuery } from '@tanstack/react-query';
import { bazarApi, Darkstore } from 'shared/lib/bazar-api';

export const useDarkstore = ({ darkstoreId }: { darkstoreId?: Darkstore['id'] }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['darkstores', { darkstoreId }],
    queryFn: () => {
      if (darkstoreId) {
        return bazarApi.darkstores.getOne({ id: darkstoreId });
      }
    },
    enabled: !!darkstoreId,
    suspense: true,
  });

  return { isFetching, darkstore: data };
};
