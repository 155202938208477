import { useTranslation } from 'react-i18next';
import { Button, NumberInput, Stack, TextInput } from '@mantine/core';
import { ProductSelect } from 'features/products';
import { Form, FormController } from 'shared/lib/form';
import { orderProductFormSchema } from '../schemas';
import { OrderProductFormValues } from '../types';

export const OrderProductForm = ({
  mode,
  isSubmitting,
  defaultValues,
  onSubmit,
}: {
  mode: 'add' | 'edit';
  isSubmitting: boolean;
  defaultValues: Partial<OrderProductFormValues>;
  onSubmit: (values: OrderProductFormValues) => void;
}) => {
  const { t } = useTranslation('features/orders', { keyPrefix: 'order-product-form' });

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={orderProductFormSchema}
      onSubmit={onSubmit}
    >
      <Stack>
        {mode === 'add' ? (
          <FormController
            name="productId"
            render={({ field, fieldState }) => (
              <ProductSelect
                required
                label={t('product')}
                value={field.value}
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        ) : (
          <TextInput disabled label={t('product')} defaultValue={defaultValues.productName} />
        )}
        <FormController
          name="amount"
          render={({ field, fieldState }) => (
            <NumberInput
              {...field}
              required
              precision={3}
              label={t('amount')}
              placeholder={t('input-amount')}
              error={fieldState?.error?.message}
            />
          )}
        />
        <Button type="submit" loading={isSubmitting} sx={{ display: 'block', marginLeft: 'auto' }}>
          {t(mode === 'add' ? 'add' : 'save')}
        </Button>
      </Stack>
    </Form>
  );
};
