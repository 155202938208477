import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseSearch, stringifySearch } from './search';

export const useSearch = <T extends Record<string, any>>(
  initialSearch: T,
): [T, (changes: Partial<T>, options?: { replace?: boolean; merge?: boolean }) => void] => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialSearchRef = useRef(initialSearch);

  const search = useMemo(() => {
    const parsed = parseSearch<T>(location.search);
    return {
      ...initialSearchRef.current,
      ...parsed,
    };
  }, [location.search]);

  const setSearch = useCallback(
    (changes: Partial<T>, { replace = true, merge = true } = {}) => {
      const nextSearch = merge
        ? stringifySearch({
            ...search,
            ...changes,
          })
        : stringifySearch(changes);
      navigate(nextSearch, { replace });
    },
    [navigate, search],
  );

  return [search, setSearch];
};
